import React, { useState, useEffect } from 'react'
import environment from './relay-env'
import { isMobile } from "react-device-detect"
import { ApolloProvider } from '@apollo/client'
import { client } from './apollo-client'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Loading } from './components'
import { useReactiveVar } from '@apollo/client'
import {
  open as openFinalizeDialog,
  close as closeFinalizeDialog,
  isOpen as isFinalizeDialogOpen,
} from './FinalizeDialog'
import Loom from './Loom'
import './App.css'

import { openSocket, onSocketOpen } from './Daemon'
import { startPipeline } from './Pipeline/Pipeline'
import { tokenOptions } from './relay-env'
import wagonImg from './Images/wagon.png'
import type { LocalDevice } from './Pipeline/Devices'
// import { cancelAllPreloads } from './Pipeline/Downloader'

import { options as fulfillmentOptions } from './components/FulfillmentOptions'
const { bookType } = fulfillmentOptions

Sentry.init({
  dsn:
    'https://c43524c34ed54420b2089ae741c73b89@o509038.ingest.sentry.io/5641636',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,

  beforeSend: (event) => {
    if (process.env.NODE_ENV !== 'production') {
      return null
    }
    return event
  },
})

let boundSocket = false
function onHaveToken() {
  // Reset the apollo store after a user logs in
  // https://www.apollographql.com/docs/react/networking/authentication/#reset-store-on-logout
  client.resetStore()

  if (!boundSocket) {
    onSocketOpen(() => {
      // This will also get called whenever the socket reconnects
      startPipeline(environment, onTransferredDeviceDisconnected)
    })
    boundSocket = true
  }
}

function onTransferredDeviceDisconnected(device: LocalDevice) {
  isFinalizeDialogOpen(environment, (isOpen) => {
    if (isOpen) {
      alert('Multiple devices disconnected, please start over.')
    } else {
      openFinalizeDialog(environment, device.transferredOrderId, {
        allowAutoPrint: true,
        allowAutoBuy: true,
      })
    }
  })
}

closeFinalizeDialog(environment)

enum TokenState {
  INIT,
  LOADING,
  SUCCESS,
  ERROR,
}

enum DaemonState {
  INIT,
  SUCCESS,
  ERROR,
}

const socketPromise = openSocket()

const AuthError = () => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div className="App-auth-message">
      Hmm... an error occurred while trying to login. Try again, or check to see
      if you have the correct permissions.
    </div>
    <div className="App-auth-message">
      Try{' '}
      <a
        href={`https://heirloom.us.auth0.com/v2/logout?returnTo=${encodeURIComponent(
          window.location.origin
        )}`}
      >
        logging
      </a>{' '}
      out and back in.
    </div>
  </div>
)

const App = () => {
  const {
    isLoading,
    error,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0()
  const [tokenState, setTokenState] = useState<TokenState>(TokenState.INIT)
  const [daemonState, setDaemonState] = useState<DaemonState>(DaemonState.INIT)
  const [hasChangedBookState, setHasChangedBookState] = useState<boolean>(false)
  const [ignoreNoDaemon, setIgnoreNoDaemon] = useState<boolean>(false)

  const bookTypeVar = useReactiveVar(bookType);
  useEffect(() => {
    // TODO Also reset the download status of existing videos and make sure
    // it starts preloading ones that now match
    // cancelAllPreloads(environment)

    // HACK until above TODO is done
    if (hasChangedBookState) {
      console.log("Reloading due to book size change")
      document.location.reload()
    }
    setHasChangedBookState(true)
  }, [bookTypeVar])

  socketPromise
    .then(() => setDaemonState(DaemonState.SUCCESS))
    .catch((_) => setDaemonState(DaemonState.ERROR))

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    console.error('Authentication error', error)
    return <AuthError />
  }

  if (tokenState === TokenState.INIT) {
    // This is likely not used anymore by the relay code path, as relay-env gets its own token

    setTokenState(TokenState.LOADING)
    const handleTokenSuccess = (token: string) => {
      localStorage.token = token
      setTokenState(TokenState.SUCCESS)
      onHaveToken()
    }

    getAccessTokenSilently(tokenOptions()).then(handleTokenSuccess, () => {
      // We can't get the token silently if the user has never authed our scopes
      getAccessTokenWithPopup(tokenOptions()).then(
        handleTokenSuccess,
        (_: any) => {
          setTokenState(TokenState.ERROR)
        }
      )
    })

    return <div className="App-auth-message">Getting token...</div>
  }

  if (daemonState === DaemonState.ERROR && !isMobile && !ignoreNoDaemon) {
    return (
      <div className="App-daemon-error-container">
        <div className="App-auth-message">
          Hmmm... can't talk to the daemon. You may have to install it.
        </div>
        <div className="App-auth-message">
          To install it open a terminal window, paste the following and press
          Enter and reload the site:
        </div>
        <div className="App-daemon-install-code">
          curl -O https://public-loom-daemon-releases.s3.amazonaws.com/Loom/v2.2.2/loom-daemon_2.2.2_darwin_amd64 && mv ./loom-daemon_2.2.2_darwin_amd64 ./loom-daemon && chmod +x ./loom-daemon && ./loom-daemon -install && rm ./loom-daemon
        </div>
        <div>
          <button
            onClick={ () => setIgnoreNoDaemon(true) }
            className="block p-2 mt-6 mb-2 bg-gray-100 hover:bg-gray-200 mx-auto"
          >
            Ignore
          </button>
        </div>
        <img className="App-daemon-wagon" alt="wagon" src={wagonImg} />
      </div>
    )
  }

  return <Loom />
}

const app = Sentry.withProfiler(function () {
  // Auth0 options also appear in relay-env.ts
  return (
    <ApolloProvider client={client}>
      <Auth0Provider
        domain="auth.sendheirloom.com"
        clientId="oBe57B5gP8Yv1wJy5TGjZkf1OhbfAl7U"
        cacheLocation="localstorage"
        redirectUri={window.location.origin}
      >
        <Sentry.ErrorBoundary fallback={<div className="p-5">An error has occurred.</div>}>
          <App />
        </Sentry.ErrorBoundary>
      </Auth0Provider>
    </ApolloProvider>
  )
})

export default app
