import { graphql, fetchQuery } from 'react-relay'
import { commitLocalUpdate } from 'relay-runtime'
import type { IEnvironment } from 'relay-runtime'

import { startDownload, cancelDownload } from '../Daemon'
import type { DownloadProgress } from '../Daemon'

import debug from 'debug'
const log = debug('pipeline:downloader')

interface VideoRef {
  URL: string
  SHA256: string
}

const query = graphql`
  query DownloaderQuery($orderID: ID!) {
    videoURL(orderID: $orderID) {
      URL,
      SHA256,
    }
  }`

let currentlyPreloading = new Map()

export function currentlyPreloadingCount(): number {
  return currentlyPreloading.size
}

export async function cancelPreload(environment: IEnvironment, orderId: string) {
  console.log("Cancelling preload of " + orderId)

  commitLocalUpdate(environment, store => {
    const order = store.get(orderId)
    order.setValue(null, 'videoDownloadStatus')
    order.setValue(null, 'videoTransferStatus')

    const sha = order.getValue('cardVideoSHA256')
    if (sha){
      currentlyPreloading.delete(sha)
      cancelDownload(sha)
    }
  })
}

export async function cancelAllPreloads(environment: IEnvironment) {
  commitLocalUpdate(environment, store => {
    let orders = store.getRoot().getLinkedRecords('orders')
    if (!orders){
      return
    }

    orders.forEach((order) => {
      if (!order) {
        return
      }

      order.setValue(null, 'videoDownloadStatus')
      order.setValue(null, 'videoTransferStatus')

      const sha = order.getValue('cardVideoSHA256')
      if (sha){
        currentlyPreloading.delete(sha)
        cancelDownload(sha)
      }
    })
  })
}

async function startPreload(environment: IEnvironment, dataID: string, video: VideoRef){
  if (currentlyPreloading.has(video.SHA256)) {
    return
  }

  currentlyPreloading.set(video.SHA256, dataID)

  const handleError = (message: string) => {
    console.error("Error preloading:", message)
    currentlyPreloading.delete(video.SHA256)
  }

  const handleSuccess = () => {
    currentlyPreloading.delete(video.SHA256)
  }

  const res = startDownload(video.URL, video.SHA256, (p: DownloadProgress) => {
    commitLocalUpdate(environment, store => {
      let order = store.get(dataID)
      order && order.setValue(p.percent_complete, 'videoPercentageDownloaded')
    })
  })

  res.then(() => {
    commitLocalUpdate(environment, store => {
      let order = store.get(dataID)
      order && order.setValue('downloaded', 'videoDownloadStatus')
    })

    handleSuccess()
  }, (err) => {
    commitLocalUpdate(environment, store => {
      let order = store.get(dataID)
      order && order.setValue('error', 'videoDownloadStatus')
    })

    handleError(err.message)
  })
}

let preloadPending: Set<string> = new Set()
export function preloadVideo(environment: IEnvironment, orderId: string){
  if (preloadPending.has(orderId)){
    return
  }

  log('Preload start')

  commitLocalUpdate(environment, store => {
    const order = store.get(orderId)
    if (!order){
      return
    }
    preloadPending.add(orderId)

    log('Preload query fetch', orderId)

    fetchQuery(environment, query, {orderID: orderId})
      .subscribe({
        next: (resp: any) => {
          preloadPending.delete(orderId)

          if (resp && resp.videoURL){
            const dataID = order!.getDataID()
            if (!dataID)
              return

            startPreload(environment, dataID, resp.videoURL)

            commitLocalUpdate(environment, store => {
              let order = store.get(orderId)
              order.setValue('downloading', 'videoDownloadStatus')
            })
          }
        },
      error: () => {
        preloadPending.delete(orderId)
      }
    })
  })
}

