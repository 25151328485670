import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'

import { ArtList } from './components'
import { getPrintQuantity } from './components/ArtList'
import { ArtDocument } from './graphql/__generated__'
import { isCustomCover } from './OrderDetails'

export default function Art() {
  const [loadArt, { data, error, loading }] = useLazyQuery(ArtDocument, {
    fetchPolicy: 'network-only',
    pollInterval: 10_000,
  })
  useEffect(loadArt, [])

  if (error) {
    return <p className="p-4">Error loading.</p>
  }

  if (!data) {
    return <p className="p-4">Loading...</p>
  }

  const covers = data?.activeCustomCovers

  let awaitingApproval = []
  let ready = []
  let qtyByCoverId = {}
  for (let i=0; i < data.activeCustomCovers.length; i++) {
    const cover = data.activeCustomCovers[i]

    if (!cover.approvedAt){
      awaitingApproval.push(cover)
    } else {
      const { needed, printed } = getPrintQuantity(cover)
      ready.push(cover)
    }
  }

  const refreshButton = (
    <button
      className={ "ml-2 text-2xl bg-gray-200 hover:bg-gray-300 rounded-full " +
                  "p-1 h-6 w-6 " + (loading ? " bg-gray-700 text-white" : "") }
      style={{ lineHeight: '0' }}
      onClick={ loadArt }>
        <span className="relative top-[-.25rem]">↻</span>
    </button>
  )

  return <div className="space-y-16 pb-96">
    <div>
      <h1 className="text-lg">
        Ready for Print

        { refreshButton }
      </h1>

      <ArtList art={ ready } />
    </div>
    <div>
      <h1 className="text-lg">
        Awaiting Approval

        { refreshButton }
      </h1>

      <ArtList art={ awaitingApproval } />
    </div>
  </div>
}
