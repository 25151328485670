import React from 'react'
import { useLazyQuery } from '@apollo/client'
import { SignVideosDocument } from '../graphql/__generated__'
import { Loading } from '.'

interface PlayerProps {
  source: string
}

const IMAGE_EXTS = ["jpg", "jpeg", "png", "gif", "avic", "heic", "tiff"]

const Player = ({ source }: PlayerProps) => {
  const [signURLs, { loading, data, error }] = useLazyQuery(SignVideosDocument, {
    variables: {
      urls: [source],
    },
  })

  if (!source) {
    return <>No source.</>
  }

  const ext = source.split('.').pop() || 'mp4'
  const isImage = IMAGE_EXTS.indexOf(ext.toLowerCase()) !== -1
  const isS3URL = source.indexOf('s3.amazonaws.com') !== -1

  if (!loading && !data && !error && isS3URL) {
    signURLs()
  }

  if (loading) {
    return <Loading />
  }

  if (error || !data) {
    return <>Error loading file.</>
  }

  if (isImage) {
    return <img alt="Tile" className="m-auto max-h-48" src={ data!.signVideoURLs!.signedURLs![0]! } />
  } else {
    return <video controls muted className="w-full max-h-48">
      <source src={ isS3URL ? data!.signVideoURLs!.signedURLs![0]! : source } type="video/mp4" />
    </video>
  }
}

export default Player
