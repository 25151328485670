/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type DownloaderQueryVariables = {
    orderID: string;
};
export type DownloaderQueryResponse = {
    readonly videoURL: {
        readonly URL: string;
        readonly SHA256: string;
    };
};
export type DownloaderQuery = {
    readonly response: DownloaderQueryResponse;
    readonly variables: DownloaderQueryVariables;
};



/*
query DownloaderQuery(
  $orderID: ID!
) {
  videoURL(orderID: $orderID) {
    URL
    SHA256
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderID"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "orderID",
        "variableName": "orderID"
      }
    ],
    "concreteType": "VideoRef",
    "kind": "LinkedField",
    "name": "videoURL",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "URL",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "SHA256",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DownloaderQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DownloaderQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9ab96938db66bbc7c3694cdce070ed7f",
    "id": null,
    "metadata": {},
    "name": "DownloaderQuery",
    "operationKind": "query",
    "text": "query DownloaderQuery(\n  $orderID: ID!\n) {\n  videoURL(orderID: $orderID) {\n    URL\n    SHA256\n  }\n}\n"
  }
};
})();
(node as any).hash = '2575ff5e8d2859920467f0258333c912';
export default node;
