import React from 'react'
import { useLazyQuery } from '@apollo/client'
import { SignVideosDocument } from '../graphql/__generated__'
import { Loading } from '.'

interface VideoLinkProps {
  href: string | undefined | null
  className: string
  children: any
}

const VideoLink = ({ href, children, className }: VideoLinkProps) => {
  const [signURLs, { loading, data, error }] = useLazyQuery(SignVideosDocument, {
    variables: {
      urls: [href || ''],
    },
  })

  if (!href) {
    return <>No href.</>
  } else if (!loading && !data && !error) {
    signURLs()
  }

  if (loading) {
    return <Loading />
  }

  if (error || !data) {
    return <>Error loading.</>
  }

  return <a href={ data!.signVideoURLs!.signedURLs![0]! } className={ className } target="_blank" rel="noreferrer">
    { children }
  </a>
}

export default VideoLink
