import {
  Environment,
  Network,
  RecordSource,
  RequestParameters,
  Store,
  Variables
} from 'relay-runtime'
import jwt_decode from 'jwt-decode'

import { DEBUG } from './Debug'
import { Auth0Client } from '@auth0/auth0-spa-js'

let url = 'https://loom-server.sendheirloom.com/query'

if (DEBUG("local_server")) {
  url = 'http://localhost:8080/query'
}

export function tokenOptions(): any {
  return {
    audience: "https://heirloom.us.auth0.com/api/v2/",
    scope: "read:current_user update:current_user_metadata profile email openid",
    cacheLocation: 'localstorage',
  }
}

export function auth0Options(): any {
  // Also appear in the element in App.tsx
  return {
    domain: "auth.sendheirloom.com",
    client_id: "oBe57B5gP8Yv1wJy5TGjZkf1OhbfAl7U",
    cache_location: "localstorage",
    redirect_uri: window.location.origin,
  }
}

interface DecodedToken {
  exp: number
}

async function fetchQuery(
  operation: RequestParameters,
  variables: Variables,
) {
  let token = localStorage.token

  if (token) {
    let decoded: DecodedToken|null = null
    try {
      decoded = jwt_decode(token)
    } catch (e) {}

    if (!decoded || ((decoded.exp - 5) < (+new Date() / 1000))){
      // The token is expired, don't try to use it
      console.log("Token expired, clearing")
      delete localStorage.token
      token = undefined
    }
  }

  if (token === undefined || token === "undefined") {
    const auth0Client = new Auth0Client(auth0Options())
    try {
      token = await auth0Client.getTokenSilently(tokenOptions())
    } catch (e) {
      // We can't get the token silently if the user has never authed our scopes
      token = await auth0Client.getTokenWithPopup(tokenOptions())
    }
    localStorage.token = token
  }

  return fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  }).then(response => {
    return response.json()
  })
}

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
})

export default environment
