import { makeVar, FieldPolicy } from '@apollo/client'
import { Device } from '../__generated__'

export const deviceMapVar = makeVar<{ [key: string]: Device }>({})

export const DevicesPolicy: FieldPolicy = {
  read() {
    return Object.values(deviceMapVar())
  },
}
