import React from 'react'
import { useMutation } from '@apollo/client'
import { UpdateCustomerDocument } from '../graphql/__generated__'
import type { ApolloError } from '@apollo/client'
import type { IOrder } from '../graphql'

interface FlagButtonProps {
  customerId: string
  flag: IOrder["customer"]["flagOrders"]
}

const FlagButton = ({ customerId, flag }: FlagButtonProps) => {
  const [updateCustomer, { loading, data, error }] = useMutation(UpdateCustomerDocument, {
    onError: (error: ApolloError) => {
      console.error("Error unflagging", error)
    }
  })

  if (!flag) {
    return <></>
  }

  const createdAtDate = new Date(flag.createdAt)

  function unflag() {
    updateCustomer({
      variables: {
        customerID: customerId,
        fields: ['flagOrders'],
        prevValues: {
          flagOrders: flag,
        },
        newValues: {
          flagOrders: null,
        }
      }
    })
  }

  let emoji = flag.emoji
  if (!emoji) {
    emoji = ["🚩"]
  }

  let buttonValue = 
    <span className="text-2xl">{ emoji.join([' ']) }</span>
  if (loading) {
    buttonValue = "S"
  } else if (error) {
    buttonValue = "E"
  } else if (data) {
    buttonValue = ""
  }

  return <button title={`Flagged by ${ flag.createdByName } at ${ createdAtDate.toLocaleDateString() }`} onClick={ unflag }>
    { buttonValue }
  </button>
}

export default FlagButton
