import React, { useEffect, useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { printPDFFromURL, printPNGFromURL } from './Daemon/Print'
import { options as fulfillmentOptions } from './components/FulfillmentOptions'

interface Props {
  url: string,
  type: string,
  hotkey: string,
  title: string,
  paperSize: string,
  autoPrint: boolean,
}

export default function PrintButton({ url, type, title, hotkey, paperSize, autoPrint }: Props) {
  useHotkeys(hotkey, () => {
    if (fulfillmentOptions.keyboardShortcuts()) {
      handleClick()
    }
  })

  const handleClick = useCallback(
    () => {
      if (type === 'pdf') {
        printPDFFromURL(paperSize, url)
      } else if (type === 'image') {
        printPNGFromURL(paperSize, url)
      } else {
        throw new Error("Type not understood")
      }
    },
    [paperSize, url, type],
  )

  useEffect(() => {
    if (url && autoPrint) {
      console.log(`Auto printing ${ title }...`)
      handleClick()
    }
  }, [url, autoPrint, handleClick, title])

  let body = `Print ${ title }`
  const re = new RegExp(`([${ hotkey.toUpperCase() }${ hotkey.toLowerCase() }])`)
  body = body.replace(re, '<strong className="keyboard-shortcut-indicator">$1</strong>')
  // TODO Deal with escaping?

  return <>
    <button
      className="App-print-button"
      onClick={handleClick}
      dangerouslySetInnerHTML={{ __html: body }}
      ></button>
  </>
}
