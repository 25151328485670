import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { UpdateOrderDocument } from '../graphql/__generated__'
import DatePicker from 'react-date-picker';
import type { IOrder } from '../graphql'
import type { ApolloError } from '@apollo/client'

interface ScheduledDeliveryPickerProps {
  order: IOrder
}

const ScheduledDeliveryPicker = ({ order }: ScheduledDeliveryPickerProps) => {
  const [value, setValue] = useState<Date | undefined>(order.scheduledDeliveryDate && new Date(order.scheduledDeliveryDate));
  const [changed, setChanged] = useState<boolean>(false);
  const [updateOrder, { loading, data, error }] = useMutation(UpdateOrderDocument, {
    onError: (error: ApolloError) => {
      setChanged(true)

      console.error("Error saving change", error)
    }
  })

  if (!order) {
    return <>No order.</>
  }

  function saveChanges() {
    updateOrder({
      variables: {
        orderId: order.id,
        fields: ['scheduledDeliveryDate'],
        prevValues: {
          scheduledDeliveryDate: order.scheduledDeliveryDate,
        },
        newValues: {
          scheduledDeliveryDate: value,
        }
      }
    })

    setChanged(false)
  }

  function onChange(newValue: Date) {
    setValue(newValue)
    setChanged(true)
  }

  let changedBlock: JSX.Element = <></>
  if (data) {
    changedBlock = <div className="m-6 p-3 bg-green-200">
      Changes saved.
    </div>
  }
  if (loading) {
    changedBlock = <div className="m-6 p-3 bg-gray-200">
      Saving...
    </div>
  }
  if (changed) {
    changedBlock = <div className="m-6 p-3 bg-gray-200">
      { error && <div className="m-2 p-2 bg-red-200">Error saving changes.</div> }

      <h4 className="inline-block mr-6">You have unsaved changes.</h4>
      <button className="btn btn-primary mr-2" onClick={ saveChanges }>Save</button>
    </div>
  }

  return <>
    <DatePicker
      // @ts-ignore We aren't defining the exact function type onChange wants
      onChange={ onChange }
      value={ value }
    />
    { changedBlock }
  </>
}

export default ScheduledDeliveryPicker
