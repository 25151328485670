import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Device, ConnectedDevicesDocument } from '../graphql/__generated__'
import { deviceMapVar } from '../graphql/local/devices'
import { marryDevices } from '../Daemon'
import { DeviceDisplay } from './DeviceDisplay'

export const DeviceMonitor = () => {
  useEffect(() => {
    marryDevices((devices) => {
      const store = deviceMapVar()
      const newStore: { [key: string]: Device } = {}

      devices.forEach((device) => {
        if (device.kind !== 'card') {
          return
        }

        let record = store[device.id]
        if (!record) {
          record = {
            ...device,
            transferredOrderId: null,
            video: {
              percentageTransferred: null,
              transferStatus: null,
            },
          }
        }

        newStore[device.id] = record
      })

      deviceMapVar(newStore)
    })
  }, [])

  const { data } = useQuery(ConnectedDevicesDocument)

  return <DeviceDisplay devices={data?.devices} />
}
