import { commitLocalUpdate } from 'relay-runtime'
import type { IEnvironment, RecordProxy, RecordSourceProxy } from 'relay-runtime'

import { startTransfer } from '../Daemon/Transfer'
import { options as fulfillmentOptions } from '../components/FulfillmentOptions'
import type { OrderDetails_order } from '../__generated__/OrderDetails_order.graphql'
import type { TransferProgress } from '../Daemon/Transfer'

export interface LocalDevice {
  id: string
  status: string
  videoTransferStatus: string
  videoPercentageTransferred: string
  transferredOrderId: string
}

interface Order extends OrderDetails_order {}

// The video we load onto books our customer's will load themselves.
// Also in shopify.go.
export const BLANK_VIDEO = "https://public.sendheirloom.com/DELETE_ME.mp4"

export function getOrder(store: RecordSourceProxy, orderId: string): (RecordProxy|null) {
  return store.get(orderId) || null
}

// It would be nice if we could just use the relay vars for this, but they update async
let orderStatuses = new Map()
let deviceStatuses = new Map()

function startLoading(environment: IEnvironment, device: LocalDevice, order: Order){
  if (!order || !order.cardVideoURL || !order.cardVideoSHA256)
    return

  const updateVal = function (store: RecordSourceProxy, key: string, val: number|string){
    let deviceRef = store.get("device:" + device.id)
    if (deviceRef) {
      deviceRef.setValue(val, key)
    }

    let orderRef = getOrder(store, order.id)
    if (orderRef) {
      orderRef.setValue(val, key)
    }

    if (key === 'videoTransferStatus'){
      orderStatuses.set(order.id, val)
      deviceStatuses.set(device.id, val)
    }
  }

  commitLocalUpdate(environment, store => {
    updateVal(store, 'videoTransferStatus', 'transferring')
    updateVal(store, 'videoPercentageTransferred', 0)
    updateVal(store, 'transferredOrderId', "")
  })

  const { formatBooks, verifyTransfers } = fulfillmentOptions

  const res = startTransfer(device.id, order.cardVideoSHA256!, formatBooks(), verifyTransfers(), (p: TransferProgress) => {
    commitLocalUpdate(environment, store => {
      updateVal(store, 'videoPercentageTransferred', p.percent_complete)
    })
  })

  res.then(() => {
    commitLocalUpdate(environment, store => {
      updateVal(store, 'videoTransferStatus', 'transferred')
      updateVal(store, 'transferredOrderId', order.id)
    })
  }, (err) => {
    commitLocalUpdate(environment, store => {
      console.error("Error transferring video", err)

      if (err?.message === "Entry not downloaded"){
        // The daemon was likely restarted between when this was downloaded and when it was transferred
        let orderRef = getOrder(store, order.id)
        orderRef?.setValue(null, 'videoDownloadStatus')

        updateVal(store, 'videoTransferStatus', '')
      } else {
        updateVal(store, 'videoTransferStatus', 'error')
      }
    })
  })
}

function findReadyOrder(orders: Order[], allowBlank: boolean): Order | null{
  for (let i=orders.length; i--;){
    if (!orders[i] || !orders[i].shopify) {
      continue
    }
    if (orders[i].shopify.videoManifest &&
        orders[i].shopify.videoManifest.length === 1 &&
        orders[i].shopify.videoManifest[0] === BLANK_VIDEO &&
        !allowBlank) {
      // We can only load our 'blank' instructional video onto books which don't have a
      // bug which causes customer confusion. See Daemon/Devices.ts.
      console.log("Skipping device as video is blank and device does not support customer loading.")
      continue
    }

    let transferStatus = orderStatuses.get(orders[i].id)
    if (orders[i].videoDownloadStatus === "downloaded" && !transferStatus){
      return orders[i]
    }
  }

  return null
}

export function updateDevices(environment: IEnvironment, orders: Order[], devices: LocalDevice[]){
  if (!devices)
    return

  devices.forEach(device => {
    let transferStatus = deviceStatuses.get(device.id)
    if (device.status === 'active' && !transferStatus){
      let readyOrder = findReadyOrder(orders, device.allowBlank)
      if (readyOrder){
        startLoading(environment, device, readyOrder)
      }
    }
  })
  
}
