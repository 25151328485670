/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type OrderShipmentMutationVariables = {
    orderID: string;
    rateID: string;
    isReturn?: boolean | null;
};
export type OrderShipmentMutationResponse = {
    readonly orderShipmentFromRate: {
        readonly label_id: string;
        readonly is_return: boolean | null;
        readonly status: string;
        readonly voided: boolean;
        readonly shipment_id: string;
        readonly tracking_number: string;
        readonly label_download: {
            readonly pdf: string | null;
            readonly png: string | null;
        } | null;
        readonly form_download: {
            readonly href: string | null;
        } | null;
    };
};
export type OrderShipmentMutation = {
    readonly response: OrderShipmentMutationResponse;
    readonly variables: OrderShipmentMutationVariables;
};



/*
mutation OrderShipmentMutation(
  $orderID: ID!
  $rateID: ID!
  $isReturn: Boolean
) {
  orderShipmentFromRate(orderID: $orderID, rateID: $rateID, isReturn: $isReturn) {
    label_id
    is_return
    status
    voided
    shipment_id
    tracking_number
    label_download {
      pdf
      png
    }
    form_download {
      href
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isReturn"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderID"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rateID"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "isReturn",
        "variableName": "isReturn"
      },
      {
        "kind": "Variable",
        "name": "orderID",
        "variableName": "orderID"
      },
      {
        "kind": "Variable",
        "name": "rateID",
        "variableName": "rateID"
      }
    ],
    "concreteType": "ShippingLabel",
    "kind": "LinkedField",
    "name": "orderShipmentFromRate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_return",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "voided",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shipment_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tracking_number",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LabelDownload",
        "kind": "LinkedField",
        "name": "label_download",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pdf",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "png",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FormDownload",
        "kind": "LinkedField",
        "name": "form_download",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "href",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderShipmentMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrderShipmentMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "5d300063e46e4112424f46e7ea5d2cf6",
    "id": null,
    "metadata": {},
    "name": "OrderShipmentMutation",
    "operationKind": "mutation",
    "text": "mutation OrderShipmentMutation(\n  $orderID: ID!\n  $rateID: ID!\n  $isReturn: Boolean\n) {\n  orderShipmentFromRate(orderID: $orderID, rateID: $rateID, isReturn: $isReturn) {\n    label_id\n    is_return\n    status\n    voided\n    shipment_id\n    tracking_number\n    label_download {\n      pdf\n      png\n    }\n    form_download {\n      href\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd849f779bdb415bd7d222ce7f4cb59ee';
export default node;
