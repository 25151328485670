import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useHotkeys } from 'react-hotkeys-hook'
import { UpdateOrderDocument } from '../graphql/__generated__'
import TimeAgo from 'react-timeago'
import { useAuth0 } from '@auth0/auth0-react'
import type { ApolloError } from '@apollo/client'
import type { ChangeEvent } from 'react'
import type { OrderDetails_order } from '../__generated__/OrderDetails_order.graphql'

interface OrderNotesProps {
  order: OrderDetails_order
}

let QUICK_MSGS = {
  'sending-preview': "Sending customer preview.",
  'want-another': "Customer wants another copy. I already made a new label.",
  'vertical': "Emailed customer to ask if they want a vertical cover.",
  'made-label': "Already made a new label.",
  'video-issue': "There's an issue with the video, looking into it.",
  'ready': "Ready to go out.",
}

function formatLabel(key: string): string {
  return key.replace(/-/g, ' ').replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    }
  )
}

const OrderNotes = ({ order }: OrderNotesProps) => {
  const [newNote, setNewNote] = useState<string>("");

  // Apollo would be able to manage this automatically, but this object is coming from relay-world
  // This means, for the moment, changes here don't propagate to relay-land
  const [notes, setNotes] = useState<OrderDetails_order["notes"]|undefined>(undefined)

  const [updateOrder, { loading, data, error }] = useMutation(UpdateOrderDocument, {
    onError: (error: ApolloError) => {
      console.error("Error saving change", error)
    }
  })

  const { user } = useAuth0()

  useHotkeys('command+enter', save, {
    enableOnTags: ['TEXTAREA'],
  })

  useEffect(() => {
    setNotes(order && order.notes)
  }, [order, order.notes, setNotes])

  if (!order) {
    return <>No order.</>
  }

  function save() {
    if (!order) {
      return
    }

    const newNotes = (notes ? notes.slice() : [])
    const prevNotes = newNotes.slice()

    newNotes.push({
      id: Math.floor(Math.random() * 1e12).toString(),
      createdAt: new Date(),
      createdBy: {
        name: user?.given_name || "Unknown",
        email: user?.email || "unknown",
      },
      content: newNote,
    })

    updateOrder({
      notifyOnNetworkStatusChange: true,
      variables: {
        orderId: order.id,
        fields: ['notes'],
        prevValues: {
          // @ts-ignore it doesn't like the readonly
          notes,
        },
        newValues: {
          notes: newNotes,
        }
      },
      onError: (error: ApolloError) => {
        console.error("Error saving note", error)
        setNotes(prevNotes)
      },
    }).then(() => {
      setNewNote("")
    })

    setNotes(newNotes)
  }

  function remove(index: number) {
    const sure = window.confirm("Are you sure you wish to remove this note?")
    if (!sure) return

    const newNotes = (notes ? notes.slice() : [])
    const prevNotes = newNotes.slice()

    newNotes.splice(index, 1)

    updateOrder({
      variables: {
        orderId: order.id,
        fields: ['notes'],
        prevValues: {
          // @ts-ignore it doesn't like the readonly
          notes,
        },
        newValues: {
          notes: newNotes,
        },
      },
      onError: (error: ApolloError) => {
        console.error("Error removing note", error)
        setNotes(prevNotes)
      },
    })

    setNotes(newNotes)
  }

  function onChange(event: ChangeEvent<HTMLTextAreaElement>){
    let val = event.target.value
    setNewNote(val)
  }

  let respBlock: JSX.Element = <></>
  if (loading) {
    respBlock = <div className="my-2 p-2 bg-gray-100">
      Saving...
    </div>
  }
  if (data) {
    respBlock = <div className="my-2 p-2 bg-green-200">
      Note saved.
    </div>
  }
  if (error) {
    console.error("Error saving", error)

    respBlock = <div className="my-2 p-2 bg-red-200">
      Error saving changes.
    </div>
  }

  return <>
    <h2>Notes</h2>
    <ul>
      { notes && notes.map((note, i) => {
        return <li key={ i } className="border-gray-100 border p-3 mb-1 relative">
          <button
            style={{margin: 0, fontSize: '1.5em', padding: 0}}
            className="absolute -right-0 -top-0"
            onClick={ () => remove(i) }
            >
              ×
          </button>
          <div className="mb-2 pr-3 flex" style={{fontSize: '0.8em'}}>
            <h4 className="text-left flex-1 m-0" style={{paddingLeft: '0.2em'}}>{ note.createdBy.name }</h4>
            {/*
            // @ts-ignore */}
            <TimeAgo className="text-right flex-1" date={ note.createdAt }
            />
          </div>
          <p className="text-left w-full font-sans text-sm break-words">{ note.content }</p>
        </li>
      })}
    </ul>

    <form className="pt-1 flex" onSubmit={ (e) => { e.preventDefault(); save() }}>
      <textarea className="flex-grow bg-gray-100 h-24 mr-2 p-2 font-sans text-sm" onChange={ onChange } value={ newNote }></textarea>
      <input className="p-2 border-gray-900 border cursor-pointer bg-white align-bottom" type="submit" value="Save" />
    </form>

    { respBlock }

    <h4>Quick Messages</h4>

    {
      Object.entries(QUICK_MSGS).map( ([key, msg]) => {
        return <a className="inline-block m-2 p-2 bg-gray-100 cursor-pointer"
                  key={ key }
                  onClick={ () => { setNewNote(msg) } }>{ formatLabel(key) }</a>
      })
    }
  </>
}

export default OrderNotes
