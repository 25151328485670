import {
  send,
  registerMessageKind,
  randomSequence,
  onSocketClose,
} from './Socket'

registerMessageKind('transfer:progress', handleTransferProgress)
registerMessageKind('transfer:complete', handleTransferComplete)
registerMessageKind('transfer:error', handleTransferError)

interface TransferListener {
  progressNotifier: (progress: TransferProgress) => void
  completeNotifier: () => void
  errorNotifier: (error: TransferError) => void
}

// TODO Refactor this and the downloader into a single concept
let transferListeners = new Map<string, TransferListener[]>()

export interface TransferStart {
  transfer_id: string

  device_id: string
  hash_sha256: string
  format: boolean
  verify: boolean
}

export interface TransferError {
  transfer_id: string

  message: string
  code?: string
}

export interface TransferProgress {
  transfer_id: string

  percent_complete: number
  time_remaining_seconds: number
}

export interface TransferComplete {
  transfer_id: string
}

onSocketClose(() => {
  for (let id of transferListeners.keys()) {
    const message: TransferError = {
      transfer_id: id,
      message: 'Daemon disconnected',
    }

    handleTransferError(message)
  }
})

function handleTransferProgress(message: TransferProgress) {
  let listeners = transferListeners.get(message.transfer_id)
  if (listeners) {
    for (let i = 0; i < listeners.length; i++) {
      listeners[i].progressNotifier && listeners[i].progressNotifier(message)
    }
  }
}

function handleTransferComplete(message: TransferComplete) {
  let listeners = transferListeners.get(message.transfer_id)
  if (listeners) {
    for (let i = 0; i < listeners.length; i++) {
      listeners[i].completeNotifier && listeners[i].completeNotifier()
    }
  }

  transferListeners.delete(message.transfer_id)
}

function handleTransferError(message: TransferError) {
  let listeners = transferListeners.get(message.transfer_id)
  if (listeners) {
    for (let i = 0; i < listeners.length; i++) {
      listeners[i].errorNotifier && listeners[i].errorNotifier(message)
    }
  }

  transferListeners.delete(message.transfer_id)
}

export async function startTransfer(
  deviceId: string,
  fileHash: string,
  format: boolean,
  verify: boolean,
  progressCb: (progress: TransferProgress) => void
): Promise<TransferError> {
  const transferId = randomSequence()

  send(
    JSON.stringify({
      kind: 'transfer:start',
      transfer_id: transferId,
      format,
      verify,
      device_id: deviceId,
      hash_sha256: fileHash,
    })
  )

  let promise = new Promise<TransferError>((resolveCb, rejectCb) => {
    let list: TransferListener[] = []

    // This doesn't super make sense as it's not currently possible for anyone other than us to bind
    // onto this transferId. transferListeners is still an array to make it easier to refactor this into downloader.
    let existingList = transferListeners.get(transferId)
    if (existingList) list = existingList

    list.push({
      progressNotifier: progressCb,
      completeNotifier: resolveCb,
      errorNotifier: rejectCb,
    })

    transferListeners.set(transferId, list)
  })

  return promise
}
