import React, { useEffect, useRef, memo } from 'react'
import { useQuery, makeVar, useReactiveVar } from '@apollo/client'
import { AllOrdersDocument } from './graphql/__generated__'
import type { RouteComponentProps } from '@reach/router'
import type { OrderFilters } from './graphql/__generated__'
import { OrderTimeline, OrderTimelineFilters, Loading } from './components'

interface OrderDashboardProps extends RouteComponentProps {}

const expressFilter = makeVar<boolean>(false)
const shippedNoPickupFilter = makeVar<boolean>(false)

const MemoizedTimeline = memo(OrderTimeline, (a, b) => {
  const keys = Object.keys(a.filters)
  for (let i=0; i < keys.length; i++) {
    // @ts-ignore
    if (b[keys[i]] !== a[keys[i]]) {
      return false
    }
  }

  if (a.orders.length !== b.orders.length) {
    return false
  }

  for (let i=0; i < a.orders.length; i++){
    if (a.orders[i] !== b.orders[i]){
      return false
    }
  }

  return true
})

const OrderDashboard = (_: OrderDashboardProps) => {
  const block = useRef(null);
  const expressFilterVar = useReactiveVar(expressFilter)
  const shippedNoPickupFilterVar = useReactiveVar(shippedNoPickupFilter)

  const { loading, data, error, fetchMore, refetch } = useQuery(AllOrdersDocument, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setTimeout(() => {
        if (block && block.current){
          // @ts-ignore
          block.current.style.minHeight = ''
        }
      }, 0)
    },
    variables: {
      limit: 50,
      filters: {
        express: expressFilter(),
        shippedNoPickup: shippedNoPickupFilter(),
      },
    },
  })

  useEffect(() => { refetch() }, [refetch, expressFilterVar, shippedNoPickupFilterVar])

  if (loading && !data) {
    return <Loading />
  }

  if (error) {
    return <div>Error loading orders.</div>
  }

  if (!data || !data.allOrders) {
    return <div>No orders.</div>
  }

  function getFilters(): OrderFilters {
    return {
      express: expressFilter(),
      shippedNoPickup: shippedNoPickupFilter(),
    }
  }

  function setFilters(filters: OrderFilters) {
    expressFilter(filters.express || false)
    shippedNoPickupFilter(filters.shippedNoPickup || false)
  }

  function loadMore() {
    // @ts-ignore
    if (block?.current?.getBoundingClientRect){
      // When the timeline is rerendered it becomes height nil, resetting our scroll position
      // @ts-ignore
      block.current.style.minHeight = block.current.getBoundingClientRect().height + 'px'
    }

    if (!fetchMore) return
    fetchMore({
      variables: {
        cursor: data?.allOrders?.cursor,
        limit: 50,
        filters: {
          express: expressFilter(),
          shippedNoPickup: shippedNoPickupFilter(),
        },
      },
    })
  }

  return (
    <>
      <article ref={ block }>
        <OrderTimelineFilters
          getFilters={ getFilters }
          setFilters={ setFilters }
        />
        <MemoizedTimeline
          orders={ data.allOrders.orders }
          filters={{express: expressFilterVar, shippedNoPickup: shippedNoPickupFilterVar}}
        />
      </article>
      <button
        className="App-load-more"
        disabled={loading || !fetchMore}
        onClick={ () => loadMore() }
      >
        {loading ? 'Loading...' : 'Load more'}
      </button>
    </>
  )
}

export default OrderDashboard
