/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type PipelineQueryVariables = {};
export type PipelineQueryResponse = {
    readonly searchOrders: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly status: string;
        readonly videoDownloadStatus: string;
        readonly videoTransferStatus: string | null;
        readonly cardVideoURL: string | null;
        readonly cardVideoSHA256: string | null;
        readonly shopify: {
            readonly videoManifest: ReadonlyArray<string> | null;
            readonly lineItems: ReadonlyArray<{
                readonly SKU: string;
                readonly quantity: number;
            }> | null;
        } | null;
        readonly cardVideoEncodedConfig: {
            readonly vertical: boolean;
            readonly allowVertical: boolean;
        } | null;
        readonly balanceUsages: ReadonlyArray<{
            readonly id: string;
            readonly vendorSaleVendorId: string | null;
            readonly vendorSaleId: string | null;
        }> | null;
    }>;
    readonly devices: ReadonlyArray<{
        readonly id: string;
        readonly status: string;
        readonly videoTransferStatus: string | null;
        readonly transferredOrderId: string | null;
        readonly allowBlank: boolean | null;
    } | null>;
};
export type PipelineQuery = {
    readonly response: PipelineQueryResponse;
    readonly variables: PipelineQueryVariables;
};



/*
query PipelineQuery {
  searchOrders(query: "", destinationsBecomeOrders: true) {
    id
    name
    status
    cardVideoURL
    cardVideoSHA256
    shopify {
      videoManifest
      lineItems {
        SKU
        quantity
      }
    }
    cardVideoEncodedConfig {
      vertical
      allowVertical
    }
    balanceUsages {
      id
      vendorSaleVendorId
      vendorSaleId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoTransferStatus",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "destinationsBecomeOrders",
        "value": true
      },
      {
        "kind": "Literal",
        "name": "query",
        "value": ""
      }
    ],
    "concreteType": "Order",
    "kind": "LinkedField",
    "name": "searchOrders",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cardVideoURL",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cardVideoSHA256",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ShopifyOrder",
        "kind": "LinkedField",
        "name": "shopify",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "videoManifest",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ShopifyLineItem",
            "kind": "LinkedField",
            "name": "lineItems",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "SKU",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantity",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VideoConfiguration",
        "kind": "LinkedField",
        "name": "cardVideoEncodedConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "vertical",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "allowVertical",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BalanceUsage",
        "kind": "LinkedField",
        "name": "balanceUsages",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "vendorSaleVendorId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "vendorSaleId",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "videoDownloadStatus",
            "storageKey": null
          },
          (v2/*: any*/)
        ]
      }
    ],
    "storageKey": "searchOrders(destinationsBecomeOrders:true,query:\"\")"
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "devices",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "transferredOrderId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "allowBlank",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PipelineQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PipelineQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "8f8ed1525d38837bc1724db1de0ca34d",
    "id": null,
    "metadata": {},
    "name": "PipelineQuery",
    "operationKind": "query",
    "text": "query PipelineQuery {\n  searchOrders(query: \"\", destinationsBecomeOrders: true) {\n    id\n    name\n    status\n    cardVideoURL\n    cardVideoSHA256\n    shopify {\n      videoManifest\n      lineItems {\n        SKU\n        quantity\n      }\n    }\n    cardVideoEncodedConfig {\n      vertical\n      allowVertical\n    }\n    balanceUsages {\n      id\n      vendorSaleVendorId\n      vendorSaleId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f78331fe80fcc202138b6bb9763e8f86';
export default node;
