import React from 'react'
import { useMutation } from '@apollo/client'
import { ReencodeDocument } from '../graphql/__generated__'
import type { IOrder } from '../graphql'

interface ReencodeButtonProps {
  order: IOrder
}

const ReencodeButton = ({ order }: ReencodeButtonProps) => {
  const [reencode, { loading, error }] = useMutation(ReencodeDocument)

  if (!order) {
    return <>No order.</>
  }

  let button = <button
    className="btn btn-primary ml-2 mt-2"
    onClick={ () => reencode({variables: {orderID: order.id}}) }
    disabled={ loading }
    >
      Reencode
    </button>

  return <>
    { button }

    { loading && <div className="p-2">Saving...</div> }
    { error && <div className="p-2">There was an error.</div> }
  </>
}

export default ReencodeButton
