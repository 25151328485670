import React, { useState, useRef } from 'react'
import type { ChangeEvent } from 'react'
import { useMutation } from '@apollo/client'
import { UpdateOrderDocument } from '../graphql/__generated__'
import type { IOrder } from '../graphql'
import type { ApolloError } from '@apollo/client'

interface AudioGainProps {
  order: IOrder
}

const AudioGain = ({ order }: AudioGainProps) => {
  const [value, setValue] = useState<number | null | undefined>(order.manualGain)
  const [changed, setChanged] = useState<boolean>(false)
  const rangeRef = useRef<HTMLInputElement>(null)
  const [updateOrder, { loading, data, error }] = useMutation(UpdateOrderDocument, {
    onError: (error: ApolloError) => {
      setChanged(true)

      console.error("Error saving change", error)
    }
  })

  if (!order) {
    return <>No order.</>
  }

  function saveChanges() {
    updateOrder({
      variables: {
        orderId: order.id,
        fields: ['manualGain'],
        prevValues: {
          manualGain: order.manualGain,
        },
        newValues: {
          manualGain: value,
        }
      }
    })

    setChanged(false)
  }

  function onChange(evt: ChangeEvent<HTMLInputElement>){
    setValue(+evt.target.value)
    setChanged(true)
  }

  function reset() {
    setValue(null)
    setChanged(true)

    if (rangeRef.current) {
      rangeRef.current.value = ""
    }
  }

  let changedBlock: JSX.Element = <></>
  if (data) {
    changedBlock = <div className="m-6 p-3 bg-green-200">
      Changes saved.
    </div>
  }
  if (loading) {
    changedBlock = <div className="m-6 p-3 bg-gray-200">
      Saving...
    </div>
  }
  if (changed) {
    changedBlock = <div className="m-6 p-3 bg-gray-200">
      { error && <div className="m-2 p-2 bg-red-200">Error saving changes.</div> }

      <h4 className="inline-block mr-6">You have unsaved changes.</h4>
      <button className="btn btn-primary mr-2" onClick={ saveChanges }>Save</button>
    </div>
  }

  return <>
    <input ref={ rangeRef } type="range" min="-30" max="30" defaultValue={ order.manualGain || undefined } onChange={ onChange } />
    <output className="w-20 p-2 ml-2 inline-block text-center">
      { (value === undefined || value === null ? "auto" : value.toString() + ' dB') }
    </output>
    <button onClick={ reset } className="p-2 ml-2 btn btn-primary">Reset</button>
    { changedBlock }
  </>
}

export default AudioGain
