import React, { useState } from 'react'
import { useMutation } from '@apollo/client'

import { renderArtTag } from '../Labels/ArtTag'
import { printPNGFromURL } from '../Daemon/Print'
import type { CoverFragment, DestinationFragment } from '../graphql/__generated__'
import { UpdateCustomCoverApprovalDocument, UpdateCoverPrintCountDocument } from '../graphql/__generated__'
import { filenameWithoutExt } from '../FinalizeDialog/LabelSection'

interface Props {
  art: [Cover]
}

type PrintQuantity = {
  needed: number
  printed: number
  remaining: number
}

const PRINTERS = [
  'lower',
  'upper',
]

type CurrentlyPrinting = {
  [key: string]: string | undefined,
}

export function getPrintQuantity(cover: CoverFragment): PrintQuantity | null {
  if (!cover?.usedByDestinations) {
    return null
  }

  let neededQty = 0
  let printedQty = 0
  for (let i=0; i < cover.usedByDestinations.length; i++){
    const dest = cover.usedByDestinations[i]
    if (dest.state === 'paid' || dest.state === 'hold'){
      neededQty += dest.quantity
      printedQty += dest.coversPrinted
    }
  }

  return {
    needed: neededQty,
    printed: printedQty,
    remaining: neededQty - printedQty,
  }
}

export default function ArtList({ art }: Props){
  const [updateCustomCoverApproval, { loading: loadingApproval, error: approvalError }] = useMutation(UpdateCustomCoverApprovalDocument)
  const [updateCoverPrintCount, { loading: loadingCoverPrintCount, error: coverPrintCountError }] = useMutation(UpdateCoverPrintCountDocument)
  const [currentlyPrinting, setCurrentlyPrinting] = useState<CurrentlyPrinting>({ lower: undefined, upper: undefined })

  const currentlyPrintingOn = (coverId: string): string | undefined => {
    for (let i=0; i < PRINTERS.length; i++){
      const printer = PRINTERS[i]
      if (currentlyPrinting[printer] === coverId){
        return printer
      }
    }
    return undefined
  }

  // sort art by the highest order name in the destinations,
  // such that our prints will be done in rough name order.
  const sortedArt = art.sort((a, b) => {
    const aDest = a.usedByDestinations?.sort((a, b) => +b.order.name - +a.order.name)[0]
    const bDest = b.usedByDestinations?.sort((a, b) => +b.order.name - +a.order.name)[0]

    if (!aDest || !bDest) {
      return 0
    }
    return +aDest.order.name > +bDest.order.name ? 1 : -1
  })

  const setApproval = (coverId: String, approved: boolean) => {
    updateCustomCoverApproval({
      variables: {
        coverID: coverId,
        approved,
      }
    })
  }

  const increment = (coverId: String, incr: Number) => {
    let incrementDest: DestinationFragment | undefined = undefined

    for (let k=0; k < art.length; k++){
      if (art[k].id === coverId){
        for (let i=0; i < art[k].usedByDestinations.length; i++) {
          const dest = art[k].usedByDestinations[i]

          // If all the dests are full, we just pick the last one
          incrementDest = dest

          if (dest.coversPrinted < dest.quantity) {
            break
          }
        }

        if (!incrementDest) {
          throw new Error("No destinations found to increment cover count")
        }

        updateCoverPrintCount({
          variables: {
            destinationID: incrementDest.id,
            oldValue: incrementDest.coversPrinted,
            newValue: incrementDest.coversPrinted + incr,
          },
        })

        break
      }
    }
  }

  const markPrinting = (printer: String, coverId: String) => {
    console.log('marking printing', printer, coverId)
    currentlyPrinting[printer] = coverId
    setCurrentlyPrinting({ ...currentlyPrinting })
  }

  const printDone = (printer: String, coverId: String) => {
    for (let i=0; i < art.length; i++){
      if (art[i].id === coverId){
        let totalQty = 0
        let printedQty = 1
        for (let k=0; k < art[i].usedByDestinations.length; k++){
          totalQty += art[i].usedByDestinations[k].quantity
          printedQty += art[i].usedByDestinations[k].coversPrinted
        }
        if (printedQty >= totalQty){
          currentlyPrinting[printer] = undefined
          setCurrentlyPrinting({ ...currentlyPrinting })
        }

        break
      }
    }

    increment(coverId, 1)
  }

  const printCancelled = (printer: String, coverId: String) => {
    currentlyPrinting[printer] = undefined
    setCurrentlyPrinting({ ...currentlyPrinting })
  }

  const printTag = (orderName: String, coverName: String, qty: Number) => {
    renderArtTag(orderName, coverName).then((canvasRef) => {
      if (!canvasRef || !canvasRef.canvas){
        return
      }

      const image = canvasRef.canvas.toDataURL('image/png')
      printPNGFromURL("2x1", image)
    })
  }

  const printAllTags = (coverId: String) => {
    // We need to come up with labels for all the prints. A single
    // order can include multiple designs, and we don't want swap
    // them with other covers for the same order. To avoid that,
    // we need to label each cover with the design it's meant for.
    // In the UI we label the designs with OrderName:DesignIndex, after
    // sorting the designs by id, so we will do the same here.
    let allByOrder = {}
    for (let k=0; k < art.length; k++){
      if (art[k].id !== coverId){
        continue
      }

      for (let i=0; i < art[k].usedByDestinations.length; i++) {
        const dest = art[k].usedByDestinations[i]
        if (!allByOrder[dest.orderId]){
          allByOrder[dest.orderId] = []
        }

        let entryIndex = Object.values(allByOrder[dest.orderId])
          .findIndex((entry) => entry.designId === dest.designId)

        if (entryIndex === -1) {
          entryIndex = allByOrder[dest.orderId].length
          allByOrder[dest.orderId].push({
            designId: dest.designId,
            entries: [],
          })
        }

        allByOrder[dest.orderId][entryIndex].entries.push({
          orderName: dest.order.name,
          coverName: filenameWithoutExt(art[k].productionImageURL),
          quantity: dest.quantity,
        })
      }
    }

    for (let orderId in allByOrder){
      const order = allByOrder[orderId]
      // sort by designId, as that's what we use when indexing designs
      // in the main listing.

      order.sort((a, b) => (a.designId > b.designId) ? 1 : -1)

      for (let k=0; k < order.length; k++){
        const design = order[k]

        for (let i=0; i < design.entries.length; i++){
          const entry = design.entries[i]
          for (let j=0; j < entry.quantity; j++){
            printTag(entry.orderName + ":" + k + ":" + i, entry.coverName, 1)
          }
        }
      }
    }
  }

  return <div className="pl-8">
    { approvalError && <p className="bg-red-100 p-4 mb-2">Error approving custom cover.</p> }
    { coverPrintCountError && <p className="bg-red-100 p-4 mb-2">Error saving cover print count.</p> }

    <ul>
      <li className="grid grid-cols-12 font-bold mb-4 pb-1 border-b">
        <div />
        <div>Approval { loadingApproval ? "S" : ""  }</div>
        <div>Order</div>
        <div>Name</div>
        <div className="col-span-2">Print</div>
        <div>Needed</div>
        <div className="col-span-2">Printed { loadingCoverPrintCount ? "S" : "" }</div>
        <div>Remaining</div>
        <div>Tags</div>
      </li>

      { art.map((cover) => {
        const qty = getPrintQuantity(cover)
        if (qty === null) {
          return null
        }
        const { needed, printed, remaining } = qty

        const name = filenameWithoutExt(cover.productionImageURL)

        const done = remaining <= 0
        return <li key={ cover.id } className={ "grid grid-cols-12 mb-2 " + (done ? 'bg-green-100' : '') }>
          <div className="col-span-1 py-2 group relative">
            <img className="w-8 inline-block mr-4" src={ cover.thumbnailImageURL } />
            <a
              href={ `https://make.sendheirloom.com/sudo/ownerOfOrderID:${ cover.usedByDestinations[0].orderId }?next=cover/${ cover.id }` }
              target="_blank"
              className="underline">edit</a>
            <div className="absolute left-8 hidden group-hover:block z-10">
              <div className="w-96 bg-black p-4">
                <img className="w-full" src={ cover.productionImageURL } />
              </div>
            </div>
          </div>

          <div className="col-span-1">
            { 
              cover.approvedAt
              ? <div>
                  <button
                    className="p-2 ml-2 bg-red-300 text-white"
                    onClick={ () => setApproval(cover.id, false) }>Cancel</button>
                </div>
              : <div>
                  <button
                    className="p-2 ml-2 bg-gray-200"
                    onClick={ () => setApproval(cover.id, true) }>Approve</button>
                </div>
            }
          </div>

          <div className="col-span-1 py-2">
            { cover.usedByDestinations.map((dest) => dest.order.name).join(", ") }
          </div>

          <div className="col-span-1 py-2">
            <a href={ cover.productionImageURL } target="_blank" className="underline">
              { name.substring(0, 3) }
            </a>
          </div>

          <div className="col-span-2 py-2 space-y-2">
            { Object.values(PRINTERS).map((printer) => {
              if (currentlyPrintingOn(cover.id) === printer) {
                return <div className="inline-block bg-gray-100 p-1 mr-2 space-y-1" key={ printer }>
                  <button
                    onClick={ () => printDone(printer, cover.id) }
                    className="bg-green-200 p-1 mr-2">Done</button>
                  <button
                    onClick={ () => printCancelled(printer, cover.id) }
                    className="bg-red-200 p-1">Cancel</button>
                </div>
              } else {
                return <a
                  key={ printer }
                  className="bg-gray-200 p-2 ml-2"
                  href={ cover.productionImageURL }
                  target="_blank"
                  onClick={ () => markPrinting(printer, cover.id) }>{ printer.substring(0, 1).toUpperCase() }</a>
              }
            }) }
          </div>

          <div className="py-2">
            { needed }
          </div>

          <div className="col-span-2">
            { printed }

            <button
              className="bg-gray-200 p-2 ml-2"
              onClick={ () => increment(cover.id, -1) }>-</button>
            <button
              className="bg-gray-200 p-2 ml-2"
              onClick={ () => increment(cover.id, 1) }>+</button>
          </div>

          <div className="py-2">
            { remaining }
          </div>

          <div>
            { cover.usedByDestinations.length <= 1 &&
              <button
                className="bg-gray-200 p-2 ml-2"
                onClick={ () => printTag(cover.usedByDestinations[0].order.name, name, 1) }>1</button>
            }
            <button
              className="bg-gray-200 p-2 ml-2"
              onClick={ () => printAllTags(cover.id) }>All</button>
          </div>
        </li>
      }) }
    </ul>
  </div>
}
