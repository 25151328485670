import React from 'react'
import { useQuery } from '@apollo/client'
import { HelpScoutConversationsDocument } from '../graphql/__generated__'
import TimeAgo from 'react-timeago'

interface HelpdeskProps {
  email: String
  customerName: String
}

function dateFormatter(value: number, unit: string, suffix: string, epochSeconds: number, defaultFormatter: any) {
  return value + unit[0]
}

const Helpdesk = ({ email, customerName }: HelpdeskProps) => {
  const { data, loading, error } = useQuery(HelpScoutConversationsDocument, {
    variables: {
      emails: [email],
    },
    onError: (error: ApolloError) => {
      console.error("Error loading conversations", error)
    }
  })

  let conversations
  if (data) {
    conversations = [...data.helpScoutConversations.conversations]
  }

  if (email === 'zackbloom@gmail.com' || email.indexOf('@sendheirloom.com') !== -1) {
    // This would give a bunch of junk coversations, we didn't use the customer's
    // actual email in the order.
    conversations = []
  }

  // sort conversations by date, most recent first
  if (conversations) {
    conversations.sort((a: any, b: any) => {
      return b.createdAt - a.createdAt
    })
  }

  return <>
    <h2>
      Customer Support
      - <a
          href={`https://secure.helpscout.net/search/?query=${ encodeURIComponent(customerName) }`}
          target="_blank"
          className="text-underline"
          style={{ fontSize: '0.8em' }}
          >by Name</a>
    </h2>
    { loading && <span>Loading Helpdesk Tickets...</span> }
    { error && <span className="bg-red-100 p-2">Error loading Helpdesk Tickets.</span> }
    <ul className="text-left" style={{ fontSize: '0.8em' }}>
      { conversations && conversations.map((conv, i) => {
          let rowColors = ""
          if (conv.status === 'closed') {
            rowColors = 'text-gray-400'
          } else if (conv.status === 'open' || conv.status === 'active') {
            rowColors = 'text-white bg-red-400'
          } else if (conv.status === 'pending') {
            rowColors = 'text-white bg-amber-500'
          }

          return <li
            key={ conv.id }
            className={ rowColors }
          >
            <span className="w-1/12 inline-block align-top">
              <TimeAgo
                date={ conv.createdAt }
                formatter={ dateFormatter }
              />
            </span>

            <span className="w-11/12 inline-block">
              <a
                href={ `https://secure.helpscout.net/conversation/${ conv.id }/${ conv.number }` }
                title={ conv.preview }
                target="_blank">
                  { conv.subject }
              </a>
            </span>
          </li>
        })
      }
    </ul>
  </>
}

export default Helpdesk
