/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FinalizeDialog_query = {
    readonly finalizeDialogState: {
        readonly isOpen: boolean | null;
        readonly orderId: string | null;
        readonly showWrongVideoMessage: boolean | null;
        readonly showLabelSection: boolean | null;
        readonly showVideoSection: boolean | null;
        readonly allowAutoPrint: boolean | null;
        readonly allowAutoBuy: boolean | null;
    } | null;
    readonly " $refType": "FinalizeDialog_query";
};
export type FinalizeDialog_query$data = FinalizeDialog_query;
export type FinalizeDialog_query$key = {
    readonly " $data"?: FinalizeDialog_query$data;
    readonly " $fragmentRefs": FragmentRefs<"FinalizeDialog_query">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FinalizeDialog_query",
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FinalizeDialogState",
          "kind": "LinkedField",
          "name": "finalizeDialogState",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isOpen",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orderId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showWrongVideoMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showLabelSection",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showVideoSection",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "allowAutoPrint",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "allowAutoBuy",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = '08338dff7d60d84c0d00c0f331f9dc7c';
export default node;
