import React from 'react'
import type { IEnvironment } from 'relay-runtime'

import { useAuth0 } from "@auth0/auth0-react"

export interface User {
  name?: string
  email?: string
}

interface Props {
  environment: IEnvironment
  user: User
}

export default function LogoutButton({ environment, user }: Props) {
  const { logout } = useAuth0()

  function doLogout() {
    logout({
      returnTo: window.location.origin
    })
  }

  return (
    <button className="App-nav-button" onClick={doLogout}>
      Logout
    </button>
  )
}
