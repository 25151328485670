import React from 'react';
import {useDroppable} from '@dnd-kit/core';

export function VideoDropTarget(props: any) {
  const {setNodeRef, isOver} = useDroppable({
    id: props.index,
  });

  let style: any = {}
  if (isOver) {
    style.height = '50px'
    style.visibility = 'visible'
    style.border = '3px dashed blue'
    style.padding = '1em'
    style.margin = '1em'
  } else {
    style.height = '10px'
    style.visibility = 'hidden'
  }

  return (
    <div ref={setNodeRef}>
      <div style={style}>
        Drop Here
      </div>
    </div>
  );
}
