import React from 'react'
import { graphql, createFragmentContainer } from 'react-relay'
import { commitLocalUpdate } from 'relay-runtime'
import { useHotkeys } from 'react-hotkeys-hook'
import type { IEnvironment } from 'relay-runtime'

import type { OrderDetails_order } from '../__generated__/OrderDetails_order.graphql' 

import { VideoSection } from './VideoSection'
import { LabelSection } from './LabelSection'

interface Query {
  finalizeDialogState: {
    isOpen: boolean
    orderId: string
    showWrongVideoMessage: boolean
    showLabelSection: boolean
    showVideoSection: boolean
    allowAutoPrint: boolean
    allowAutoBuy: boolean
  }
}

interface Props {
  environment: IEnvironment
  close: () => void
  query: Query
  orders: OrderDetails_order[]
}

/*
 * x when a loaded device is unplugged
 *    x store the video id with the device
 * x open the finalize dialog for that order
 * X show two videos, make them pick the right one
 * - print message
 *    - generate in canvas
 *    - digitize to jpg
 *    - send over socket
 *    - send to printer
 * - print label
 *    - send shipping info to daemon
 *    - order from shipment api
 *    - write fulfillment now?
 *    - send to printer
 *    - if autoprint is on, maybe lets start this process while the video is still being confirmed?
 * - click finalize button
 *    - also have a button for (Issue, Retry) and (Issue, Hold)
 *    - take auto photo
 *    - make fulfillment record in Shopify
 * - auto reserve more videos
 */

export function open(environment: IEnvironment, orderId: string, otherProps?: any){
  commitLocalUpdate(environment, store => {
    let record = store.getRoot().getOrCreateLinkedRecord('finalizeDialogState', 'FinalizeDialogState')
    record?.setValue(true, 'isOpen')
    record?.setValue(orderId, 'orderId')
    record?.setValue(false, 'showWrongVideoMessage')
    record?.setValue(false, 'showLabelSection')
    record?.setValue(true, 'showVideoSection')

    if (otherProps) {
      for (let [k, v] of Object.entries(otherProps)) {
        // @ts-ignore
        record?.setValue(v, k)
      }
    }
  })
}

export function close(environment: IEnvironment) {
  commitLocalUpdate(environment, store => {
    let record = store.getRoot().getOrCreateLinkedRecord('finalizeDialogState', 'FinalizeDialogState')
    record?.setValue(false, 'isOpen')
    record?.setValue(false, 'showWrongVideoMessage')
    record?.setValue(false, 'showLabelSection')
  })
}

export function isOpen(environment: IEnvironment, cb: (open: boolean) => void) {
  commitLocalUpdate(environment, store => {
    const record = store.getRoot().getOrCreateLinkedRecord('finalizeDialogState', 'FinalizeDialogState')
    const open = !!record?.getValue('isOpen')
    setTimeout(() => {
      cb(open)
    })
  })
}

export function FinalizeDialog({ environment, close, query, orders }: Props) {
  let { finalizeDialogState } = query

  useHotkeys('esc', close)

  if (!finalizeDialogState || orders === undefined){
    return <></>
  }

  let { isOpen, orderId, showWrongVideoMessage, showLabelSection, showVideoSection } = finalizeDialogState

  let renderContent = () => {
    if (showWrongVideoMessage) {
      return <output>Wrong video selected. We will load this card again in the next batch. Please return the card to the unused pile, and move onto the next card.</output>
    }

    if (!orderId) {
      return <output>No order selected.</output>
    }

    if (!query) {
      return <>Error</>
    }

    let order: any
    for (let i=orders.length; i--;){
      if (orders[i].id === orderId){
        order = orders[i]
        break
      }
    }

    if (!order) {
      return <output>Order not found.</output>
    }

    if (order.videoDownloadStatus !== "downloaded") {
      // We're just looking at the order, not fulfilling
      showVideoSection = false
      showLabelSection = true
    }

    let videoSection = <></>
    if (showVideoSection){
      videoSection = <VideoSection
        order={ order }
        orders={ orders }
        environment={ environment }
      />
    }

    let transferStatusBlock: JSX.Element = <></>
    if (order.videoTransferStatus !== 'transferred') {
      if (order.videoTransferStatus === 'transferring') {
        transferStatusBlock = <div className="App-transfer-status-message">Video has not finished transferring yet!</div>
      } else {
        transferStatusBlock = <div className="App-transfer-status-message">Video has not been transferred in this session, finalize order with caution.</div>
      }
    }

    let labelSection = <></>
    if (showLabelSection){
      labelSection = <LabelSection
        environment={ environment }
        order={ order }
        onFinalized={ close }
        allowAutoPrint={ finalizeDialogState.allowAutoPrint || false }
        allowAutoBuy={ finalizeDialogState.allowAutoBuy || false }
      />
    }

    return <>
      { transferStatusBlock }

      { videoSection }

      { labelSection }
    </>
  }

  return <dialog open={isOpen} className="App-finalize-order-dialog">

    <header>
     <button className="App-dialog-close-button" onClick={ close }>X</button>
    </header>


    <div className="App-finalize-order-dialog-content">
      { renderContent() }
    </div>

  </dialog>
}

export default createFragmentContainer(
  FinalizeDialog,
  {
    query: graphql`
      fragment FinalizeDialog_query on Query
      {
        finalizeDialogState {
          isOpen
          orderId
          showWrongVideoMessage
          showLabelSection
          showVideoSection
          allowAutoPrint
          allowAutoBuy
        }
      }
    `
  }
)
