import React, { useState, useEffect, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useHotkeys } from 'react-hotkeys-hook'
import { DEBUG } from '../Debug'

import { RateQuoteDocument } from '../graphql/__generated__'
import type { RateQuoteQuery } from '../graphql/__generated__'

import { purchaseAndPrintLabel } from './OrderShipment'
import { options as fulfillmentOptions } from '../components/FulfillmentOptions'

import type { IEnvironment } from 'relay-runtime'
import type { ChangeEvent } from 'react'

const { autoPrintLabel, saturdayDelivery, fromWarehouse } = fulfillmentOptions

// Temp clear out the fulfillment machine from afar:
delete localStorage.DEBUG_express_days

// How long can transit take for someone who bought Express shipping
const EXPRESS_DAYS = +DEBUG('express_days') || 2

// TODO Load query as a part of the pipeline

type RateQuoteOpts = {
  environment: IEnvironment
  orderID: string
  defaultQuantity: number
  defaultDomesticService: string
  defaultInternationalService: string
  isReturn: boolean
  autoBuyLabel: boolean
}

function getTotalRate(quote: any): number {
  return quote.shipping_amount.amount + quote.other_amount.amount + quote.confirmation_amount.amount + quote.insurance_amount.amount
}

function chooseDefault(rates: RateQuoteQuery["shippingRates"], defaultDomestic: string, defaultInternational: string): RateQuoteQuery["shippingRates"][0]|undefined {
  // Some addresses (like PO Boxes) can't be delivered to by UPS, let's make sure to choose another suitable
  // option, even when we would normally prefer UPS for an express shipment.
  let hasUPS = false

  // For us, Express shipping means we will use the best rate we can which uses
  // UPS as the carrier and guarentees delivery in 2 days. Based on distance that can be
  // 2 day, or could be even Ground.
  // @ts-ignore
  rates = new Array(...rates)
  rates.sort((a: any, b: any) => {
    let aVal = getTotalRate(a)
    let bVal = getTotalRate(b)
    if (defaultDomestic === "best-express"){
      aVal = Math.max(a.delivery_days || 7, EXPRESS_DAYS) + aVal / 10000
      bVal = Math.max(b.delivery_days || 7, EXPRESS_DAYS) + bVal / 10000
    }

    if (a.carrier_friendly_name === "UPS") {
      hasUPS = true
    }

    return bVal - aVal
  })

  for (let i=rates.length; i--;){
    const rate = rates[i]

    if (defaultDomestic === "best-express" && (!hasUPS || rate.carrier_friendly_name === "UPS")){
      return rate
    }

    if (defaultDomestic === "best-economy") {
      if (rate.service_code === "usps_first_class_mail" || rate.service_code === "ups_ground" || rate.service_code === "usps_priority_mail") {
        // For orders of qty > 1, USPS isn't available. If it is it should always sort first.
        // We don't ship much by Priority Mail, as it tends to be more expensive, but for certain destinations (like HI) it's much cheaper.
        return rate
      }
    }

    if (rate.service_code === defaultDomestic || rate.service_code === defaultInternational) {
      return rate
    }
  }

  return undefined
}

export default function RateQuote({
  orderID,
  environment,
  defaultQuantity,
  defaultDomesticService,
  defaultInternationalService,
  isReturn,
  autoBuyLabel
}: RateQuoteOpts) {
  const [selectedRate, setSelectedRate] = useState<any>(null)
  useEffect(() => {
    setSelectedRate(null)
  }, [orderID])

  const [quantity, setQuantity] = useState<number>(defaultQuantity || 1)
  const qtyRef = useRef<HTMLSelectElement>(null)
  const [loadRates, { loading, data, error }] = useLazyQuery(RateQuoteDocument, {
    variables: {
      orderID: orderID,
      // @ts-ignore Mapping the string to the enum
      fromOrToWarehouse: fromWarehouse(),
      saturdayDelivery: saturdayDelivery(),
      isReturn,
      quantity,
    },
  })

  const [currentlyPurchasing, setCurrentlyPurchasing] = useState(false)

  useEffect(() => {
    // We reuse the UI, so when the default changes we have to update the selection.
    setQuantity(defaultQuantity)
  }, [defaultQuantity])

  useEffect(loadRates, [orderID, defaultQuantity, quantity, loadRates])

  useHotkeys('p', () => {
    if (fulfillmentOptions.keyboardShortcuts()) {
      buy()
    }
  })

  useEffect(() => {
    if (autoBuyLabel && data && data.shippingRates) {
      const rate = chooseDefault(Array.from(data.shippingRates), defaultDomesticService, defaultInternationalService)
      if (rate){
        purchaseAndPrintLabel(environment, orderID, rate.rate_id, false)
      }
    }
  }, [data, defaultDomesticService, defaultInternationalService, environment, orderID, autoBuyLabel])

  const rates: RateQuoteQuery["shippingRates"] = Array.from(data?.shippingRates || [])
  const handleRateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    for (let i=rates.length; i--;){
      if (rates[i].rate_id === e.target.value) {
        setSelectedRate(rates[i])
        break
      }
    }
  }

  const handleQtyChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setQuantity(+e.target.value)
  }

  const defaultRate = chooseDefault(rates, defaultDomesticService, defaultInternationalService)

  useEffect(() => {
    if (selectedRate) {
      return
    }
    setSelectedRate(defaultRate ?? rates[0])
  }, [selectedRate, defaultRate, rates])

  if (loading) {
    return <div>Loading Shipping Rates...</div>
  }

  Array.prototype.sort.call(rates, (a: any, b: any) => {
    // This is because FedEx right now means UncommonGoods, and we want those rates seperated.
    let aVal = getTotalRate(a)
    if (a.carrier_friendly_name === "FedEx")
      aVal *= 1000

    let bVal = getTotalRate(b)
    if (b.carrier_friendly_name === "FedEx")
      bVal *= 1000

    return aVal - bVal
  })

  let entries = rates.map((rate: any) => {
    let deliveryEstimate = ''
    if (rate.delivery_days > 0) {
      deliveryEstimate = `(${ rate.delivery_days } day${ rate.delivery_days !== 1 ? 's' : '' })`
    }

    let serviceLabel = rate.service_type
    if (rate.saturday_delivery) {
      serviceLabel = `[SATURDAY] ${ serviceLabel }`
    }

    if (!!rate.package_type && rate.package_type !== "package") {
      serviceLabel = `${ serviceLabel } [${ rate.package_type.toUpperCase() }]`
    }

    return <option key={ rate.rate_id } value={ rate.rate_id }>
      { serviceLabel } { deliveryEstimate } - ${ getTotalRate(rate).toFixed(2) }
    </option>
  })

  let renderedRate = selectedRate || defaultRate

  function buy(){
    if (renderedRate) {
      setCurrentlyPurchasing(true)
      purchaseAndPrintLabel(environment, orderID, renderedRate.rate_id, isReturn).then(() => {
        setCurrentlyPurchasing(false)
      }, () => {
        setCurrentlyPurchasing(false)
      })
    }
  }

  return <div className="App-rate-selection">
    <label>
      Quantity:
      <select ref={qtyRef} defaultValue={quantity} onChange={handleQtyChange} className="mb-2" style={{width: "5em"}}>
        {Array.from({length: 44}, (e, i) => {
          return <option key={i + 1}>{i + 1}</option>
        })}
      </select>
    </label>

    { error &&
      <p className="bg-red-100 p-2 m-2">{ error.message }</p>
    }

    { !error && <>
      <select defaultValue={ defaultRate && defaultRate.rate_id } onChange={handleRateChange}>
        { entries }
      </select>

      <button
        hidden={ !renderedRate }
        disabled={ currentlyPurchasing }
        onClick={ () => { buy() } }>
          { currentlyPurchasing ?
            "Purchasing..." : <>
            <strong className="keyboard-shortcut-indicator">P</strong>urchase { autoPrintLabel() ? "& Print" : "" } for ${ renderedRate && getTotalRate(renderedRate).toFixed(2) }
          </> }
      </button>
    </> }
  </div>
}
