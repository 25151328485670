import { send } from './Socket'
import { options as fulfillmentOptions } from '../components/FulfillmentOptions'

function blobToDataURL(blob: Blob): Promise<string> {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result as string)
    }
  })
}

function printerFromSize(size: string): string {
  let printer: string
  if (size === "4x4") {
    printer = fulfillmentOptions.squarePrinter()
  } else if (size === "2x1") {
    printer = fulfillmentOptions.tagPrinter()
  } else {
    printer = fulfillmentOptions.rectPrinter()
  }

  if (!printer){
    throw new Error("No printer selected")
  }

  return printer
}

export function printPNGFromBlob(size: string, data: Blob){
  blobToDataURL(data)
    .then((url) => {
      printPNGFromURL(size, url)
    })
}

export function printPNGFromURL(size: string, url: string){
  const printer = printerFromSize(size)

  send(JSON.stringify({
    kind: 'print:start',
    url,
    printer,
    file_type: 'image/png',
  }))
}

export function printPDFFromURL(size: string, url: string){
  const printer = printerFromSize(size)

  send(JSON.stringify({
    kind: 'print:start',
    url,
    printer,
    file_type: 'application/pdf',
  }))
}
