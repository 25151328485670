import React from 'react'

import type { IOrder } from './graphql'

interface Props {
  order: IOrder | null
}

export default function LinotypeOrderButtons({ order }: Props) {
  if (!order?.id || !order?.customer?.email) {
    return <></>
  }

  const [orderId, designId, destinationId] = order.id.split(':')
  if (!designId || !destinationId) {
    // This isn't really a Linotype order
    return <></>
  }

  const makeLink = (next: String) => {
    return `https://make.sendheirloom.com/sudo/${ order.customer.email }?next=${ encodeURIComponent(next) }`
  }

  return <div className="group">
    <a
      className="text-[1.1rem]"
      href={ makeLink(`/order/${ orderId }?edit=true`) }
      target="_blank"
      title="Edit Order"
      >🛠️</a>
    <div className="hidden group-hover:block absolute bg-gray-100 p-2 space-x-2 z-50">
      <a
        className="text-[1.1rem]"
        href={ makeLink(`/order/${ orderId }/design/${ designId }/destination/${ destinationId }/message`) }
        target="_blank"
        title="Edit Message"
        >💬</a>
      <a
        className="text-[1.1rem]"
        href={ makeLink(`/order/${ orderId }/design/${ designId }/destination/${ destinationId }/address`) }
        target="_blank"
        title="Edit Address"
        >📬</a>
      <a
        className="text-[1.1rem]"
        href={ makeLink(`/order/${ orderId }/design/${ designId }`) }
        target="_blank"
        title="Edit Video"
        >📹</a>
      <a
        className="text-[1.1rem]"
        href={ makeLink(`/order/${ orderId }/design/${ designId }/destination/${ destinationId }/cover`) }
        target="_blank"
        title="Edit Cover"
        >🖼️</a>
    </div>
  </div>
}
