import React from 'react'
import type { RouteComponentProps } from '@reach/router'
import { useQuery, useMutation } from '@apollo/client'
import type { ApolloError } from '@apollo/client'
import { Loading, VideoListing, ArchiveOrderButton, ScheduledDeliveryPicker, ReencodeButton, AudioGain, OrientationInput } from './components'
import { SaveVideoManifestDocument, OrderInfoDocument } from './graphql/__generated__'

interface OrderInfoProps extends RouteComponentProps {
  orderId?: string
}

const OrderInfo = ({orderId}: OrderInfoProps) => {
  const { loading, data, error } = useQuery(OrderInfoDocument, {
    variables: {
      orderID: orderId
    },
  })
  const [saveManifest, saveManifestResp] = useMutation(SaveVideoManifestDocument, {
    onError: (error: ApolloError) => {
      console.error("Error saving manifest changes", error)
    }
  })


  const saveManifestChanges = (newURLs: string[], oldURLs: string[], onDone: (error: string | undefined) => void) => {
    saveManifest({
      variables: {
        orderID: orderId,
        prevVideoURLs: oldURLs,
        newVideoURLs: newURLs,
      },
      update: (cache) => {
        if (data?.order) {
          cache.modify({
            id: cache.identify(data.order),
            fields: {
              cardVideoURL: () => undefined,
              shareVideoURL: () => undefined,
            }
          })
        }
      },
      onCompleted: () => onDone(undefined),
      onError: (err: ApolloError) => onDone(err.toString()),
    })
  }

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    return <div>Error Loading Order Details.</div>
  }

  const { order } = data
  const isNewOrder = order.id.includes(':')

  return (
    <div className="pl-8 md:flex flex-wrap">
      <h1 className="w-full text-lg mt-6">{ order.customerName }’s Order</h1>
      <div className="md:w-6/12 w-full">
        <h2>Video Segments</h2>
        <VideoListing order={ order } onSave={ saveManifestChanges } noEdit={ isNewOrder } />
      </div>
      <div className="md:w-6/12 w-full">
        <h2>Order Actions</h2>
        <ArchiveOrderButton order={ order } />
        <ReencodeButton order={ order } />
        { !isNewOrder && <>
          <h3 className="mt-4">Scheduled Delivery</h3>
          <ScheduledDeliveryPicker order={ order } />
          <h3 className="mt-4">Audio Gain</h3>
          <AudioGain order={ order } />
          <h3 className="mt-4">Video Orientation</h3>
          <OrientationInput order={ order } />
        </> }
      </div>
    </div>
  )
}

export default OrderInfo
