import React from 'react'
import { graphql, createFragmentContainer } from 'react-relay'
import type { IEnvironment } from 'relay-runtime'

import OrderList from './OrderList'
import type { MyOrderList_query } from './__generated__/MyOrderList_query.graphql'

interface Props {
  environment: IEnvironment
  query: MyOrderList_query | null
}

function MyOrderList({ query, environment }: Props) {
  if(!query) {
    return <>Error</>
  }
  const { myOrders } = query

  // @ts-ignore
  return OrderList({ orders: myOrders, environment, query })
}

// It's massively frustrating that we have to repeat the full OrderDetails query here
// This will get better when Relay is gone
export default createFragmentContainer(
  MyOrderList,
  {
    query: graphql`
      fragment MyOrderList_query on Query @argumentDefinitions(
        activeFulfillers: {type: "Int", defaultValue: 0},
        fulfillerIndex: {type: "Int", defaultValue: 0},
      ) {
        ...FinalizeDialog_query
        myOrders(activeFulfillers: $activeFulfillers, fulfillerIndex: $fulfillerIndex) {
          ...OrderDetails_order
          id
          name
          status
          reservedBy
          reservedAt
          paidAt
          cardVideoURL
          cardVideoSHA256
          cardVideoEncodeProgress
          shareVideoEncodeProgress
          shareVideoURL
          customerName
          scheduledDeliveryDate
          loyaltyCode

          notes {
            id
            createdAt
            content
            createdBy {
              name
              email
            }
          }

          balanceUsages {
            id
            vendorSaleVendorId
            vendorSaleId
          }

          vendorSale {
            id
            vendorId
          }

          customer {
            id
            email
            flagOrders {
              createdByName
              createdByEmail
              createdAt
              description
              emoji
            }
          }

          shopify {
            email
            createdAt
            note
            giftMessage
            discountCodes
            shippingAddress {
              address1
              address2
              city
              company
              countryCode
              name
              phone
              province
              provinceCode
              zip
            }
            shipping {
              carrierIdentifier
              code
            }
            lineItems {
              quantity
              SKU
              product {
                title
                images {
                  src
                }
              }
              destination {
                id
                coversPrinted
                cover {
                  approvedAt
                }
                backCover {
                  approvedAt
                }
              }
            }
          }

          shippingLabels {
            label_id
            is_return
            created_at
            status
            voided
            shipment_id
            tracking_number
            carrier_code
            service_code
            label_download {
              pdf
              png
            }
            form_download {
              href
            }
          }

          cardVideoEncodedConfig {
            vertical
            allowVertical
          }

          videoDownloadStatus
          videoPercentageDownloaded

          videoTransferStatus
          videoPercentageTransferred
        }
      }
    `
  }
)
