import { commitMutation, graphql } from 'react-relay'
import type { IEnvironment, RecordSourceSelectorProxy, SelectorData } from 'relay-runtime'

const orderShipmentMutation = graphql`
  mutation OrderShipmentMutation($orderID: ID!, $rateID: ID!, $isReturn: Boolean) {
    orderShipmentFromRate(orderID: $orderID, rateID: $rateID, isReturn: $isReturn) {
      label_id
      is_return
      status
      voided
      shipment_id
      tracking_number
      label_download {
        pdf
        png
      }
      form_download {
        href
      }
    }
  }
`

export function purchaseAndPrintLabel(environment: IEnvironment, orderID: string, rateID: string, isReturn: boolean) {
  // return promise
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation: orderShipmentMutation,
      variables: {
        orderID,
        rateID,
        isReturn,
      },
      onError: (err) => {
        console.error("Error buying label", err)
        reject(err)
      },
      onCompleted: () => {
        resolve()
      },
      updater: (store: RecordSourceSelectorProxy, data: SelectorData) => {
        const label = store.getRootField('orderShipmentFromRate')
        if (!label) {
          return
        }

        let order = store.get(orderID)
        if (!order) {
          return
        }

        let labels = order.getLinkedRecords('shippingLabels') || []
        order.setLinkedRecords([label, ...labels], 'shippingLabels')
      },
    })
  })
}
