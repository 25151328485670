import React, { useState, useMemo } from 'react'
import Uploady from "@rpldy/uploady";
import UploadDropZone from "@rpldy/upload-drop-zone"
import getTusEnhancer from "@rpldy/tus-sender";

interface VideoUploaderProps {
  onUploaded: (url: string) => void
}

const VideoUploader = ({ onUploaded }: VideoUploaderProps) => {
  const [activeFiles, setActiveFiles] = useState({})
  const [error, setError] = useState<string | undefined>(undefined)

  const tusEnhancer = useMemo(() => {
    return getTusEnhancer({
      chunked: true,
      parallel: 4,
      retries: 1,
      resume: false,
      forgetOnSuccess: true,

      // Larger is a bit better, as it has to include all paths in the final call,
      // and too large of a header tends to cause issues.
      chunkSize: 1024 * 1024 * 15,
    })
  }, [])

  function onFinish(file: any){
    const url = file.uploadResponse.location

    console.log('Upload finished', file.id)

    const filename = activeFiles[file.id].filename

    // It's a bit of a wonky process to get the filename from the upload response, as
    // tus doesn't make it easy to return the actual destination location.
    const filePath = `${ url.split('+')[0].split('/').pop() }/${ filename }`;

    onUploaded(`https://heirloom-customer-videos-web.s3.amazonaws.com/${ filePath }`)

    setActiveFiles((activeFiles) => {
      delete activeFiles[file.id]

      // Clone obj so React knows to re-render
      return {...activeFiles}
    })
  }

  function addURL(e: Event) {
    e.preventDefault()

    // Add a file using a public URL, the server will automatically download the file and
    // move it to S3.
    onUploaded(e.target.querySelector('input')!.value)
    e.target.querySelector('input')!.value = ''
  }

  function onError(err: any){
    console.error("Error uploading", err)
    setError(err.uploadResponse)
  }

  function onProgress(file: any){
    setActiveFiles((activeFiles) => {
      activeFiles[file.id].progress = file.completed|0

      return {...activeFiles}
    })
  }

  function preSend(args: any): any {
    setError(undefined)

    // With Group disabled, this actually gets called for each file
    const item = args.items[0]

    const extension = item.file.name.split('.').pop()
    const newFilename = `${ Math.floor(Math.random()*10000000000000) }.${ extension }`


    setActiveFiles((activeFiles) => {
      activeFiles[item.id] = {
        filename: newFilename,
      }

      return {...activeFiles}
    })

    console.log('New file', item.id, newFilename)

    const out = {
      options: {
        params: {
          filename: newFilename,
          filetype: item.file.type,
          extension: extension,
        }
      }
    }

    return out
  }

  function onStart(item: any) {
    console.log('Upload starting', item)
  }

  const progress = <ul className="mt-1">
    { Object.keys(activeFiles).map(id => {
      const file = activeFiles[id]
      return <li key={ id }>
        <span>{ file.filename }</span>
        <span className="inline-block ml-2">{ file.progress||0 }%</span>
      </li>
    }) }
  </ul>

  return <>
    <Uploady destination={{url: 'https://upload.sendheirloom.com/files/'}}
             enhancer={ tusEnhancer }
             multiple={ true }
             grouped={ false }
             concurrent={ true }
             maxConcurrent={ 4 }
             listeners={{
                'FILE-PROGRESS': onProgress,
                'FILE-FINISH': onFinish,
                'FILE-ERROR': onError,
                'FILE-START': onStart,
                'REQUEST_PRE_SEND': preSend,
             }}>
      <UploadDropZone onDragOverClassName="bg-gray-200"
                      className="block w-full h-48 bg-gray-100 flex"
                      >
        <div className="block m-auto p-1">
          <span>Drop File(s) Here</span>
          <form onSubmit={ addURL } >
            <input type="text" className="mt-2" style={{fontSize: '0.6em', height: '1em', width: '28em'}} placeholder="Paste public URL here..." />
          </form>

          { error && <output className="m-2 p-2 block bg-red-50">Error: { error }</output> }
          { !error && progress }
        </div>
      </UploadDropZone>
    </Uploady>
  </>
}

export default VideoUploader
