import React from 'react'
import { useMutation } from '@apollo/client'
import { SetOrderStatusDocument } from '../graphql/__generated__'
import type { IOrder } from '../graphql'

interface ArchiveOrderButtonProps {
  order: IOrder
}

const ArchiveOrderButton = ({ order }: ArchiveOrderButtonProps) => {
  const [setOrderStatus, { loading, error }] = useMutation(SetOrderStatusDocument)

  if (!order) {
    return <>No order.</>
  }

  let button: JSX.Element
  if (order.status !== 'archived') {
    button = <button
      className="btn btn-danger"
      onClick={ () => setOrderStatus({variables: {orderID: order.id, status: "archived"}}) }
      >
        Archive Order
      </button>
  } else {
    button = <button
      className="btn btn-primary"
      onClick={ () => setOrderStatus({variables: {orderID: order.id, status: "paid"}}) }>
        Unarchive Order
      </button>
  }

  return <>
    { button }

    { loading && <div className="p-2">Saving...</div> }
    { error && <div className="p-2">There was an error.</div> }
  </>
}

export default ArchiveOrderButton
