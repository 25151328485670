import React from 'react'

export type Aspect = 'auto' | 'rect' | 'square'

interface Props {
  aspect: Aspect
  setAspect: (aspect: Aspect) => void
}

export default function AspectButtons({ aspect, setAspect }: Props) {
  return (
    <div className="aspect-buttons">
      <button
        className={aspect === 'auto' ? 'active' : ''}
        onClick={() => setAspect('auto')}
      >
        Auto
      </button>
      <button
        className={aspect === 'rect' ? 'active' : ''}
        onClick={() => setAspect('rect')}
      >
        Rect
      </button>
      <button
        className={aspect === 'square' ? 'active' : ''}
        onClick={() => setAspect('square')}
      >
        Square
      </button>
    </div>
  )
}

