/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type VoidLabelMutationVariables = {
    orderID: string;
    labelID: string;
};
export type VoidLabelMutationResponse = {
    readonly voidShippingLabel: {
        readonly label_id: string;
        readonly status: string;
        readonly voided: boolean;
        readonly shipment_id: string;
        readonly tracking_number: string;
        readonly label_download: {
            readonly pdf: string | null;
            readonly png: string | null;
        } | null;
    };
};
export type VoidLabelMutation = {
    readonly response: VoidLabelMutationResponse;
    readonly variables: VoidLabelMutationVariables;
};



/*
mutation VoidLabelMutation(
  $orderID: ID!
  $labelID: ID!
) {
  voidShippingLabel(orderID: $orderID, labelID: $labelID) {
    label_id
    status
    voided
    shipment_id
    tracking_number
    label_download {
      pdf
      png
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "labelID"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderID"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "labelID",
        "variableName": "labelID"
      },
      {
        "kind": "Variable",
        "name": "orderID",
        "variableName": "orderID"
      }
    ],
    "concreteType": "ShippingLabel",
    "kind": "LinkedField",
    "name": "voidShippingLabel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "voided",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shipment_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tracking_number",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LabelDownload",
        "kind": "LinkedField",
        "name": "label_download",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pdf",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "png",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VoidLabelMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "VoidLabelMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8a691aff7e16740727ca06073368541d",
    "id": null,
    "metadata": {},
    "name": "VoidLabelMutation",
    "operationKind": "mutation",
    "text": "mutation VoidLabelMutation(\n  $orderID: ID!\n  $labelID: ID!\n) {\n  voidShippingLabel(orderID: $orderID, labelID: $labelID) {\n    label_id\n    status\n    voided\n    shipment_id\n    tracking_number\n    label_download {\n      pdf\n      png\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a27dfe730bed4ff2f29d3bf05e16bcbc';
export default node;
