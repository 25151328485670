import React from 'react'
import type { RouteComponentProps } from '@reach/router'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import type { ApolloError } from '@apollo/client'

import { UpdateCustomerDocument } from './graphql/__generated__'
import type { IOrder } from './graphql'

interface FlagsProps extends RouteComponentProps {}

const EMOJI = {
  flag: '🚩',
  new: '🆕',
  elm: '🌳',
  rush: '💨',
  press: '📰',
  etsy: '🛍',
  qvc: '📺',
}

const Flags = (_: FlagsProps) => {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const [updateCustomer, { loading, data, error }] = useMutation(UpdateCustomerDocument, {
    onError: (error: ApolloError) => {
      console.error("Error updating flags", error)
    }
  })

  const onSubmit = async (data: any) => {
    let emoji = []
    Object.entries(EMOJI).forEach(([key, value]) => {
      if (data[`emoji-${ key }`]) {
        emoji.push(value)
      }
    })

    let flag: IOrder["customer"]["flagOrders"] | null = null
    if (emoji.length > 0) {
      flag = {
        emoji,
      }
    }

    const emails = data.emails.split('\n').map((email: string) => email.trim()).filter((email: string) => email.length > 0)

    for (let email of emails) {
      await updateCustomer({
        variables: {
          customerEmail: email,
          fields: ['flagOrders'],
          prevValues: {}, /* This is not actually implemented on the server */
          newValues: {
            flagOrders: flag,
          }
        }
      })
    }
  }

  return <div className="m-4 flex justify-center">
    <form className="space-y-4 w-120" onSubmit={ handleSubmit(onSubmit) } >
      <h1 className="text-md font-bold">Add Flag to Customers</h1>

      <div className="grid grid-cols-4 w-full gap-1">
        <label htmlFor="emails" className="block">Emails</label>
          
        <textarea {...register("emails")} id="emails" name="emails" className="col-span-3 w-full text-xs" />
        <div />
        <div className="col-span-3 text-xs text-gray-500">One email per line</div>
      </div>
      <div className="grid grid-cols-4 w-full gap-1">
        <label htmlFor="emoji" className="block">Emoji</label>

        <div className="mt-2 col-span-3">
          { Object.entries(EMOJI).map(([eName, emoji]) => (
            <span key={ eName } className="ml-4 mt-4 inline-block">
              <input {...register('emoji-' + eName)} type="checkbox" id={ `emoji-${ eName }` } name={ `emoji-${ eName }` } className="invisible absolute peer" />
              <label
                htmlFor={ `emoji-${ eName }` }
                title={ eName }
                className="inline-block bg-gray-50 peer-checked:bg-gray-200 peer-checked:shadow-inner
                           cursor-pointer shadow relative w-16 h-16 text-xl">
                <span className="absolute top-1 left-[1.3rem]">{ emoji }</span>
                <span className="absolute bottom-1 left-0 right-0 text-xs text-center">{ eName.substring(0, 1).toUpperCase() }{ eName.substring(1) }</span>
              </label>
            </span>
          )) }
        </div>
      </div>
      <div className="space-y-4">
        <input type="submit" value="Submit" className="ml-4 p-4 bg-gray-50 shadow cursor-pointer" />

        { loading && <div>Saving...</div> }
        { error && <div className="p-2 bg-red-100">Error: { error.message }</div> }
        { data && <div className="p-2 bg-green-100">Saved!</div> }
      </div>
    </form>
  </div>
}

export default Flags
