import { registerMessageKind } from './Socket'

registerMessageKind('connect:daemon', handleChange)
registerMessageKind('devices:change', handleChange)

export interface Device {
  id: string
  kind: string
  status: string
  mount_point: string

  // The casing is unfortunate, everything on the client is camelcase, but the daemon
  // messages are all snakecase.
  allowBlank: boolean
}

export interface DevicesChange {
  devices: Device[]
}

let devices: Device[] = []
let watchers: (((devices: Device[]) => void)|null)[] = []

function handleChange(message: DevicesChange) {
  devices = message.devices

  for (let i = 0; i < devices.length; i++) {
    if (devices[i].kind === 'card') {
      // We have a bug where devices with the name "ActDisk" don't correctly ignore
      // the various dotfiles which get loaded onto them when they are manually loaded by
      // customers. To handle this, we only allow our 'blank' instruction video to be
      // installed onto devices which have the correct software (and are named "Untitled").
      // ActDisk are the ones which are broken.
      devices[i].allowBlank = (devices[i].mount_point?.indexOf("/Volumes/ActDisk") === -1)
    }
  }

  callWatchers()
}

export function watchDevices(onChange: (devices: Device[]) => void): number {
  watchers.push(onChange)
  return (watchers.length - 1)
}

// Get called now and forever
export function marryDevices(onChange: (devices: Device[]) => void): number {
  onChange(devices)
  return watchDevices(onChange)
}

export function divorceDevices(index: number) {
  watchers[index] = null
}

function callWatchers() {
  for (let i = 0; i < watchers.length; i++) {
    if (watchers[i] !== null){
      // @ts-ignore It doesn't get the check right above
      watchers[i](devices)
    }
  }
}
