import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

interface Props {
  trackingNumber: string | null | undefined
}

export default function TrackingButton({ trackingNumber }: Props) {
  if (!trackingNumber) {
    return <></>
  }

  return (
    <CopyToClipboard text={trackingNumber}>
      <button className="App-copy-button" style={{ marginTop: "-3px", fontSize: "2em", lineHeight: "1.3em" }} title="Copy tracking number to clipboard">
        🚂
      </button>
    </CopyToClipboard>
  )
}
