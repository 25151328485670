/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type OrderActiveToggleMutationVariables = {
    id: string;
    active: boolean;
};
export type OrderActiveToggleMutationResponse = {
    readonly setOrderActive: {
        readonly order: {
            readonly id: string;
            readonly status: string;
        };
    };
};
export type OrderActiveToggleMutation = {
    readonly response: OrderActiveToggleMutationResponse;
    readonly variables: OrderActiveToggleMutationVariables;
};



/*
mutation OrderActiveToggleMutation(
  $id: ID!
  $active: Boolean!
) {
  setOrderActive(id: $id, active: $active) {
    order {
      id
      status
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "active"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "active",
        "variableName": "active"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "OrderResponse",
    "kind": "LinkedField",
    "name": "setOrderActive",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Order",
        "kind": "LinkedField",
        "name": "order",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderActiveToggleMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrderActiveToggleMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8e41101324f89b063ce28a5b1cb3806d",
    "id": null,
    "metadata": {},
    "name": "OrderActiveToggleMutation",
    "operationKind": "mutation",
    "text": "mutation OrderActiveToggleMutation(\n  $id: ID!\n  $active: Boolean!\n) {\n  setOrderActive(id: $id, active: $active) {\n    order {\n      id\n      status\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'db96560a924fc5e04a14847415f7de00';
export default node;
