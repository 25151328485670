import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { useLazyQuery } from '@apollo/client'
import { SearchOverlayDocument } from '../graphql/__generated__'
import { debounce } from "debounce"
import type { OrderDetailsFragment } from '../graphql/__generated__'
import { Loading, OrderSelection } from '.'

interface SearchOverlayProps {
  onSelected: (order: OrderDetailsFragment) => void
  onCancelled: () => void
}

export default function SearchOverlay({ onSelected, onCancelled }: SearchOverlayProps) {
  const [query, setQuery] = useState<string | undefined>(undefined)

  const [runQuery, { loading, data, error }] = useLazyQuery(SearchOverlayDocument, {
    variables: {
      query,
    },
  })

  let output: JSX.Element = <></>

  if (loading && !data) {
    output = <Loading />
  }

  if (error) {
    output = <div>Error Searching Orders.</div>
  }

  const onSelectedId = (orderId: string) => {
    data!.searchOrders!.forEach ((order) => {
      if (order.id === orderId) {
        onSelected(order)
      }
    })
  }

  if (query && data) {
    if (data.searchOrders.length)
      output = <OrderSelection orders={data.searchOrders} onSelected={onSelectedId} />
    else
      output = <div className="p-6">No results.</div>
  } else if (query && !loading && !error) {
    runQuery()
  }

  const handleSearch = debounce(() => {
    // @ts-ignore
    const newQuery = document.querySelector('#search-overlay-query')!.value
    setQuery(newQuery)
  }, 300)

  // @ts-ignore
  function stopPropagation(e){
    e.stopPropagation()
  }

  // @ts-ignore
  function onKeyUp(e){
    if (e.keyCode === 27) {
      // Escape
      onCancelled()
    }
  }

  let el = <div
    className="fixed w-screen h-screen flex bg-opacity-10	bg-black z-50"
    onClick={ onCancelled }
    onKeyUp={ onKeyUp }
  >
    <div
      className="w-11/12 md:w-6/12 mx-auto mt-2"
      onClick={ stopPropagation }
    >
      <input
        className="text-5xl p-8 w-full"
        id="search-overlay-query"
        onChange={ handleSearch }
        placeholder="Search Orders"
        autoFocus={true}
      />
      <output className="block w-full bg-white">
        { output }
      </output>
    </div>
  </div>

  return ReactDOM.createPortal(el, document.getElementById('root')!)
}
