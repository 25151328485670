import React, { useState, useEffect, useRef } from 'react'
import { commitLocalUpdate } from 'relay-runtime'
import type { IEnvironment } from 'relay-runtime'
import { useHotkeys } from 'react-hotkeys-hook'

import type { OrderDetails_order } from '../__generated__/OrderDetails_order.graphql' 
import type { HotkeysEvent } from 'hotkeys-js'

import { getDownloadedFileURL } from '../Daemon/Downloader'

function buildVideoURL(order: OrderDetails_order): string {
  return getDownloadedFileURL(order!.cardVideoSHA256!)
}

interface Props {
  order: OrderDetails_order
  orders: OrderDetails_order[]
  environment: IEnvironment
}

export function VideoSection({ order, orders, environment }: Props) {
  let [correctFirst] = useState<boolean>(Math.random() < 0.5)
  let [loadingRandomOrder, setLoadingRandomOrder] = useState<boolean>(true)

  useHotkeys('left,right', (event: KeyboardEvent, handler: HotkeysEvent) => {
    if ((handler.key === 'left' && correctFirst) ||
      (handler.key === 'right' && !correctFirst)){
      chooseRightVideo()
    } else {
      chooseWrongVideo()
    }
  })

  useHotkeys('s', (event: KeyboardEvent, handler: HotkeysEvent) => {
    chooseRightVideo()
  })

  let chooseWrongVideo = () => {
    commitLocalUpdate(environment, store => {
      let record = store.getRoot().getLinkedRecord('finalizeDialogState')
      record?.setValue(true, 'showWrongVideoMessage')

      let orders = store.getRoot().getLinkedRecords('orders')
      if (!orders) {
        console.error("Orders not found to choose video")
        return
      }
      for (let i=0; i < orders!.length; i++){
        if (orders![i]!.getValue('id') === order.id){
          orders![i]!.setValue('', 'videoTransferStatus')
        }
      }
    })
  }

  let chooseRightVideo = () => {
    commitLocalUpdate(environment, store => {
      let record = store.getRoot().getLinkedRecord('finalizeDialogState')
      record?.setValue(false, 'showWrongVideoMessage')
      record?.setValue(true, 'showLabelSection')
      record?.setValue(false, 'showVideoSection')
    })
  }

  let randomOrder = useRef<OrderDetails_order|null>(null)
  useEffect(() => {
    let downloadedOrders: OrderDetails_order[] = []
    for (let i=orders.length; i--;){
      if (orders[i].id !== order.id && orders[i].videoDownloadStatus === "downloaded")
        downloadedOrders.push(orders[i])
    }
    if (downloadedOrders.length > 1) {
      let randomOrderIndex = Math.floor(Math.random() * (downloadedOrders.length - 1))
      randomOrder!.current = downloadedOrders[randomOrderIndex]
    } else {
      randomOrder!.current = null
    }

    setLoadingRandomOrder(false)
  // We are intentionally not including orders as a dep, as we don't want this to change even if the orders list changes
  /* eslint-disable */
  }, [order.id])
  /* eslint-enable */

  let css = 'w-80'
  if (order.cardVideoEncodedConfig!.vertical) {
    if (order.cardVideoEncodedConfig!.allowVertical) {
      // A vertical video in a vertical (square) book
      css = 'transform -rotate-90 w-44'
    } else {
      // A vertical video in a rectangular book (hinge at the top)
      css = 'transform rotate-90 w-44'
    }
  }

  let correctVideo = <video autoPlay muted onClick={chooseRightVideo} key={order.id} className={ css }>
      <source src={buildVideoURL(order)} type="video/mp4" />
    </video>

  let randomVideo: any
  if (loadingRandomOrder) {
    randomVideo = <></>
  } else if (randomOrder.current !== null){
    // @ts-ignore It doesn't like that randomOrder hasn't been set yet
    randomVideo = <video autoPlay muted onClick={chooseWrongVideo} key={randomOrder.current.id} className={ (!!randomOrder.current.cardVideoEncodedConfig && randomOrder.current.cardVideoEncodedConfig!.vertical) ? 'transform -rotate-90 w-44' : 'w-80' }>
      <source src={buildVideoURL(randomOrder.current)}  type="video/mp4" />
    </video>
  } else {
    randomVideo = <video autoPlay muted onClick={chooseWrongVideo} key="homepage-video" className="max-w-xs">
      <source src="https://public.sendheirloom.com/Homepage_11_2020_RF_24.mp4" type="video/mp4" />
    </video>
  }

  let videoSection: JSX.Element
  if (correctFirst){
    videoSection = <>
      { correctVideo }
      { randomVideo }
    </>
  } else {
    videoSection = <>
      { randomVideo }
      { correctVideo }
    </>
  }

  return <div className="App-finalize-compare-videos">
    <h2>Finalize Order: Click the video which plays on this card</h2>
    { videoSection }
  </div>
}
