import React from 'react'
import { graphql, createFragmentContainer } from 'react-relay'
import type { IEnvironment } from 'relay-runtime'

import OrderList from './OrderList'
import type { SearchOrderList_query } from './__generated__/SearchOrderList_query.graphql'

interface Props {
  environment: IEnvironment
  query: SearchOrderList_query | null
}

function SearchOrderList({ query, environment }: Props) {
  if(!query) {
    return <>Error</>
  }
  const { searchOrders } = query

  // @ts-ignore
  return OrderList({ orders: searchOrders, environment, query })
}

export default createFragmentContainer(
  SearchOrderList,
  {
    query: graphql`
      fragment SearchOrderList_query on Query @argumentDefinitions(
        query: {type: "String", defaultValue: ""},
      ) {
        ...FinalizeDialog_query
        searchOrders(query: $query, destinationsBecomeOrders: true) {
          ...OrderDetails_order
          id
          name
          status
          reservedBy
          reservedAt
          paidAt
          cardVideoURL
          cardVideoSHA256
          cardVideoEncodeProgress
          shareVideoEncodeProgress
          shareVideoURL
          customerName
          scheduledDeliveryDate
          loyaltyCode

          notes {
            id
            createdAt
            content
            createdBy {
              name
              email
            }
          }

          balanceUsages {
            id
            vendorSaleVendorId
            vendorSaleId
          }

          vendorSale {
            id
            vendorId
          }

          customer {
            id
            email
            flagOrders {
              createdByName
              createdByEmail
              createdAt
              description
              emoji
            }
          }

          shopify {
            email
            createdAt
            note
            giftMessage
            discountCodes
            shippingAddress {
              address1
              address2
              city
              company
              countryCode
              firstName
              name
              phone
              province
              provinceCode
              zip
            }
            shipping {
              carrierIdentifier
              code
            }
            lineItems {
              quantity
              SKU
              product {
                title
                images {
                  src
                }
              }
              destination {
                id
                coversPrinted
                cover {
                  approvedAt
                }
                backCover {
                  approvedAt
                }
              }
            }
          }

          shippingLabels {
            label_id
            is_return
            created_at
            status
            voided
            shipment_id
            tracking_number
            tracking_status
            carrier_code
            service_code
            label_download {
              pdf
              png
            }
            form_download {
              href
            }
          }

          cardVideoEncodedConfig {
            vertical
            allowVertical
          }

          videoDownloadStatus
          videoPercentageDownloaded

          videoTransferStatus
          videoPercentageTransferred
        }
      }
    `
  }
)
