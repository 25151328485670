import { TypePolicy } from '@apollo/client'
import type { Order, OrderDetailsFragment } from './__generated__'
import { DevicesPolicy as devices } from './local'

export type IOrder = Pick<Order, 'id'> & OrderDetailsFragment

export const QueryPolicy: TypePolicy = {
  fields: {
    allOrders: {
      keyArgs: ['express', 'shippedNoPickup'],
      merge(existing, incoming, { readField }) {
        const orders = existing ? { ...existing.orders } : {}

        incoming.orders.forEach((el: any) => {
          // @ts-ignore
          orders[readField('id', el)] = el
        })

        return {
          cursor: incoming.cursor,
          orders,
        }
      },
      read(existing) {
        if (existing) {
          return {
            cursor: existing.cursor,
            orders: Object.values(existing.orders),
          }
        }
      },
    },
    devices,
  },
}
