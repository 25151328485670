/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DeviceMonitor_query = {
    readonly devices: ReadonlyArray<{
        readonly id: string;
        readonly status: string;
        readonly videoTransferStatus: string | null;
        readonly videoPercentageTransferred: number | null;
    } | null>;
    readonly " $refType": "DeviceMonitor_query";
};
export type DeviceMonitor_query$data = DeviceMonitor_query;
export type DeviceMonitor_query$key = {
    readonly " $data"?: DeviceMonitor_query$data;
    readonly " $fragmentRefs": FragmentRefs<"DeviceMonitor_query">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceMonitor_query",
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "devices",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "videoTransferStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "videoPercentageTransferred",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = '7ff0f8e21d6380d2b169386a098c3d38';
export default node;
