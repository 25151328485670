import React, { useEffect } from 'react'
import { makeVar, useReactiveVar } from '@apollo/client';
import { cancelPreload, cancelAllPreloads } from '../Pipeline/Downloader'
import { recheckOrders } from '../Pipeline/Pipeline'

import type { IEnvironment } from 'relay-runtime'

let initLoadAll = true
if (localStorage.loadAll){
  initLoadAll = JSON.parse(localStorage.loadAll)
}
const loadAll = makeVar<boolean>(initLoadAll)

let initOrderIds = {}
if (localStorage.loadOrderIds){
  initOrderIds = JSON.parse(localStorage.loadOrderIds)
}
const loadOrderIds = makeVar(initOrderIds)

export function shouldLoad(orderId: string){
  return loadAll() || loadOrderIds()[orderId]
}

interface LoadListHeaderProps {
  environment: IEnvironment
}

export function LoadListHeader({ environment }: LoadListHeaderProps) {
  const loadAllVar = useReactiveVar(loadAll)

  useEffect(() => {
    if (!loadAll()){
      cancelAllPreloads(environment)
    } else {
      recheckOrders(environment)
    }

    localStorage.setItem('loadAll', JSON.stringify(loadAll()))
  }, [loadAllVar])


  function toggle(e){
    loadAll(e.target.checked)
    loadOrderIds({})
  }

  return <input
            type="checkbox"
            checked={ loadAllVar }
            onChange={ toggle }
          />
}

interface LoadListToggleProps {
  environment: IEnvironment
  order: string
}

export function LoadListToggle({ orderId, environment }: LoadListToggleProps) {
  const loadOrderIdsVar = useReactiveVar(loadOrderIds)
  const loadAllVar = useReactiveVar(loadAll)

  useEffect(() => {
    localStorage.setItem('loadOrderIds', JSON.stringify(loadOrderIds()))
  }, [loadOrderIdsVar])

  if (!orderId) {
    return <></>
  }

  function onChange(e){
    let currentOrderIds = {...loadOrderIds()}
    if (currentOrderIds[orderId]) {
      delete currentOrderIds[orderId]
      loadOrderIds(currentOrderIds)

      cancelPreload(environment, orderId)
    } else {
      loadAll(false)
      currentOrderIds[orderId] = true
      loadOrderIds(currentOrderIds)

      recheckOrders(environment)
    }
  }

  return <div>
    <input
      type="checkbox"
      className="m-auto block"
      checked={ loadOrderIdsVar[orderId] || loadAllVar }
      onChange={ onChange } />
  </div>
}
