import React from 'react'
import { graphql, createFragmentContainer } from 'react-relay'
import type { IEnvironment } from 'relay-runtime'
import { Link } from '@reach/router'
import TimeAgo from 'react-timeago'
import * as Sentry from "@sentry/react"
import CopyToClipboard from 'react-copy-to-clipboard'

import { OrderDetails_order } from './__generated__/OrderDetails_order.graphql'
import OrderActiveToggle from './OrderActiveToggle'
import ShopifyOrderButton from './ShopifyOrderButton'
import ShareVideoButton from './ShareVideoButton'
import { BLANK_VIDEO } from './Pipeline/Devices'
import { filenameWithoutExt } from './FinalizeDialog/LabelSection'
import { options as fulfillmentOptions } from './components/FulfillmentOptions'
import { TrackingButton, FlagButton, LoadListToggle, LinotypeOrderButtons } from './components'

interface Props {
  environment: IEnvironment
  order: OrderDetails_order | null
  onOpenDialog: () => void
}

const BOTH_SIZE_COVERS = ["BOOKFLOWERS2021", "BOOKBABY2021", "BOOKBIRTHDAY2021", "BOOKHEARTS2021"]

interface Item {
  SKU: string
}

export function isItemBook(item: Item): boolean {
  return !!item.SKU && (item.SKU === 'HEIRLOOM' || item.SKU.indexOf('BOOK') === 0)
}

export function hasBook(order: OrderDetails_order): boolean {
  if (!!!order.shopify?.lineItems){
    throw new Error("Order " + order.id + " missing line items")
  }

  for (var i=0; i < order.shopify!.lineItems!.length; i++){
    const item = order.shopify!.lineItems![i]

    if (isItemBook(item)) {
      return true
    }
  }

  return false
}

export function isCustomCover(coverId: string): boolean {
  return coverId !== "" && coverId.indexOf("cover-") !== 0
}

export function isExternalSale(order: OrderDetails_order): boolean {
  if (order?.balanceUsages) {
    for (let i=0; i < order.balanceUsages.length; i++){
      if (order.balanceUsages[i].vendorSaleVendorId !== 'heirloom') {
        return true
      }
    }
  }
  return false
}

export function isBlankVideo(order: OrderDetails_order): boolean {
  // New orders
  if (order.cardVideoURL === BLANK_VIDEO) {
    return true
  }

  // Old orders
  return (order.shopify!.videoManifest?.length === 1 && order.shopify!.videoManifest[0] === BLANK_VIDEO)
}

export function getLabelSize(order: OrderDetails_order): string {
  if (!!!order.cardVideoEncodedConfig || !!!order.shopify?.lineItems){
    console.log("Card video encoded config or line items are missing", order.id, order)
    Sentry.captureException(new Error("Card video encoded config or line items are missing"))
    return "4x6"
  }

  let coverInBothSizes = false
  let giftBox = false
  for (var i=0; i < order.shopify!.lineItems!.length; i++){
    const item = order.shopify!.lineItems![i]

    if (item.SKU === 'BOOKCUSTOM') {
      // Custom covers are only available in square
      return "4x4"
    }
    if (!isBlankVideo(order) && !isExternalSale(order) && BOTH_SIZE_COVERS.indexOf(item.SKU) !== -1){
      coverInBothSizes = true
    }
    if (item.SKU === 'GIFTBOX'){
      giftBox = true
    }
  }

  if (coverInBothSizes){
    // This book is available in both horizontal and vertical.
    // If the order has it vertical, let's use a square cover, otherwise a rectangular.

    if (giftBox){
      // Gift box is only available for square books
      if (!order.cardVideoEncodedConfig!.allowVertical){
        console.error("Warning: gift box is only available for square books, check order", order.id)
      }

      return "4x4"
    } else {
      return (order.cardVideoEncodedConfig!.allowVertical && order.cardVideoEncodedConfig!.vertical) ? '4x4' : '4x6'
    }
  }

  // Otherrwise, if we say 'allowVertical', it must be a square book
  return order.cardVideoEncodedConfig!.allowVertical ? '4x4' : '4x6'
}

export function OrderDetails({ order, environment, onOpenDialog }: Props) {
  if (!order) {
    return <div></div>
  }

  const {
    id,
    videoDownloadStatus,
    videoPercentageDownloaded,
    videoTransferStatus,
    videoPercentageTransferred,
    cardVideoEncodeProgress,
    shareVideoEncodeProgress,
    cardVideoURL,
    shopify,
    paidAt,
  } = order

  let { shareVideoURL } = order

  let downloadStatus: string = ''
  if (videoDownloadStatus === 'downloading') {
    downloadStatus = videoPercentageDownloaded
      ? Math.floor(videoPercentageDownloaded) + '%'
      : 'starting'
  } else if (videoDownloadStatus === 'downloaded') {
    downloadStatus = 'downloaded'
  } else if (!!!cardVideoURL && cardVideoEncodeProgress > 0 && cardVideoEncodeProgress < 100) {
    downloadStatus = 'encoding ' + Math.floor(cardVideoEncodeProgress) + '%'
  }

  let transferStatus: string = ''
  if (videoTransferStatus === 'transferring') {
    if (videoPercentageTransferred! > 99) {
      if (fulfillmentOptions.verifyTransfers()){
        transferStatus = 'verifying'
      } else {
        transferStatus = 'finishing'
      }
    } else if (videoPercentageTransferred! === 0) {
      if (fulfillmentOptions.formatBooks()){
        transferStatus = 'formatting'
        } else {
        transferStatus = 'starting'
      }
    } else {
      transferStatus = Math.floor(videoPercentageTransferred!) + '%'
    }
  } else if (videoTransferStatus === 'transferred') {
    transferStatus = 'transferred'
  } else if (videoDownloadStatus === 'downloaded') {
    transferStatus = 'waiting for device'
  }

  let creditInfo: JSX.Element = <></>
  if (order.balanceUsages) {
    const balanceUsagesByVendor: { [key: string]: BalanceUsage[] } = {}
    for (let i=0; i < order.balanceUsages.length; i++){
      const balanceUsage = order.balanceUsages[i]
      if (!balanceUsagesByVendor[balanceUsage.vendorSaleVendorId]){
        balanceUsagesByVendor[balanceUsage.vendorSaleVendorId] = []
      }
      balanceUsagesByVendor[balanceUsage.vendorSaleVendorId].push(balanceUsage)
    }

    creditInfo = <span>
      {Object.keys(balanceUsagesByVendor).map((vendorSaleVendorId) => {
        const balanceUsages = balanceUsagesByVendor[vendorSaleVendorId]
        return <span key={vendorSaleVendorId}>
          { balanceUsages[0].vendorSaleVendorId }
          { balanceUsages.length > 1 ? "×" + balanceUsages.length : "" }
        </span>
      }) }
    </span>
  } else if (order.vendorSale?.vendorId) {
    creditInfo = <span>
      <CopyToClipboard text={ order.vendorSale.vendorId}>
        <span>{ order.vendorSale.vendorId }</span>
      </CopyToClipboard>
    </span>
  }

  function dateFormatter(value: number, unit: string, suffix: string, epochSeconds: number, defaultFormatter: any) {
    if (['second', 'minute', 'hour'].indexOf(unit) !== -1) {
      return 'today'
    }

    return defaultFormatter()
  }

  type product = {
    link: string | undefined
    title: string
    classes: string
  }

  let products: product[] = []
  let containsNonemptyBook = false
  for (var i=0; i < order.shopify!.lineItems!.length; i++){
    const item = order.shopify!.lineItems![i]

    if (!item) continue;

    const isBook = isItemBook(item)
    const isEmpty = !order.shopify!.videoManifest
    containsNonemptyBook = containsNonemptyBook || (isBook && !isEmpty)

    let link: string | undefined = undefined
    let title: string = item.product!.title
    let classes: string = 'bg-gray-200'
    let alt: string = ''
    if (item.SKU.startsWith('BOOKCUSTOM') && item.product?.images?.length > 1) {
      // We pass the production image url of the custom cover as the second image.
      link = item.product.images[1].src
      title = `${item.SKU === 'BOOKCUSTOMBACK' ? 'Back' : 'Front'}: ${filenameWithoutExt(link)}`
      if (!item.destination?.cover?.approvedAt) {
        classes = 'bg-red-100'
        alt = 'Not approved'
      } else if (item.destination && item.quantity > item.destination.coversPrinted) {
        classes = 'bg-yellow-100'
        alt = `Print ${ item.quantity - item.destination.coversPrinted } more`
      }
    }

    products.push({
      title: (`
        ${ title }
        ${ item.quantity > 1 ? ` × ${ item.quantity }` : '' }
        `).trim(),
      link,
      classes,
      alt,
    })
  }

  const flag = order.customer?.flagOrders
  const hasShopify = !order.id.includes(':')
  const blankVideo = isBlankVideo(order)

  if (blankVideo) {
    shareVideoURL = BLANK_VIDEO
  }

  let shareSection: JSX.Element = <></>
  if (hasBook(order)){
    if (blankVideo || shareVideoURL){
      let shareIcon = undefined
      if (blankVideo){
        shareIcon = '🥟'
      }

      shareSection = <ShareVideoButton shareVideoURL={shareVideoURL} icon={shareIcon} />
    } else if (shareVideoEncodeProgress > 0 && shareVideoEncodeProgress < 100) {
      shareSection = <>{ Math.floor(shareVideoEncodeProgress) }%</>
    }
  }

  let unvoidedTrackingNumber: string | undefined = undefined
  if (order.shippingLabels) {
    for (let i=0; i < order.shippingLabels.length; i++){
      if (order.shippingLabels[i] && order.shippingLabels[i].voided === false && order.shippingLabels[i].is_return !== true) {
        unvoidedTrackingNumber = order.shippingLabels[i].tracking_number
        break
      }
    }
  }

  let need4gbModule = false
  let hasDropShipExtra = false
  order.designs?.forEach(design => {
    if (design.design?.renders) {
      for (const render of design.design?.renders) {
        // check for the most recent / fully encoded render
        if (render.task === "normalize" && render.sizeKB) {
          if (render.sizeKB > 220416000 / 1024) {
            need4gbModule = true
          }
          break;
        }
      }
    }

    design.destinations?.forEach(dest => {
      dest?.extras?.forEach(extra => {
        if (extra.id.startsWith('dropship-')) {
          hasDropShipExtra = true
        }
      })
    })
  })

  return (
    <tr
      key={id}
      className={`App-order-detail App-order-detail-status-${order.status} hover:bg-gray-100`}
    >
      <td align="left">
        {(order.notes?.length ?
          <button className="text-lg" title="This order has notes" onClick={ () => onOpenDialog() }>
            🎶
          </button>
          : <></>
        )}
      </td>

      <td align="left" width={25}>
        {(need4gbModule ?
          <button title="This order is too large for the 256MB module">
            <img src="/4gb.png" alt="4gb" />
          </button>
          : <></>
        )}
        {(hasDropShipExtra ?
          <button title="This order contains dropship items">
            <img src="/dropship-kit.jpeg" alt="dropship-kit" />
          </button>
          : <></>
        )}
      </td>

      <td align="left">
        {(flag ?
          <FlagButton flag={ flag } customerId={ order.customer?.id } />
          : <></>
        )}
      </td>

      <td align="left">
        { shareSection }
      </td>

      <td align="left">
        {(hasShopify
          ? <ShopifyOrderButton order={order} mini={true} />
          : <LinotypeOrderButtons order={order} />
        )}
      </td>

      <td align="left" className="text-lg font-bold" style={{marginTop: '-1px', marginLeft: '-3px'}}>
        <Link to={`/order/${order.id}`} className={ containsNonemptyBook && (order?.cardVideoEncodeAttempts || 0) > 2 ? 'text-red-400' : '' }>
          ⓘ
        </Link>
      </td>

      <td align="left">
        <TrackingButton trackingNumber={ unvoidedTrackingNumber } />
      </td>

      <td align="center">
        { order.name }
      </td>

      <td align="left">
        <button
          className="looks-like-link"
          style={{ paddingLeft: '1em', textAlign: 'left' }}
          onClick={onOpenDialog}
        >
          { order.customerName.trim() }
        </button>
      </td>

      <td align="left" className="pb-1">
        <ul>
          { products.map((p, i) => {
            return <li
              key={ i }
              className={ "inline-block mr-1 mt-1 p-1 rounded " + p.classes }
              >{ !p.link
              ? p.title
              : <a title={ p.alt } href={ p.link } target="_blank" className="underline">{ p.title }</a>
            }</li>
          }) }
        </ul>
      </td>

      <td align="left">
        <TimeAgo
          date={ paidAt || shopify?.createdAt || '' }
          formatter={dateFormatter}
        />
      </td>

      <td align="left">
        { shopify?.shipping?.carrierIdentifier ? `${shopify.shipping.carrierIdentifier} ` : "" }
        { shopify?.shipping?.code && shopify.shipping.code !== 'Standard' ? shopify.shipping.code : "" }
      </td>

      <td align="center">{creditInfo}</td>

      <td align="center">
        <output>{downloadStatus}</output>
      </td>

      <td align="center">
        <output>{transferStatus}</output>
      </td>

      <td align="center">
        <OrderActiveToggle order={order} environment={environment} />
      </td>

      <td align="left">
        <LoadListToggle orderId={order.id} environment={environment} />
      </td>
    </tr>
  )
}

export default createFragmentContainer(OrderDetails, {
  // This includes some fields used by pipeline/Devices and the fulfillment dialog
  order: graphql`
    fragment OrderDetails_order on Order {
      id
      name
      status
      createdAt
      reservedBy
      reservedAt
      paidAt
      cardVideoURL
      cardVideoSHA256
      cardVideoEncodeAttempts
      cardVideoEncodeProgress
      shareVideoEncodeProgress
      shareVideoURL
      customerName
      loyaltyCode
      notes {
        id
        createdAt
        content
        createdBy {
          name
          email
        }
      }

      balanceUsages {
        id
        vendorSaleVendorId
        vendorSaleId
        products {
          productId
          quantity
        }
      }

      vendorSale {
        id
        vendorId
      }

      designs {
        design {
          id
          generation
          createdAt
          media {
            id
            type
            format
            encodedLocation
            thumbnailLocation
            encodeProgress
            encodeAttempts
            isVertical
          }
          renders {
            id
            formFactor
            task
            signedURL
            sizeKB
            encodedConfig {
              vertical
            }
          }
        }
        destinations {
          id
          createdAt
          state
          bookType
          coverId
          quantity
          shippingSpeed
          shippingServiceCode
          giftMessage {
            body
          }
          address {
            fullName
            companyName
            street1
            street2
            cityLocality
            countryCode
            stateProvinceCode
            postalCode
            phoneNumber
          }
          extras {
            id
          }
          items {
            id
            createdAt
            type
            state
          }
          packages {
            id
            createdAt
            state

            shippingLabels {
              label_id
              is_return
              status
              created_at
              tracking_number
              service_code
              voided
            }
          }
        }
      }

      customer {
        id
        email
        flagOrders {
          createdByName
          createdByEmail
          createdAt
          description
          emoji
        }
      }

      shopify {
        email
        createdAt
        note
        giftMessage
        discountCodes
        videoManifest
        shippingAddress {
          address1
          address2
          city
          company
          countryCode
          name
          firstName
          phone
          province
          provinceCode
          zip
        }
        shipping {
          carrierIdentifier
          code
        }
        lineItems {
          quantity
          SKU
          product {
            title
            images {
              src
            }
          }
          destination {
            id
            coversPrinted
            cover {
              approvedAt
            }
            backCover {
              approvedAt
            }
          }
        }
      }

      shippingLabels {
        label_id
        created_at
        status
        voided
        shipment_id
        tracking_number
        tracking_status
        label_download {
          pdf
          png
        }
        form_download {
          href
        }
      }

      cardVideoEncodedConfig {
        vertical
        allowVertical
      }

      videoDownloadStatus
      videoPercentageDownloaded

      videoTransferStatus
      videoPercentageTransferred
    }
  `,
})
