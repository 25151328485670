import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useForm } from "react-hook-form";
import { CreateShopifyOrderDocument } from './graphql/__generated__'
import type { RouteComponentProps } from '@reach/router'

interface NewOrderProps extends RouteComponentProps {}

const NewOrder  = (_: NewOrderProps) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      country: 'US',
    }
  })
  const [createShopifyOrder, { loading, data, error }] = useMutation(CreateShopifyOrderDocument, {
    onError: (error: ApolloError) => {
      console.error("Error saving change", error)
    }
  })

  const onSubmit = (data) => {
    let books = []
    Array.from({ length: 5 }, (_, i) => {
      let qty = +data.quantity[i]
      if (!isNaN(qty) && qty !== 0 && data.coverId[i] !== '') {
        books.push({
          coverId: data.coverId[i].toUpperCase(),
          quantity: qty,
        })
      }

      return null
    })

    createShopifyOrder({
      variables: {
        order: {
          email: data.email,
          address: {
            fullName: data.name,
            street1: data.street1,
            cityLocality: data.city,
            countryCode: data.country,
            stateProvinceCode: data.state,
            postalCode: data.zip,
          },
          giftMessage: data.giftMessage,
          books,
        }
      }
    })
  }

  let out = <></>
  if (loading) {
    out = <>Saving</>
  } else if (data) {
    out = <>Saved</>
  } else if (error) {
    out = <div className="bg-red-100">{ error.toString() }</div>
  }

  let emptyOrder: OrderInfoQuery['order'] = {}

  return (
    <div className="w-8/12 m-auto">
      <h1 className="text-lg">Create an Order</h1>
      <form className="text-left" onSubmit={handleSubmit(onSubmit)}>
         <label className="block">
          <textarea {...register("magic")} className="h-32 mt-2 block m-auto w-full bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" autoComplete="off" style={{ fontSize: '0.5rem', lineHeight: '1.2' }}></textarea>
        </label>
        <label className="block">
          <input type="text" {...register("email")} placeholder="Email" className="mt-2 block m-auto w-full bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" autoComplete="off" />
        </label>

        <h2 className="text-base">Shipping Address</h2>
        <label className="block">
          <input type="text" {...register("name")} placeholder="Name" className="mt-2 block m-auto w-full bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" autoComplete="off" />
        </label>
        <label className="block">
          <input type="text" {...register("street1")} placeholder="Street" className="mt-2 block m-auto w-full bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" autoComplete="off" />
        </label>
        <label className="block">
          <input type="text" {...register("city")} placeholder="City" className="mt-2 block m-auto w-full bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" autoComplete="off" />
        </label>
        <label className="mt-6">
          <input type="text" {...register("state")} placeholder="State" maxLength="2" className="mt-2 w-2/12 bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" autoComplete="off" />
        </label>
        <label className="mt-6">
          <input type="text" {...register("zip")} placeholder="ZIP" className="mt-2 ml-2 w-2/12 bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" autoComplete="off" />
        </label>
        <label className="mt-6">
          <input type="text" {...register("country")} placeholder="Country" maxLength="2" className="mt-2 ml-2 w-1/12 m-auto bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" autoComplete="off" />
        </label>

        <h2 className="text-base mt-12">Book Options</h2>
        <label className="block mt-6">
          <span className="text-gray-700 text-sm">Gift Message</span>
          <textarea {...register("giftMessage")} className="h-32 text-sm mt-2 block m-auto w-full bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" autoComplete="off"></textarea>
        </label>

        <ul>
        { Array.from({ length: 5 }, (_, i) => <li key={i}>
              <label className="mt-6">
                <input type="text" {...register(`quantity.${ i }`)} placeholder="Qty" className="mt-2 w-2/12 bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" autoComplete="off" />
              </label>
              <label className="mt-6">
                <input type="text" {...register(`coverId.${ i }`)} placeholder="Cover ID" className="ml-2 mt-2 w-4/12 m-auto bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" autoComplete="off" />
              </label>
            </li>
        ) }
        </ul>

        <input type="submit" className="mt-6 p-5 text-base cursor-pointer bg-gray-100 border-transparent focus:border-gray-500 focus:ring-0" />
        <output className="inline-block text-lg ml-6">{ out }</output>
      </form>
    </div>
  )
}

export default NewOrder
