import React from 'react'
import type { ChangeEvent } from 'react'
import type { OrderFilters } from '../graphql/__generated__'

interface OrderTimelineFiltersProps {
  getFilters: () => OrderFilters
  setFilters: (filters: OrderFilters) => void
}

const OrderTimelineFilters = ({setFilters, getFilters}: OrderTimelineFiltersProps) => {

  function makeSetter(field: string): (evt: ChangeEvent<HTMLInputElement>) => void {
    return (evt: ChangeEvent<HTMLInputElement>) => {
      let filters = getFilters()

      // @ts-ignore
      filters[field] = evt.target.checked
      setFilters(filters)
    }
  }

  return <div className="App-timeline-filters mb-4">
    <h4 className="inline-block mr-4 font-bold">Filter</h4>
    <input type="checkbox" defaultChecked={ getFilters().express || false } onChange={ makeSetter('express') } id="express" className="invisible absolute" />
    <label htmlFor="express" className="p-2">Express</label>

    <input type="checkbox" defaultChecked={ getFilters().shippedNoPickup || false } onChange={ makeSetter('shippedNoPickup') } id="shippedNoPickup" className="invisible absolute" />
    <label htmlFor="shippedNoPickup" className="p-2">No Pickup</label>
  </div>
}

export default OrderTimelineFilters
