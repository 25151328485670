import React, { useEffect, useState } from 'react'
import type { IEnvironment } from 'relay-runtime'
import { DEBUG } from '../Debug'

import { renderMessage } from '../Labels/Message'
import { renderCoupon } from '../Labels/Coupon'
import ShipmentRateQuote from '../Shipment/RateQuote'
import ShipmentVoidButton from '../Shipment/VoidButton'
import PrintButton from '../PrintButton'
import FinalizeButton from '../FinalizeButton'
import { OrderNotes, Helpdesk, ArchiveOrderButton, FlagButton, AlignButtons, AspectButtons } from '../components'
import { isItemBook, getLabelSize, isExternalSale } from '../OrderDetails'
import type { Alignment } from '../components/AlignButtons'
import type { Aspect } from '../components/AspectButtons'

import exampleShippingLabelImage from '../Images/example-shipping-label.png'
import { options as fulfillmentOptions } from '../components/FulfillmentOptions'

import type { CanvasRef } from '../Labels/Message'
import type { OrderDetails_order } from '../__generated__/OrderDetails_order.graphql'

interface Props {
  order: OrderDetails_order
  environment: IEnvironment
  allowAutoPrint: boolean
  allowAutoBuy: boolean
  onFinalized: () => void
}

export function filenameWithoutExt(path: string): string {
  const parts = path.split('/')
  const last = parts[parts.length - 1]
  return last.split('.')[0]
}

export function LabelSection({ order, environment, onFinalized, allowAutoPrint, allowAutoBuy }: Props) {
  const [messageImage, setMessageImage] = useState<string|null>(null)
  const [couponImage, setCouponImage] = useState<string|null>(null)
  const [labelLoaded, setLabelLoaded] = useState<boolean>(false)
  const [messageAlignment, setMessageAlignment] = useState<Alignment>('center')
  const [messageAspect, setMessageAspect] = useState<Aspect>('auto')
  // @ts-ignore
  const [visibleLabel, setVisibleLabel] = useState<OrderDetails_order["shippingLabels"][number]|string>("_NEW")

  let labels = [...order.shippingLabels || []]
  // @ts-ignore
  labels.sort((a, b) => {
    let aC = +(new Date(a.created_at))
    let bC = +(new Date(b.created_at))
    if (a.is_return) aC = aC - 1e12
    if (b.is_return) bC = bC - 1e12
    return bC - aC
  })

  let validLabels = labels.filter((label) => {
    return !label.voided
  })

  useEffect(() => {
    if (labels){
      for (let i=0; i < labels.length; i++){
        if (!labels[i].voided){
          setVisibleLabel(labels[i])
          return
        }
      }
    }

    setVisibleLabel("_NEW")
  }, [order.shippingLabels])

  // After a void we stop auto buying labels
  const [didVoid, setDidVoid] = useState<boolean>(false)

  const labelSize = () => {
    if (messageAspect === 'auto') {
      return getLabelSize(order)
    } else if (messageAspect === 'rect') {
      return '4x6'
    } else {
      return '4x4'
    }
  }

  useEffect(() => {
    setMessageImage(null)

    if (order?.shopify?.giftMessage){
      renderMessage(order.shopify.giftMessage, labelSize(), messageAlignment).then((canvasRef: CanvasRef|null) => {
        if (!canvasRef || !canvasRef.canvas){
          return
        }

        setMessageImage(canvasRef.canvas.toDataURL('image/png'))
        canvasRef.unload()
      })
    }
  }, [order?.shopify?.giftMessage, order.cardVideoEncodedConfig, messageAlignment, messageAspect])

  useEffect(() => {
    setCouponImage(null)
    if (isExternalSale(order) || order?.name[0] === 'H'){
      // We're not allowed to include coupons with most vendor orders,
      // and we also don't include it for Shopify orders, as these days that's
      // just Amazon Kits.
      return
    }

    // Expiration is three months from now
    const exp = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30 * 3)

    // We want the shipping address, not the customer's name, as this label is going in the box
    let name = ""
    if (order?.shopify?.shippingAddress?.firstName){
      name = order.shopify.shippingAddress.firstName
    } else if (order?.shopify?.shippingAddress?.name){
      name = order.shopify.shippingAddress.name
    }

    renderCoupon(order?.id, name, exp).then((canvasRef: CanvasRef|null) => {
      if (!canvasRef || !canvasRef.canvas){
        return
      }

      setCouponImage(canvasRef.canvas.toDataURL('image/png'))
      canvasRef.unload()
    })
  }, [
    order?.id,
    order?.name,
    order.shopify.shippingAddress?.firstName,
    order.shopify.shippingAddress?.name,
    order.balanceEntries,
  ])

  let paidUsForExpress = false
  let shippingServiceCode: string | undefined
  if (order.shopify?.shipping?.code){
    paidUsForExpress = ["expedited", "express"].indexOf(order.shopify.shipping.code.toLowerCase()) !== -1
    if (!paidUsForExpress && order.shopify.shipping.code != 'Standard') {
      // It's not any of our code words, so it must be a specific service
      shippingServiceCode = order.shopify.shipping.code
    }
    if (shippingServiceCode === 'economy') {
      shippingServiceCode = 'best-economy'
    }
  }

  //  We don't use this, but it's here to illustrate the other way we could have an express order
  //  const paidVendorForExpress = ["Express (Prepaid)"].indexOf(`${order.shopify?.shipping?.code}`) !== -1

  let returnsIndex = 0
  let normalIndex = 0
  const tabsSection: JSX.Element =
    <>
      <ul>
        { labels.map((label, i) =>
          <li
            key={ label.label_id }
            className="inline-block ml-2"
          >
            <button
              className={ (visibleLabel === label ? 'border-blue-700 border-2' : 'border-gray-200 border-2') + ' ' + (label.voided ? 'bg-red-300' : '') }
              onClick={ () => {
                // @ts-ignore
                setVisibleLabel(label)
                setLabelLoaded(false)
              }}
            >
              { label.is_return ? "R" : "" }
              { label.is_return ? ++returnsIndex : ++normalIndex }</button>
          </li>
        )}
        <li
          className="inline-block mx-2"
        >
          <button
            onClick={ () => setVisibleLabel("_NEW") }
            className={ (visibleLabel === "_NEW" ? 'border-blue-700 border-2' : 'border-gray-200 border-2') }
          >+</button>
        </li>
        <li
          className="inline-block mx-2"
        >
          <button
            onClick={ () => setVisibleLabel("_RETURN") }
            className={ (visibleLabel === "_RETURN" ? 'border-blue-700 border-2' : 'border-gray-200 border-2') +
            " !p-1 flex justify-center" }
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 pt-1"> <path strokeLinecap="round" strokeLinejoin="round" d="M15 15l-6 6m0 0l-6-6m6 6V9a6 6 0 0112 0v3" /> </svg>
          </button>
        </li>

      </ul>
    </>

  type CustomCover = {
    thumbnailUrl: string
    productionUrl: string
    productionName: string
  }

  let useGiftBox = false
  let quantity = 1
  let customCover: CustomCover | undefined
  let backCustomCover: CustomCover | undefined
  if (order?.shopify?.lineItems) {
    for (let i=order.shopify.lineItems.length; i--;){
      let item = order.shopify.lineItems[i]
      if (!item) continue;

      switch (item.SKU) {
        case 'GIFTBOX':
          useGiftBox = true
          break
        case 'BOOKCUSTOM':
          if (item.product?.images && item.product.images.length > 1) {
            customCover = {
              thumbnailUrl: item.product.images[0].src,
              productionUrl: item.product.images[1].src,
              productionName: filenameWithoutExt(item.product.images[1].src),
            }
          }
          break
        case 'BOOKCUSTOMBACK':
          if (item.product?.images && item.product.images.length > 1) {
            backCustomCover = {
              thumbnailUrl: item.product.images[0].src,
              productionUrl: item.product.images[1].src,
              productionName: filenameWithoutExt(item.product.images[1].src),
            }
          }
          break
      }

      if (isItemBook(item)) {
        quantity = item.quantity
      }
    }
  }

  let visibleTab: JSX.Element

  // TODO It might be a good idea to include 'exception', but FedEx might apply that if a package is just late
  // You would think in_transit would mean it was picked up, but USPS applies that status initially
  // Similarly, accepted should mean it was accepted, but UPS applies that initially
  // So we're left with no simple way of identifying shipped packages, we should look at the actual tracking events
  const visibleLabelShipped = (visibleLabel[0] !== "_" && ["delivered"].indexOf(visibleLabel.tracking_status) !== -1)

  if (visibleLabel[0] !== '_'){
    visibleTab =
      <>
        <img
          className={"App-real-shipping-label " + (!labelLoaded ? 'invisible absolute pointer-events-none' : '')}
          src={ visibleLabel?.label_download?.png || '' }
          alt="Shipping label"
          onLoad={ () => setLabelLoaded(true) }
        />
        {!labelLoaded ?
          <img
            className="App-fake-shipping-label"
            src={ exampleShippingLabelImage }
            alt="Fake shipping label"
          />
        : <></>}
        <div hidden={ !labelLoaded || visibleLabel.voided || visibleLabelShipped } className="App-label-controls">
          <div className="App-finalize-rate-container">
            <PrintButton
              autoPrint={ allowAutoPrint && fulfillmentOptions.autoPrintLabel() }
              paperSize="4x6"
              url={ visibleLabel?.label_download?.pdf || '' }
              type="pdf"
              title="Label"
              hotkey="l"
            />

            { visibleLabel?.is_return &&
              <a
                className="block p-4 bg-white border border-black"
                href={ visibleLabel?.label_download?.pdf || '' }
                target="_blank"
                rel="noopener noreferrer"
              >Download Label</a>
            }
          </div>
          { visibleLabel.form_download?.href &&
            <div
              className="bg-blue p-3">
              <a href={ visibleLabel.form_download.href } target="_blank" rel="noreferrer">
                This shipment requires additional forms. Click to download, then attach them to the box.
              </a>
            </div>
          }
          { !visibleLabelShipped ?
            <ShipmentVoidButton
              orderID={ order.id }
              labelID={ visibleLabel.label_id }
              environment={ environment }
              onVoid={ () => setDidVoid(true) }
            />
            : <></>
          }
        </div>
      </>
  } else {
    const isReturn = visibleLabel === "_RETURN"
    let defaultDomestic = 'best-economy'

    if (!isReturn) {
      defaultDomestic = DEBUG('default_domestic_service') || (paidUsForExpress ? "best-express" : "best-economy")
      if (order.vendorSale && !paidUsForExpress) {
        switch (order.vendorSale.shippingSpeed) {
          // A preorder through the Heirloom Shopify site
          case "":
            // We leave it as the default
            break
          // We cover the shipping on these orders to help with UGs margins
          case "UG:economy":
          case "UG:perks":
            defaultDomestic = "best-economy"
            break
          case "UG:standard":
            defaultDomestic = "fedex_home_delivery"
            break
          case "UG:expedited":
            // Note there is a bug here, some destinations don't actually show this
            // rate (like Alaska), and we end up paying for the shipping.
            defaultDomestic = "fedex_express_saver"
            break
          case "UG:express":
            defaultDomestic = "fedex_2day"
            break
        }
      }
    }

    visibleTab =
      <>
        <img
          className="App-fake-shipping-label"
          src={ exampleShippingLabelImage }
          alt="Fake shipping label"
        />
        <div className="App-finalize-rate-container">
          <ShipmentRateQuote
            orderID={ order.id }
            environment={ environment }
            isReturn={ isReturn }
            defaultQuantity={ quantity }
            defaultDomesticService={ shippingServiceCode || defaultDomestic }
            defaultInternationalService={ shippingServiceCode || "usps_first_class_mail_international" }
            autoBuyLabel={ allowAutoBuy && fulfillmentOptions.autoBuyLabel() && !didVoid }
          />
        </div>
      </>
  }

  let imageBlock: JSX.Element
  if (messageImage) {
    imageBlock = <>
      <img src={ messageImage } alt="Message Preview" className="App-message-image" />
      <div className="mb-4">
        <AlignButtons alignment={ messageAlignment } setAlignment={ setMessageAlignment } />
        <AspectButtons aspect={ messageAspect } setAspect={ setMessageAspect } />
        <PrintButton
          autoPrint={ allowAutoPrint && fulfillmentOptions.autoPrintMessage() }
          type="image"
          title="Message"
          hotkey="m"
          url={ messageImage }
          paperSize={ labelSize() }
        />
      </div>
    </>
  } else {
    imageBlock = <div className="mb-4">No message provided.</div>
  }

  let coverBlock: JSX.Element
  if (customCover) {
    coverBlock = <>
      <div className="mb-4">
        <div className="mb-4">Custom Front Cover:</div>
        <a href={ customCover.productionUrl } target="_blank">
          <img src={ customCover.thumbnailUrl } />
        </a>
      </div>
      { !!backCustomCover &&
        <div className="mb-4">
          <div className="mb-4">Custom Back Cover:</div>
          <a href={ backCustomCover.productionUrl } target="_blank">
            <img src={ backCustomCover.thumbnailUrl } />
          </a>
        </div>
      }
    </>
  }

  let couponBlock: JSX.Element
  if (couponImage) {
    couponBlock = <>
      <img src={ couponImage } alt="Coupon Preview" className="App-message-image" />
      <div className="mb-4">
        <PrintButton
          autoPrint={ allowAutoPrint && fulfillmentOptions.autoPrintCoupon() }
          type="image"
          title="Coupon"
          hotkey="c"
          url={ couponImage }
          paperSize="4x4"
        />
      </div>
    </>
  }

  const service = validLabels && validLabels[0]?.service_code
  const carrier = validLabels && validLabels[0]?.carrier_code

  return <div className="App-finalize-labels">
      <h2>
        { order.customer?.id &&
          <FlagButton customerId={ order.customer.id } flag={ order.customer.flagOrders } />
        }

        Finalize Order #{ order.name }: Print shipping and message labels
      </h2>

      <div className="grid grid-cols-3 gap-2 m-auto">
        <div>
          <div className="App-finalize-labels-message mt-2">
            { coverBlock }

            { imageBlock }

            { couponBlock }
          </div>
        </div>
        <div>
          { getLabelSize(order) === "4x4" ?
            (order.cardVideoEncodedConfig!.vertical ?
                <div className="py-4 text-sm bg-red-200 text-white">
                  VERTICAL
                </div>
              :
                <div className="py-4 text-sm">
                  HORIZONTAL
                </div>
            ) : <></>
          }
          { useGiftBox ?
              <div className="py-4 bg-yellow-500 text-sm text-white">
                GIFT BOX
              </div>
            : <></>
          }
          { quantity > 1 ?
              <div className="py-4 bg-purple-400 text-sm text-white">
                QTY { quantity }
              </div>
            : <></>
          }
          { quantity > 2 && carrier && carrier !== 'stamps_com' ?
              <div className="py-4 bg-green-400 text-sm text-white">
                LITHIUM IMAGE
                { service === 'ups_ground' ? " AND TEXT" : "" }
              </div>
            : <></>
          }
          { !!customCover &&
            <>
              <a
                href={ customCover.productionUrl }
                target="_blank"
                download="front-cover.png"
                className="py-4 block bg-black text-sm text-white">
                FRONT COVER: { customCover.productionName }
              </a>
              { !!backCustomCover &&
                <>
                  <a
                    href={ backCustomCover.productionUrl }
                    target="_blank"
                    download="back-cover.png"
                    className="py-4 block bg-black text-sm text-white">
                    BACK COVER: { backCustomCover.productionName }
                  </a>
                </>
              }
            </>
          }
          <div className="App-finalize-labels-shipping">
            { tabsSection }
            { visibleTab }
          </div>

          <div className="App-huge-down-arrow">▼</div>
          <div
            className="App-finalize-button-container"
          >
            <FinalizeButton
              order={ order }
              environment={ environment }
              label={ validLabels![0] }
              onFinalized={ onFinalized }
              disabled={ !validLabels!.length }
            />
          </div>
        </div>
        <div className="mr-4">
          <ArchiveOrderButton
            order={ order }
          />

          { order.id.includes(':') ?
            <a
              className="btn inline-block mx-1 bg-gray-100"
              href={ `https://make.sendheirloom.com/sudo/${ order.customer?.email || 'missing-email' }?next=/order/${ order.id.split(':')[0] }%3Fedit%3Dtrue` }
              target="_blank"
              >Edit Order</a>
            :
            <a
              target="_blank"
              href={ `/order/${ order.id } `}
              className="btn inline-block mx-1 bg-gray-100">Edit Videos</a>
          }

          <OrderNotes
            order={ order }
          />

          <Helpdesk
            email={ order.customer?.email || '' }
            customerName={ order.customerName }
          />

          <a target="_blank" href={ "https://secure.helpscout.net/mailbox/b5353062e0ecb413/new-ticket/" +
              "?name=" + encodeURIComponent(order.customerName) +
              "&email=" + encodeURIComponent(order.customer?.email) +
              "&subject=" + encodeURIComponent("[URGENT] A Question About Your Heirloom Order") }
            className="block mt-4 bg-gray-100 py-2">Email Customer</a>
        </div>
      </div>
    </div>
}
