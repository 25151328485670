import React from 'react'
import environment from './relay-env'
import { graphql } from 'react-relay'
import { QueryRenderer } from 'react-relay'
import { RouteComponentProps } from '@reach/router'
import MyOrderList from './MyOrderList'
import DeviceMonitor from './DeviceMonitor'
import { DeviceMonitor as DeviceMonitor2 } from './components/DeviceMonitor'
import { DEBUG } from './Debug'

const query = graphql`
  query MyOrdersQuery($activeFulfillers: Int, $fulfillerIndex: Int) {
    ...MyOrderList_query @arguments(activeFulfillers: $activeFulfillers, fulfillerIndex: $fulfillerIndex)
    ...DeviceMonitor_query
    ...FinalizeDialog_query
  }
`
interface MyOrdersProps extends RouteComponentProps {
}

interface Props {
  error: Error | null
  props: any
  retry: any
}

let timeout: ReturnType<typeof setTimeout>|undefined

const renderComponent = ({ error, props, retry }: Props) => {
   // Poll for changes
  if (timeout) clearTimeout(timeout)
  timeout = setTimeout(retry, 10000)

  if (error) {
    console.error("Error loading orders", error)
    return <div>Error loading orders, retrying...</div>
  }
  if (!props) {
    return <div>Loading...</div>
  }

  return (
    <>
      <MyOrderList
        environment={environment}
        query={props}
      />

      <div className="App-device-list">
        {DEBUG('apollo_client') ? (
          <DeviceMonitor2 />
        ) : (
          <DeviceMonitor environment={environment} query={props} />
        )}
      </div>
    </>
  )
}

const MyOrders = (props: MyOrdersProps) => {
  const activeFulfillers = +DEBUG("active_fulfillers") || 1
  const fulfillerIndex = +DEBUG("fulfiller_index") || 0

  return (
    <article>
      <QueryRenderer
        environment={environment}
        query={query}
        render={renderComponent}
        variables={{ activeFulfillers, fulfillerIndex }}
      />
    </article>
  )
}

export default MyOrders
