import React from 'react'
import environment from './relay-env'
import { graphql } from 'react-relay'
import { QueryRenderer } from 'react-relay'
import { RouteComponentProps } from '@reach/router'
import SearchOrderList from './SearchOrderList'
import DeviceMonitor from './DeviceMonitor'
import { DeviceMonitor as DeviceMonitor2 } from './components/DeviceMonitor'
import { DEBUG } from './Debug'

const query = graphql`
  query SearchOrdersQuery($query: String!) {
    ...SearchOrderList_query @arguments(query: $query)
    ...DeviceMonitor_query
    ...FinalizeDialog_query
  }
`
interface SearchOrdersProps extends RouteComponentProps {
  query: string
}

interface Props {
  error: Error | null
  props: any
  retry: any
}

let timeout: ReturnType<typeof setTimeout>|undefined

const renderComponent = ({ error, props, retry }: Props) => {
  // Poll for changes
  if (timeout) clearTimeout(timeout)
  timeout = setTimeout(retry, 10000)

  if (error) {
    console.error("Error loading orders", error)
    return <div>Error loading orders, retrying...</div>
  }
  if (!props) {
    return <div>Loading...</div>
  }

  return (
    <>
      <SearchOrderList
        environment={environment}
        query={props}
      />

      <div className="App-device-list">
        {DEBUG('apollo_client') ? (
          <DeviceMonitor2 />
        ) : (
          <DeviceMonitor environment={environment} query={props} />
        )}
      </div>
    </>
  )
}

const SearchOrders = (props: SearchOrdersProps) => {
  return (
    <article>
      <QueryRenderer
        environment={environment}
        query={query}
        render={renderComponent}
        variables={{ query: props.query || '' }}
      />
    </article>
  )
}

export default SearchOrders
