import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import type { IEnvironment } from 'relay-runtime'

import OrderDetails, { isItemBook } from './OrderDetails'
import type { OrderDetails_order } from './__generated__/OrderDetails_order.graphql'
import { LoadListHeader } from './components'
import FinalizeDialog, {
  open as openFinalizeDialog,
  close as closeFinalizeDialog,
} from './FinalizeDialog'

interface Props {
  environment: IEnvironment
  onOpenDialog: (orderId: string) => void
  query: any
}

function organizeOrdersByAddress(orders: OrderDetails_order[]): OrderDetails_order[][] {
  function join(...parts: string[]): string {
    return parts.reduce( (prev, cur) => prev + "|" + (cur || "").toLowerCase().trim(), "")
  }

  const result: { [address: string]: OrderDetails_order[] } = {}
  let blankI = 0
  orders.forEach(order => {
    // We pseudo-hash the address to group orders with the same address
    const sa = order.shopify?.shippingAddress

    let address = ""
    if (!sa) {
      address = `<blank ${ blankI }>`
      blankI++
    } else {
      address = join(sa.name, sa.address1, sa.address2, sa.city, sa.postalCode, sa.provinceCode, sa.countryCode)
    }

    if (!result[address]) {
      result[address] = []
    }
    result[address].push(order)
  })

  let out: OrderDetails_order[][] = []
  for (let address in result) {
    out.push(result[address])
  }

  return out
}

export default function OrderList({ environment, onOpenDialog, query }: Props) {
  // This is terrible, it will get better after Relay is gone forever
  const orders: OrderDetails_order[]|undefined = query.myOrders || query.searchOrders

  useEffect(() => {
    cleanup()

    if (orders?.length) {
      let activeCount = 0
      let bookCount = 0
      for (let i=orders?.length; i--;){
        if (orders[i].status === 'reserved-ready') {
          activeCount++
        }

        if (orders[i].shopify?.lineItems){
          for (let j=orders[i].shopify.lineItems.length; j--;){
            if (isItemBook(orders[i].shopify.lineItems[j])) {
              bookCount += orders[i].shopify.lineItems[j].quantity
            }
          }
        }
      }

      if (activeCount) {
        document.title = `${ orders?.length } orders / ${ activeCount } active / ${ bookCount } books | loom`
      }
    }

    function cleanup(){
      document.title = 'loom'
    }

    return cleanup
  }, [orders])

  if(!orders) {
    return <>No orders found</>
  }

  const orderSets = organizeOrdersByAddress(orders)

  // sort orderSet by oldest order
  orderSets.sort((a, b) => {
    const dateF = (o: OrderDetails_order) => +new Date(o.paidAt || o.shopify?.createdAt)

    // Convert genrated names to values such that 53:1:10 sorts after 53:1:2
    const nameAsValue = (name: string) => {
      name = name.replace(/^[a-z]/gi, '')

      const parts = name.split(':')
      return parts.reverse().reduce((prev, cur, idx) => prev + (1000 ** idx)*(+cur), 0)
    }

    const compareOrder = (cur: OrderDetails_order, prev: OrderDetails_order) => {
      const curDate = dateF(cur)
      const prevDate = dateF(prev)

      if (curDate == prevDate) {
        // This is useful for when we have many designs / destinations which all fed from the
        // same order
        return nameAsValue(cur.name) - nameAsValue(prev.name)
      }
      return curDate - prevDate
    }

    a.sort(compareOrder)
    b.sort(compareOrder)

    return compareOrder(a[0], b[0])
  })

  let finalizeDialog = ReactDOM.createPortal(
    <FinalizeDialog
      environment={environment}
      query={query}
      // @ts-ignore
      orders={orders}
      close={() => {
        closeFinalizeDialog(environment)
      }}
    />,
    document.querySelector('#root')!
  )

  return (
    <>
      <table className="App-order-list">
        <thead>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th align="center"></th>
          <th align="center">Name</th>
          <th align="left">Product</th>
          <th align="left">Placed</th>
          <th align="left">Ship</th>
          <th align="center">Credits Used</th>
          <th align="center">Download</th>
          <th align="center">Transfer</th>
          <th align="center" title="Inactivate an order if it should not be fulfilled">Status</th>
          <th align="center" title="Uncheck to skip this session" className="w-12">
            <LoadListHeader environment={ environment } />
          </th>
        </tr>
        </thead>

        {
          orderSets.map((orderSet) => orderSet ? (
            <tbody className="App-order-set" order-set-quantity={ orderSet.length } key={ orderSet[0].id }>
              { orderSet.map((order) => order ?
                <OrderDetails
                  key={order.id}
                  // @ts-ignore
                  order={order}
                  environment={environment}
                  onOpenDialog={() => openFinalizeDialog(environment, order.id, {allowAutoPrint: false, allowAutoBuy: false})}
                />
              : null)
              }
            </tbody>
          ) : null)
        }
      </table>


      {finalizeDialog}
    </>
  )
}
