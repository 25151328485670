import React from 'react'

// @ts-ignore
import Timeline from 'react-visjs-timeline'

import type { OrderDetails_order } from '../__generated__/OrderDetails_order.graphql'
import type { OrderFilters } from '../graphql/__generated__'
import type { IOrder } from '../graphql'

interface OrderTimelineProps {
  orders: IOrder[]
  filters: OrderFilters
}

function datePart(a: Date): Date {
  return new Date(a.toDateString())
}

type Item = {
  id: string
  group: string
  content: string
  start: Date
  end: Date
  type: string
  className?: string
  style?: string
}

type Group = {
  id: string
  content: string
  className?: string
}

type Label = Exclude<OrderDetails_order["shippingLabels"], null>[number]

function asLink(href: string, title: string, text: string) {
  return `<a href="${ href }" title="${ title }" target="blank">${ text }</a>`
}

function filterValidLabels(labels: Label[], filter: (label: Label) => boolean): OrderDetails_order["shippingLabels"] {
  let out: Label[] = []
  for (let i=0; i < labels.length; i++){
    if (labels[i].voided){
      continue
    }

    if (filter(labels[i])){
      out.push(labels[i])
    }
  }

  return out
}

const OrderTimeline = (props: OrderTimelineProps) => {
  const { orders, filters } = props

  if (orders.length === 0) {
    return <>No orders found</>
  }

  let items: Item[] = []
  let groups: Group[] = []

  for (let i=0; i < orders.length; i++){
    if (!orders[i]){
      continue
    }

    // We process filters on the server, but we also do it here both because clearing the
    // Apollo cache is hard and because it provides a more fluid experience.
    const express = ["Expedited", "Express", "Express (Prepaid)"].indexOf(`${orders[i].shopify?.shipping?.code}`) !== -1
    if (filters.express && !express) {
      continue
    }

    if (filters.shippedNoPickup){
      if (!orders[i].shippingLabels){
        continue
      }

      // @ts-ignore TS doesn't see our validity check above
      const shippedNoPickup = filterValidLabels(orders[i].shippingLabels, (label) => {
        return label.tracking_status === "accepted" && label.carrier_tracking_status !== "Pickup Scan"
      })

      if (!shippedNoPickup || !shippedNoPickup.length){
        continue
      }
    }

    groups.push({
      id: orders[i].id,
      className: (express ? 'timeline-group-express' : ''),
      content: asLink(
        `https://send-heirloom-inc.myshopify.com/admin/orders/${ orders[i].id }`,
        `#${ orders[i].name }`,
        orders[i].customerName || orders[i].name || 'Unknown')
    })

    const addDate = (id: string, label: string, date: string|null|undefined) => {
      if (!date){
        return
      }

      items.push({
        id: ""+items.length,
        group: orders[i].id,
        content: label,
        start: new Date(date),
        end: new Date(),
        className: `timeline-item-${ id }`,
        type: 'point'
      })
    }

    addDate(
      "designed",
      asLink(
        `/order/${ orders[i].id }`,
        "Open order info",
        "Designed",
      ),
      orders[i].createdAt)
    addDate("requested-delivery", "Requested Delivery", orders[i].scheduledDeliveryDate)

    if (orders[i]?.vendorSale && orders[i]?.vendorSale?.createdAt){
      if (orders[i]?.vendorSale?.vendorId === 'heirloom'){
        addDate(
          "preorder",
          asLink(
            `https://send-heirloom-inc.myshopify.com/admin/orders/${ orders[i]?.vendorSale?.externalOrderId }`,
            "Preorder purchase",
            "Preorder",
          ),
          orders[i]?.vendorSale?.createdAt)
      } else {
        addDate(
          "purchase",
          `<span title="${ orders[i]?.vendorSale?.vendorId } purchase entered into the Partner Portal">Purchase</span>`,
          orders[i]?.vendorSale?.createdAt)
      }

      if (orders[i]?.vendorSale?.cancelledAt){
        addDate("purchase-cancelled", "Vendor Sale Cancelled", orders[i]?.vendorSale?.cancelledAt)
      }
    }

    if (orders[i].shippingLabels){
      // @ts-ignore It doesn't understand that we have already established the labels exist
      for (let j=orders[i].shippingLabels.length; j--;){
        // @ts-ignore
        const label = orders[i].shippingLabels[j];
        if (!label || label['voided']) {
          continue
        }

        addDate("estimated-delivery", "Estimated Delivery", label['estimated_delivery_date'])
        addDate("actual-delivery", "Actual Delivery", label['actual_delivery_date'])
        addDate(
          "shipped",
          asLink(
            `https://t.17track.net/en#nums=${ label['tracking_number'] }`,
            `Current status: ${ label['tracking_status'] }`,
            "Shipped",
          ),
          label['created_at'])
      }
    }
  }

  items.sort((a, b) => {
    return (+a.start - +b.start)
  })

  let rangeStart: Date = new Date()
  let rangeEnd: Date = new Date(0)
  const oneDay = 24 * 60 * 60 * 1000

  rangeStart = datePart(rangeStart)
  rangeEnd = datePart(rangeEnd)

  for (let i=0; i < items.length; i++){
    if (items[i+1] && items[i+1].group === items[i].group){
      items[i].end = new Date(Math.max(+items[i+1].start - 1, +items[i].end + oneDay))
    } else {
      items[i].end = new Date(+items[i].start + oneDay)
    }
  }

  for (let i=items.length; i--;){
    if (+items[i].start < +rangeStart) {
      rangeStart = items[i].start
    }
    if ((+items[i].end + oneDay) > +rangeEnd) {
      rangeEnd = new Date(+items[i].end + oneDay)
    }
  }

  const options: any = {
    stack: true,
    align: 'left',
    showMajorLabels: true,
    start: Math.max(+rangeStart, +(new Date()) - 15*oneDay),
    min: rangeStart,
    end: Math.min(+rangeEnd, +(new Date()) + 15*oneDay),
    max: rangeEnd,
    selectable: false,
    zoomKey: 'shiftKey',
    timeAxis: {scale: 'day', step: 1},
    orientation: {
      axis: "both",
      item: "top"
    },
  }

  return (
    <div className="App-order-timeline">
      <Timeline
        options={options}
        groups={groups}
        items={items}
      />
    </div>
  )
}

export default OrderTimeline
