/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BookType = "five_inch" | "%future added value";
export type ItemFulfillmentState = "archived" | "cancelled" | "created" | "hold" | "packed" | "pulled" | "ready" | "%future added value";
export type ItemType = "accessory" | "book" | "gift_card" | "%future added value";
export type PackageState = "cancelled" | "created" | "damaged" | "delivered" | "hold" | "lost" | "ready" | "returned" | "shipped" | "%future added value";
export type ProductFormFactor = "rect" | "square" | "%future added value";
export type OrderDetails_order = {
    readonly id: string;
    readonly name: string;
    readonly status: string;
    readonly createdAt: unknown;
    readonly reservedBy: string | null;
    readonly reservedAt: unknown | null;
    readonly paidAt: unknown | null;
    readonly cardVideoURL: string | null;
    readonly cardVideoSHA256: string | null;
    readonly cardVideoEncodeAttempts: number | null;
    readonly cardVideoEncodeProgress: number | null;
    readonly shareVideoEncodeProgress: number | null;
    readonly shareVideoURL: string | null;
    readonly customerName: string | null;
    readonly loyaltyCode: string | null;
    readonly notes: ReadonlyArray<{
        readonly id: string;
        readonly createdAt: unknown;
        readonly content: string;
        readonly createdBy: {
            readonly name: string;
            readonly email: string;
        };
    }> | null;
    readonly balanceUsages: ReadonlyArray<{
        readonly id: string;
        readonly vendorSaleVendorId: string | null;
        readonly vendorSaleId: string | null;
        readonly products: ReadonlyArray<{
            readonly productId: string;
            readonly quantity: number;
        }>;
    }> | null;
    readonly vendorSale: {
        readonly id: string;
        readonly vendorId: string;
    } | null;
    readonly designs: ReadonlyArray<{
        readonly design: {
            readonly id: string;
            readonly generation: string;
            readonly createdAt: unknown;
            readonly media: ReadonlyArray<{
                readonly id: string;
                readonly type: string | null;
                readonly format: string | null;
                readonly encodedLocation: string;
                readonly thumbnailLocation: string;
                readonly encodeProgress: number;
                readonly encodeAttempts: number;
                readonly isVertical: boolean | null;
            }> | null;
            readonly renders: ReadonlyArray<{
                readonly id: string;
                readonly formFactor: ProductFormFactor | null;
                readonly task: string;
                readonly signedURL: string;
                readonly sizeKB: number | null;
                readonly encodedConfig: {
                    readonly vertical: boolean;
                } | null;
            }> | null;
        } | null;
        readonly destinations: ReadonlyArray<{
            readonly id: string;
            readonly createdAt: unknown;
            readonly state: string;
            readonly bookType: BookType | null;
            readonly coverId: string | null;
            readonly quantity: number;
            readonly shippingSpeed: string | null;
            readonly shippingServiceCode: string | null;
            readonly giftMessage: {
                readonly body: string;
            } | null;
            readonly address: {
                readonly fullName: string;
                readonly companyName: string | null;
                readonly street1: string;
                readonly street2: string | null;
                readonly cityLocality: string;
                readonly countryCode: string;
                readonly stateProvinceCode: string;
                readonly postalCode: string;
                readonly phoneNumber: string | null;
            } | null;
            readonly extras: ReadonlyArray<{
                readonly id: string;
            }> | null;
            readonly items: ReadonlyArray<{
                readonly id: string;
                readonly createdAt: unknown;
                readonly type: ItemType;
                readonly state: ItemFulfillmentState;
            }> | null;
            readonly packages: ReadonlyArray<{
                readonly id: string;
                readonly createdAt: unknown;
                readonly state: PackageState | null;
                readonly shippingLabels: ReadonlyArray<{
                    readonly label_id: string;
                    readonly is_return: boolean | null;
                    readonly status: string;
                    readonly created_at: string;
                    readonly tracking_number: string;
                    readonly service_code: string;
                    readonly voided: boolean;
                }> | null;
            }> | null;
        }> | null;
    }> | null;
    readonly customer: {
        readonly id: string;
        readonly email: string;
        readonly flagOrders: {
            readonly createdByName: string;
            readonly createdByEmail: string;
            readonly createdAt: unknown;
            readonly description: string | null;
            readonly emoji: ReadonlyArray<string> | null;
        } | null;
    } | null;
    readonly shopify: {
        readonly email: string;
        readonly createdAt: unknown | null;
        readonly note: string | null;
        readonly giftMessage: string;
        readonly discountCodes: ReadonlyArray<string> | null;
        readonly videoManifest: ReadonlyArray<string> | null;
        readonly shippingAddress: {
            readonly address1: string;
            readonly address2: string;
            readonly city: string;
            readonly company: string;
            readonly countryCode: string;
            readonly name: string;
            readonly firstName: string;
            readonly phone: string;
            readonly province: string;
            readonly provinceCode: string;
            readonly zip: string;
        } | null;
        readonly shipping: {
            readonly carrierIdentifier: string;
            readonly code: string;
        } | null;
        readonly lineItems: ReadonlyArray<{
            readonly quantity: number;
            readonly SKU: string;
            readonly product: {
                readonly title: string;
                readonly images: ReadonlyArray<{
                    readonly src: string;
                }> | null;
            } | null;
            readonly destination: {
                readonly id: string;
                readonly coversPrinted: number;
                readonly cover: {
                    readonly approvedAt: unknown | null;
                } | null;
                readonly backCover: {
                    readonly approvedAt: unknown | null;
                } | null;
            } | null;
        }> | null;
    } | null;
    readonly shippingLabels: ReadonlyArray<{
        readonly label_id: string;
        readonly created_at: string;
        readonly status: string;
        readonly voided: boolean;
        readonly shipment_id: string;
        readonly tracking_number: string;
        readonly tracking_status: string;
        readonly label_download: {
            readonly pdf: string | null;
            readonly png: string | null;
        } | null;
        readonly form_download: {
            readonly href: string | null;
        } | null;
    }> | null;
    readonly cardVideoEncodedConfig: {
        readonly vertical: boolean;
        readonly allowVertical: boolean;
    } | null;
    readonly videoDownloadStatus: string;
    readonly videoPercentageDownloaded: number | null;
    readonly videoTransferStatus: string | null;
    readonly videoPercentageTransferred: number | null;
    readonly " $refType": "OrderDetails_order";
};
export type OrderDetails_order$data = OrderDetails_order;
export type OrderDetails_order$key = {
    readonly " $data"?: OrderDetails_order$data;
    readonly " $fragmentRefs": FragmentRefs<"OrderDetails_order">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vertical",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label_id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tracking_number",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voided",
  "storageKey": null
},
v14 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "approvedAt",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderDetails_order",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reservedBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reservedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paidAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cardVideoURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cardVideoSHA256",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cardVideoEncodeAttempts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cardVideoEncodeProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shareVideoEncodeProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shareVideoURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "loyaltyCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderNote",
      "kind": "LinkedField",
      "name": "notes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "createdBy",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BalanceUsage",
      "kind": "LinkedField",
      "name": "balanceUsages",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vendorSaleVendorId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vendorSaleId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BalanceUsageProduct",
          "kind": "LinkedField",
          "name": "products",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productId",
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VendorSale",
      "kind": "LinkedField",
      "name": "vendorSale",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vendorId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DesignWithDestinations",
      "kind": "LinkedField",
      "name": "designs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Design",
          "kind": "LinkedField",
          "name": "design",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "generation",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "MediaEntry",
              "kind": "LinkedField",
              "name": "media",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "format",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "encodedLocation",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "thumbnailLocation",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "encodeProgress",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "encodeAttempts",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isVertical",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Render",
              "kind": "LinkedField",
              "name": "renders",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "formFactor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "task",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "signedURL",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sizeKB",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "VideoConfiguration",
                  "kind": "LinkedField",
                  "name": "encodedConfig",
                  "plural": false,
                  "selections": [
                    (v7/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Destination",
          "kind": "LinkedField",
          "name": "destinations",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/),
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bookType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "coverId",
              "storageKey": null
            },
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shippingSpeed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shippingServiceCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Message",
              "kind": "LinkedField",
              "name": "giftMessage",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "body",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Address",
              "kind": "LinkedField",
              "name": "address",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "street1",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "street2",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cityLocality",
                  "storageKey": null
                },
                (v9/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "stateProvinceCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "postalCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "phoneNumber",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Extra",
              "kind": "LinkedField",
              "name": "extras",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PackageItem",
              "kind": "LinkedField",
              "name": "items",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                (v6/*: any*/),
                (v8/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Package",
              "kind": "LinkedField",
              "name": "packages",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShippingLabel",
                  "kind": "LinkedField",
                  "name": "shippingLabels",
                  "plural": true,
                  "selections": [
                    (v10/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "is_return",
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    (v11/*: any*/),
                    (v12/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "service_code",
                      "storageKey": null
                    },
                    (v13/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "FlagInfo",
          "kind": "LinkedField",
          "name": "flagOrders",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdByName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdByEmail",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emoji",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShopifyOrder",
      "kind": "LinkedField",
      "name": "shopify",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "note",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "giftMessage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "discountCodes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "videoManifest",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopifyAddress",
          "kind": "LinkedField",
          "name": "shippingAddress",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address1",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address2",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "company",
              "storageKey": null
            },
            (v9/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phone",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "province",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "provinceCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "zip",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopifyShipping",
          "kind": "LinkedField",
          "name": "shipping",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "carrierIdentifier",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopifyLineItem",
          "kind": "LinkedField",
          "name": "lineItems",
          "plural": true,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "SKU",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ShopifyProduct",
              "kind": "LinkedField",
              "name": "product",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShopifyImage",
                  "kind": "LinkedField",
                  "name": "images",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "src",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Destination",
              "kind": "LinkedField",
              "name": "destination",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "coversPrinted",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Cover",
                  "kind": "LinkedField",
                  "name": "cover",
                  "plural": false,
                  "selections": (v14/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Cover",
                  "kind": "LinkedField",
                  "name": "backCover",
                  "plural": false,
                  "selections": (v14/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShippingLabel",
      "kind": "LinkedField",
      "name": "shippingLabels",
      "plural": true,
      "selections": [
        (v10/*: any*/),
        (v11/*: any*/),
        (v2/*: any*/),
        (v13/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shipment_id",
          "storageKey": null
        },
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tracking_status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LabelDownload",
          "kind": "LinkedField",
          "name": "label_download",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pdf",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "png",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FormDownload",
          "kind": "LinkedField",
          "name": "form_download",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "href",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoConfiguration",
      "kind": "LinkedField",
      "name": "cardVideoEncodedConfig",
      "plural": false,
      "selections": [
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowVertical",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "videoDownloadStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "videoPercentageDownloaded",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "videoTransferStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "videoPercentageTransferred",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();
(node as any).hash = 'f54f517a225ce8d7e3ad34f87ce69e64';
export default node;
