import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import type { RouteComponentProps } from '@reach/router'
import { BanknotesIcon } from '@heroicons/react/24/outline'
import { useQuery, useMutation } from '@apollo/client'
import TimeAgo from 'react-timeago'

import { CustomerInfoDocument, CustomerInfoQuery, RefundDocument } from './graphql/__generated__'
interface CustomerInfoProps extends RouteComponentProps {
  customerId?: string
}

type PaymentFragment = CustomerInfoQuery['payments'][0]

export default function CustomerInfo ({customerId}: CustomerInfoProps) {
  const [refunding, setRefunding] = useState<PaymentFragment | undefined>(undefined)
  const [refundAmount, setRefundAmount] = useState<number | undefined>(undefined)
  const cancelButtonRef = useRef(null)

  const { loading, data, error } = useQuery(CustomerInfoDocument, {
    variables: {
      customerID: customerId
    },
  })

  const [refund, { loading: refundLoading, error: refundError }] = useMutation(RefundDocument)

  if (loading) {
    return <p className="m-4 text-center">Loading...</p>
  }

  if (error) {
    return <p className="m-4 text-red-500 text-center">Error: { error.message }</p>
  }

  const customer = data?.customerInfo?.customer
  const payments = data?.customerInfo?.payments || []

  const doRefund = () => {
    if (!refunding) {
      return
    }

    let { amountInCents } = refunding
    if (refundAmount !== undefined) {
      amountInCents = refundAmount * 100
    }

    refund({
      variables: {
        paymentID: refunding.id,
        amountInCents,
      }
    })
  }

  if (customer) {
    return <>
      <h1 className="text-xl">{ customer.email }</h1>

      { refundError && <p className="text-red-500 my-4 text-center">Error Refunding: { refundError.message }</p> }

      <h2 className="text-lg">Payments</h2>
      <table className="m-auto">
        <thead>
          <tr>
            <th className="px-4 font-bold">Date</th>
            <th className="px-4 font-bold">Charged</th>
            <th className="px-4 font-bold">Refunded</th>
            <th className="px-4 font-bold">Status</th>
            <th className="px-4 font-bold">Preorder</th>
            <th className="px-4 font-bold">Discount ID</th>
            <th className="px-4 font-bold">Order ID</th>
            <th />
          </tr>
        </thead>
        
        <tbody>
          { payments.map((payment) => (
            <tr key={ payment.id }>
              <td>
                <a
                  href={ "https://dashboard.stripe.com/payments/" + payment.id }
                  className="underline"
                  target="_blank"
                  rel="noreferrer">
                  <TimeAgo date={ payment.createdAt } />
                </a>
              </td>
              <td>
                ${ (payment.amountInCents / 100).toFixed(2) }
              </td>
              <td>
                ${ (payment.amountRefundedInCents / 100).toFixed(2) }
              </td>
              <td>{ payment.state }</td>
              <td>{ payment.isPreorder ? 'Yes' : 'No' }</td>
              <td>{ payment.discountID }</td>
              <td>
                <a href={ "/?q=" + payment.orderID } className="underline">
                  { payment.orderID }
                </a>
              </td>
              <td>
                <div className="flex flex-col justify-center">
                  <button
                    className="font-bold underline px-4 my-2"
                    disabled={ payment.state !== 'succeeded' }
                    onClick={ () => setRefunding(payment) }>
                    Refund
                  </button>
                </div>
              </td>
            </tr>
          )) }
        </tbody>
      </table>
  
      <Transition.Root show={ !!refunding } as={ Fragment }>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={ () => setRefunding(undefined) }>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-500 sm:mx-0 sm:h-10 sm:w-10">
                        <BanknotesIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="m-0 text-base font-semibold leading-6 text-gray-900">
                          Refund Payment
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            How much would you like to refund?
                          </p>
                          <form className="mt-2">
                            <label htmlFor="amount" className="sr-only">Amount</label>
                            <input
                              id="amount"
                              name="amount"
                              type="number"
                              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              onChange={ (e) => setRefundAmount(parseFloat(e.target.value)) }
                              defaultValue={ (refunding?.amountInCents / 100).toFixed(2) }
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => { doRefund(); setRefunding(undefined) }}
                    >
                      Refund
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setRefunding(undefined)}
                      ref={ cancelButtonRef }
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

    </>
  }
  return <></>
}

