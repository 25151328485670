import React, { useState, useEffect } from 'react'
import type { ChangeEvent } from 'react'
import { makeVar, useReactiveVar } from '@apollo/client'
import type { ReactiveVar } from '@apollo/client'

import { watchDevices, divorceDevices } from '../Daemon'

type FulfillmentOptionsT = {
  formatBooks: ReactiveVar<boolean>
  verifyTransfers: ReactiveVar<boolean>
  autoPrintMessage: ReactiveVar<boolean>
  autoPrintCoupon: ReactiveVar<boolean>
  autoBuyLabel: ReactiveVar<boolean>
  autoPrintLabel: ReactiveVar<boolean>
  keyboardShortcuts: ReactiveVar<boolean>
  saturdayDelivery: ReactiveVar<boolean>
  fromWarehouse: ReactiveVar<string>
  bookType: ReactiveVar<string>
  squarePrinter: ReactiveVar<string>
  rectPrinter: ReactiveVar<string>
  tagPrinter: ReactiveVar<string>
}

type FulfillmentOptionsStored = {
  formatBooks: boolean
  verifyTransfers: boolean
  autoPrintMessage: boolean
  autoPrintCoupon: boolean
  autoBuyLabel: boolean
  autoPrintLabel: boolean
  keyboardShortcuts: boolean
  saturdayDelivery: boolean
  fromWarehouse: string
  bookType: string
  squarePrinter: string
  rectPrinter: string
  tagPrinter: string
}

const printerList = makeVar<string[]>([])

const existing = loadFromStorage()
export const options: FulfillmentOptionsT = {
  formatBooks: makeVar<boolean>(existing.formatBooks),
  verifyTransfers: makeVar<boolean>(existing.verifyTransfers),
  autoPrintMessage: makeVar<boolean>(existing.autoPrintMessage),
  autoPrintCoupon: makeVar<boolean>(existing.autoPrintCoupon),
  autoBuyLabel: makeVar<boolean>(existing.autoBuyLabel),
  autoPrintLabel: makeVar<boolean>(existing.autoPrintLabel),
  keyboardShortcuts: makeVar<boolean>(existing.keyboardShortcuts),
  saturdayDelivery: makeVar<boolean>(existing.saturdayDelivery),
  fromWarehouse: makeVar<string>(existing.fromWarehouse),
  bookType: makeVar<string>(existing.bookType),
  squarePrinter: makeVar<string>(existing.squarePrinter),
  rectPrinter: makeVar<string>(existing.rectPrinter),
  tagPrinter: makeVar<string>(existing.tagPrinter),
}

function loadFromStorage(): FulfillmentOptionsStored {
  let saved: FulfillmentOptionsStored = {
    formatBooks: true,
    verifyTransfers: false,
    autoPrintMessage: false,
    autoPrintCoupon: false,
    autoBuyLabel: false,
    autoPrintLabel: false,
    keyboardShortcuts: false,
    saturdayDelivery: false,
    fromWarehouse: "DC",
    bookType: "5in+rect",
    squarePrinter: "",
    rectPrinter: "",
    tagPrinter: "",
  }

  let savedString = localStorage.getItem('fulfillmentOptions') || ''
  if (savedString) {
    saved = JSON.parse(savedString)
  }

  return saved
}

export default function FulfillmentOptions() {
  const formatBooksVar = useReactiveVar(options.formatBooks)
  const verifyTransfersVar = useReactiveVar(options.verifyTransfers)
  const autoPrintMessageVar = useReactiveVar(options.autoPrintMessage)
  const autoPrintCouponVar = useReactiveVar(options.autoPrintCoupon)
  const autoBuyLabelVar = useReactiveVar(options.autoBuyLabel)
  const autoPrintLabelVar = useReactiveVar(options.autoPrintLabel)
  const keyboardShortcutsVar = useReactiveVar(options.keyboardShortcuts)
  const saturdayDeliveryVar = useReactiveVar(options.saturdayDelivery)
  const fromWarehouseVar = useReactiveVar(options.fromWarehouse)
  const bookTypeVar = useReactiveVar(options.bookType)
  const squarePrinterVar = useReactiveVar(options.squarePrinter)
  const rectPrinterVar = useReactiveVar(options.rectPrinter)
  const tagPrinterVar = useReactiveVar(options.tagPrinter)
  const printerListVar = useReactiveVar(printerList)
  const [printersLoaded, setPrintersLoaded] = useState<boolean>(false)

  useEffect(() => {
    const index = watchDevices((devices) => {
      const existingPrinters = printerList()
      const printers = devices.filter(({ kind }) => kind === 'printer').map(({ id }) => id)

      let same = true
      if (!existingPrinters || printers.length !== existingPrinters.length) {
        same = false
      } else {
        for (let i=0; i < printers.length; i++){
          if (printers[i] !== existingPrinters[i]) {
            same = false
            break
          }
        }
      }

      if (!same) {
        printerList(printers)

        if (printers.length) {
          if (!options.squarePrinter()) {
            options.squarePrinter(printers[0])
          }
          if (!options.rectPrinter()) {
            options.rectPrinter(printers[0])
          }
          if (!options.tagPrinter()) {
            options.tagPrinter(printers[0])
          }
        }
      }

      setPrintersLoaded(true)
    })

    return function cleanup(){
      divorceDevices(index)
    }
  })

  function onChange(field: string): (evt: ChangeEvent<HTMLInputElement|HTMLSelectElement>) => void {
    return (evt: ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
      let saved: FulfillmentOptionsStored = loadFromStorage()

      let value: string|boolean
      if (evt.target.tagName === 'INPUT' && evt.target.getAttribute('type') !== 'radio'){
        const inputEvt = evt as ChangeEvent<HTMLInputElement>
        value = inputEvt.target.checked
      } else {
        value = evt.target.value
      }

      // @ts-ignore typed objects aren't addressible by key
      saved[field] = value
      const savedString = JSON.stringify(saved)

      try {
        // @ts-ignore This is a weird one, TS throws a type error at runtime even if we ask it to ignore
        localStorage.setItem('fulfillmentOptions', savedString)
      } catch (e) {}

      (options as any)[field](value)
    }
  }

  let printerOptions = printerListVar.map((printerId) => {
    return <option key={ printerId } value={ printerId }>{ printerId.replace(/_/g, ' ').trim() }</option>
  })
  if (printerListVar.length === 0) {
    printerOptions = [
      <option value="" key="">{ printersLoaded ? "No printers found." : "Loading..." }</option>
    ]
  }

  return <div className="App-fulfillment-options pt-3 pl-5">
    <div className="block mb-4">
      <h4>Loading Books</h4>
      <div className="flex">
        <label className="cursor-pointer flex-1">
          <input type="radio" name="bookType" onChange={ onChange('bookType') } defaultChecked={ bookTypeVar === '5in+square' } value="5in+square" className="sr-only peer" />
          <div className="block p-2 bg-gray-100 border-4 peer-checked:bg-gray-200 border-transparent peer-checked:border-blue-500">
            <output name="squareQty" className="block text-xs hidden">12</output>
            Square
          </div>
        </label>
        <label className="cursor-pointer flex-1 ml-1">
          <input type="radio" name="bookType" onChange={ onChange('bookType') } defaultChecked={ bookTypeVar === '5in+rect' } value="5in+rect" className="sr-only peer" />
          <div className="block p-2 bg-gray-100 border-4 peer-checked:bg-gray-200 border-transparent peer-checked:border-blue-500">
            <output name="rectQty" className="block text-xs hidden">5</output>
            Rect
          </div>
        </label>
      </div>
    </div>
    <label className="block text-left">
      <input type="checkbox" checked={ formatBooksVar } onChange={ onChange('formatBooks') } className="mr-2" />
      Format Books
    </label>
    <label className="block text-left">
      <input type="checkbox" checked={ verifyTransfersVar } onChange={ onChange('verifyTransfers') } className="mr-2" />
      Verify Txfers
    </label>
    <label className="block text-left">
      <input type="checkbox" checked={ autoPrintMessageVar } onChange={ onChange('autoPrintMessage') } className="mr-2" />
      Auto 🖨️ Messages
    </label>
    <label className="block text-left">
      <input type="checkbox" checked={ autoPrintLabelVar } onChange={ onChange('autoPrintLabel') } className="mr-2" />
      Auto 🖨️ Labels
    </label>
    <label className="block text-left">
      <input type="checkbox" checked={ autoPrintCouponVar } onChange={ onChange('autoPrintCoupon') } className="mr-2" />
      Auto 🖨️ Coupons
    </label>
    <label className="block text-left">
      <input type="checkbox" checked={ autoBuyLabelVar } onChange={ onChange('autoBuyLabel') } className="mr-2" />
      Auto 💰 Labels
    </label>
    <label className="block text-left">
      <input type="checkbox" checked={ saturdayDeliveryVar } onChange={ onChange('saturdayDelivery') } className="mr-2" />
      Sat Delivery
    </label>
    <label className="block text-left">
      <input type="checkbox" checked={ keyboardShortcutsVar } onChange={ onChange('keyboardShortcuts') } className="mr-2" />
      ⌨️ Shortcuts
    </label>
    <label className="block text-left mt-2">
      Ship From:
      <select defaultValue={ fromWarehouseVar } onChange={ onChange('fromWarehouse') } className="mt-1 mr-2 text-xs w-48">
        <option key="DC">DC</option>
        <option key="TX">TX</option>
      </select>
    </label>
    <label className="block text-left mt-2">
      Square Printer
      { /* value is necessary as the list of printers loads after this component mounts */ }
      <select value={ squarePrinterVar } alt={ squarePrinterVar } onChange={ onChange('squarePrinter') } className="mt-1 mr-2 text-xs w-48" disabled={ printerList().length === 0} >
        { printerOptions }
      </select>
    </label>
    <label className="block text-left mt-2">
      Rect Printer
      <select value={ rectPrinterVar } onChange={ onChange('rectPrinter') } className="mt-1 mr-2 text-xs w-48" disabled={ printerList().length === 0 }>
        { printerOptions }
      </select>
    </label>
    <label className="block text-left mt-2">
      Tag Printer
      <select value={ tagPrinterVar } onChange={ onChange('tagPrinter') } className="mt-1 mr-2 text-xs w-48" disabled={ printerList().length === 0 }>
        { printerOptions }
      </select>
    </label>
  </div>
}
