import React, { useEffect } from 'react'
import { graphql, createFragmentContainer } from 'react-relay'

import { commitLocalUpdate } from 'relay-runtime'
import type { IEnvironment, RecordProxy } from 'relay-runtime'

import { marryDevices, divorceDevices, EXTERN_HOST } from './Daemon'
import type { DeviceMonitor_query } from './__generated__/DeviceMonitor_query.graphql'

interface Props {
  environment: IEnvironment
  query: DeviceMonitor_query | null
}

function DeviceMonitor({ environment, query }: Props) {
  useEffect(() => {
    if (EXTERN_HOST) {
      // Not much point monitoring devices on someone elses computer
      return
    }

    const index = marryDevices((devices) => {
      commitLocalUpdate(environment, (store) => {
        let records = devices.filter(({ kind }) => kind === 'card').map(
          (device): RecordProxy => {
            let record = store.get('device:' + device.id)
            if (!record) {
              record = store.create('device:' + device.id, 'Device')
            }

            for (let key in device) {
              // @ts-ignore
              record.setValue(device[key], key)
            }
            return record
          }
        )
        store.getRoot().setLinkedRecords(records, 'devices')
      })
    })

    return function cleanup(){
      divorceDevices(index)
    }
  }, [environment])

  if (!query || !query.devices) {
    return <></>
  }

  const { devices } = query

  const elements = devices.map((device) => {
    if (device) {
      let statusDescription = ''
      if (device.videoTransferStatus === 'transferring')
        statusDescription = 'Transferring video onto device.'
      else if (device.videoTransferStatus === 'error')
        statusDescription = 'Error transferring video, try reconnecting device.'
      else if (device.status === 'unmounted')
        statusDescription =
          'Device is safe to unplug. Reconnect if you’d like to replace the transferred video.'
      else if (device.status === 'active')
        statusDescription = 'Device is ready for a video to be transferred.'

      // We currently auto-unmount after transfer, so 'transferred' should never be an observed status

      return (
        <li
          key={device.id}
          data-transfer-status={device.videoTransferStatus}
          data-device-status={device.status}
          data-progress={
            device.videoPercentageTransferred &&
            Math.round(device.videoPercentageTransferred)
          }
        >
          <label title={statusDescription}>
            <span role="img" aria-label="device">
              💌
            </span>
          </label>
        </li>
      )
    }

    return null
  })

  return (
    <aside className="App-device-monitor">
      <h2>
        Connected
        <br />
        Cards
      </h2>
      <ul>{elements}</ul>
    </aside>
  )
}

export default createFragmentContainer(DeviceMonitor, {
  query: graphql`
    fragment DeviceMonitor_query on Query {
      devices {
        id
        status
        videoTransferStatus
        videoPercentageTransferred
      }
    }
  `,
})
