import React from 'react'
import { ConnectedDevicesQuery } from '../graphql/__generated__'

interface Props {
  devices: ConnectedDevicesQuery['devices'] | undefined
}

export const DeviceDisplay = (props: Props) => {
  const { devices } = props

  if (!devices) {
    return <></>
  }

  const elements = devices.map((device) => {
    if (device) {
      let statusDescription = ''
      if (device.video.transferStatus === 'transferring')
        statusDescription = 'Transferring video onto device.'
      else if (device.video.transferStatus === 'error')
        statusDescription = 'Error transferring video, try reconnecting device.'
      else if (device.status === 'unmounted')
        statusDescription =
          'Device is safe to unplug. Reconnect if you’d like to replace the transferred video.'
      else if (device.status === 'active')
        statusDescription = 'Device is ready for a video to be transferred.'

      // We currently auto-unmount after transfer, so 'transferred' should never be an observed status

      return (
        <li
          key={device.id}
          data-transfer-status={device.video.transferStatus}
          data-device-status={device.status}
          data-progress={
            device.video.percentageTransferred &&
            Math.round(device.video.percentageTransferred)
          }
        >
          <label title={statusDescription}>
            <span role="img" aria-label="device">
              💌
            </span>
          </label>
        </li>
      )
    }

    return null
  })

  return (
    <aside className="App-device-monitor">
      <h2>
        Connected
        <br />
        Cards
      </h2>
      <ul>{elements}</ul>
    </aside>
  )
}
