/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type FinalizeButtonMutationVariables = {
    orderID: string;
};
export type FinalizeButtonMutationResponse = {
    readonly finalizeOrder: {
        readonly order: {
            readonly status: string;
            readonly finalizedBy: string | null;
            readonly finalizedAt: unknown | null;
        };
    };
};
export type FinalizeButtonMutation = {
    readonly response: FinalizeButtonMutationResponse;
    readonly variables: FinalizeButtonMutationVariables;
};



/*
mutation FinalizeButtonMutation(
  $orderID: ID!
) {
  finalizeOrder(orderID: $orderID) {
    order {
      status
      finalizedBy
      finalizedAt
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderID"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "orderID",
        "variableName": "orderID"
      }
    ],
    "concreteType": "OrderResponse",
    "kind": "LinkedField",
    "name": "finalizeOrder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Order",
        "kind": "LinkedField",
        "name": "order",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "finalizedBy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "finalizedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FinalizeButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FinalizeButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a604e01ba8ea44c0ee279311a8ca57ec",
    "id": null,
    "metadata": {},
    "name": "FinalizeButtonMutation",
    "operationKind": "mutation",
    "text": "mutation FinalizeButtonMutation(\n  $orderID: ID!\n) {\n  finalizeOrder(orderID: $orderID) {\n    order {\n      status\n      finalizedBy\n      finalizedAt\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '265f4d24e1fc30ee6d71c918385c6c0a';
export default node;
