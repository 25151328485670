import React, { useState } from 'react'
import type { ChangeEvent } from 'react'
import { useMutation } from '@apollo/client'
import { UpdateOrderDocument } from '../graphql/__generated__'
import type { IOrder } from '../graphql'
import type { ApolloError } from '@apollo/client'

interface OrientationInputProps {
  order: IOrder
}

const OrientationInput = ({ order }: OrientationInputProps) => {
  const [value, setValue] = useState<string | undefined>(order.manualOrientation);
  const [changed, setChanged] = useState<boolean>(false);
  const [updateOrder, { loading, data, error }] = useMutation(UpdateOrderDocument, {
    onError: (error: ApolloError) => {
      setChanged(true)

      console.error("Error saving change", error)
    }
  })

  if (!order) {
    return <>No order.</>
  }

  function saveChanges() {
    updateOrder({
      variables: {
        orderId: order.id,
        fields: ['manualOrientation'],
        prevValues: {
          manualOrientation: order.manualOrientation,
        },
        newValues: {
          manualOrientation: value,
        }
      }
    })

    setChanged(false)
  }

  function onChange(evt: ChangeEvent<HTMLSelectElement>) {
    setValue(evt.target.value)
    setChanged(true)
  }

  let changedBlock: JSX.Element = <></>
  if (data) {
    changedBlock = <div className="m-6 p-3 bg-green-200">
      Changes saved.
    </div>
  }
  if (loading) {
    changedBlock = <div className="m-6 p-3 bg-gray-200">
      Saving...
    </div>
  }
  if (changed) {
    changedBlock = <div className="m-6 p-3 bg-gray-200">
      { error && <div className="m-2 p-2 bg-red-200">Error saving changes.</div> }

      <h4 className="inline-block mr-6">You have unsaved changes.</h4>
      <button className="btn btn-primary mr-2" onClick={ saveChanges }>Save</button>
    </div>
  }

  return <>
    <select className="outline-none" defaultValue={ value } onChange={ onChange }>
      <option value="">Auto</option>
      <option value="horizontal">Horizontal</option>
      <option value="vertical">Vertical</option>
    </select>
    { changedBlock }
  </>
}

export default OrientationInput
