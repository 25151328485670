import React from 'react'
import { Subject } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'
import { Link } from '@reach/router'
import type { IEnvironment } from 'relay-runtime'
import type { ChangeEvent, Dispatch, SetStateAction } from 'react'

import { LogoutButton, FulfillmentOptions } from '.'
import type { User } from './LogoutButton'

interface Props {
  environment: IEnvironment
  user: User
  setQuery: Dispatch<SetStateAction<string>>
  initialQuery: string
}

function setInputValue(val: string){
  // @ts-ignore
  document.querySelector('#App-search-query')!.value = val
}

const handleSearch = (setQuery: Dispatch<SetStateAction<string>>) => {
  const inputChanges$ = new Subject<ChangeEvent<HTMLInputElement>>()

  inputChanges$
    .pipe(
      debounceTime(300),
      map((e) => {
        e.preventDefault()

        // @ts-ignore
        const text = document.querySelector('#App-search-query')!.value
        setQuery(text.trim())
      })
    )
    .subscribe()

  return (e: ChangeEvent<HTMLInputElement>) => {
    inputChanges$.next(e)
  }
}

export default function NavBar({ user, environment, setQuery, initialQuery }: Props) {
  return (
    <nav className="App-nav">
      <ul>
        <li>
          <a href="https://make.sendheirloom.com/" target="_blank">
            <button className="App-nav-button">New Order</button>
          </a>
        </li>
        <li>
          <Link to="/" onClick={ () => { setInputValue(""); setQuery("") } }>
            <button className="App-nav-button">Open Orders</button>
          </Link>
        </li>
        <li>
          <Link to="customers">
            <button className="App-nav-button">Customers</button>
          </Link>
        </li>
        <li>
          <Link to="art">
            <button className="App-nav-button">Art</button>
          </Link>
        </li>
        <li>
          <Link to="orders/dashboard">
            <button className="App-nav-button">Dashboard</button>
          </Link>
        </li>
        <li>
          <Link to="flags">
            <button className="App-nav-button">Flags</button>
          </Link>
        </li>
      </ul>
      <div className="App-search">
        <input
          id="App-search-query"
          placeholder="Order Search"
          defaultValue={ initialQuery }
          onChange={ handleSearch(setQuery) }
        />
      </div>
      <div className="App-fulfillment-options">
        <FulfillmentOptions />
      </div>
      <ul className="App-nav-auth">
        <li>
          <span className="App-nav-username">Hi, {user.name}</span>
          <LogoutButton environment={environment} user={user} />
        </li>
      </ul>
    </nav>
  )
}
