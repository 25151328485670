import { makeVar, FieldPolicy } from '@apollo/client'
import type { CardVideoStatus } from '../__generated__'

export const videoStatus = makeVar<{ [key: string]: CardVideoStatus }>({})

export const VideoPolicy: FieldPolicy = {
  read(order, { readField }) {
    const id = readField<string>('id', order)
    if (!!id) {
      const status = videoStatus()

      if (!status[id]) {
        status[id] = {
          downloadStatus: null,
          percentageDownloaded: null,
          transferStatus: null,
          percentageTransferred: null,
        }
        videoStatus(status)
      }

      return status[id]
    }
  },
}
