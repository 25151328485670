import { renderHTMLToCanvas } from './Draw'
import { client } from '../apollo-client'
import { WIDTH_DPI, HEIGHT_DPI } from './Message'

import { LoyaltyCodeDocument } from '../graphql/__generated__'

import type { ApolloError } from '@apollo/client'

export interface CanvasRef {
  canvas: HTMLCanvasElement
  unload: () => void
}

export async function renderCoupon(orderId: string, customerName: string, expiration: Date): Promise<CanvasRef|null> {
  let resp = await client.query({
    query: LoyaltyCodeDocument,
    variables: {
      orderID: orderId,
    }
  }).catch((error: ApolloError) => {
    console.error("Error loading qr code", error)
  })

  let data = resp?.data?.loyaltyCode
  if (!data) {
    return null
  }

  const url = data.link

  const widthIn = 4
  const heightIn = 4
  const pixelWidth = widthIn * WIDTH_DPI
  const pixelHeight = heightIn * HEIGHT_DPI

  let canvas = document.createElement('canvas')
  canvas.width = pixelWidth
  canvas.height = pixelHeight
  canvas.style.display = 'none'
  canvas.style.width = `${ pixelWidth/2 }px`
  canvas.style.height = `${ pixelHeight/2 }px`
  document.body.appendChild(canvas)

  const expirationString = expiration.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  await renderHTMLToCanvas(`
  <style>
    .printed {
      overflow: hidden;
      font-family: Avenir, "Avenir Next", "Avenir New", Helvetica, "Helvetica Neue", sans-serif;
      font-size: 60px;
      line-height: 1.5;
      height: ${ pixelHeight - 15 }px;
    }

    .message {
      position: relative;
      box-sizing: border-box;
      height: 100%;
      padding: 1em;
    }

    .message-outer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .body p {
      margin: 0 0 .75em;
    }

    .body p:last-child {
      margin-bottom: 0;
    }

    img {
      width: 40%;
      margin: 0.25em auto 0.5em;
      display: block;
    }

    .center {
      text-align: center;
    }
  </style>
  <div class="printed">
    <div class="message">
      <div class="message-outer">
        <div class="message-inner">
          <div class="body">
            <p>
              ${ customerName ? customerName.trim() + ", " : "" }
            </p>
            <p>
              We would love to give you $10 off a video book. Use this yourself, and then share it with a friend.
            </p>
            <p>
              Visit before ${ expirationString }:
            </p>
            <div class="center">
              <img src="${ data.qr.dataURL }" />
              <strong>
                ${ url }
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>`, canvas, pixelWidth, pixelHeight)

  let unloadCanvas = () => {
    document.body.removeChild(canvas)
  }

  return {
    canvas,
    unload: unloadCanvas,
  }
}
