import debug from 'debug'
const log = debug('daemon:socket')

let socket: WebSocket
let socketPromise: Promise<WebSocket>

export const HTTP_PORT = "50501"
export const HTTPS_PORT = "50502"
export const HOST = localStorage.getItem('daemonAddr') || 'localhost'
export const EXTERN_HOST = !!localStorage.getItem('daemonAddr')
// We have to use WSS if this is going over the network or Chrome gets mad at us
export const SECURE_DAEMON = EXTERN_HOST

export function randomSequence(): string {
  return Math.random().toString().substr(2)
}

let clientID = randomSequence()

let handlers: any = {}
let closeHandlers: (() => void)[] = []
let openHandlers: (() => void)[] = []

export function registerMessageKind(kind: string, handler: any){
  handlers[kind] = handler
}

export async function send(message: any) {
  const socket = await getSocket()
  socket.send(message)
}

export function onSocketClose(handler: () => void) {
  closeHandlers.push(handler)
}

export function onSocketOpen(handler: () => void) {
  openHandlers.push(handler)

  if (socket.readyState === WebSocket.OPEN) {
    handler()
  }
}

function handleClose() {
  for (let i=closeHandlers.length; i--;){
    closeHandlers[i]()
  }
}

function handleOpen() {
  log("Notifying open listeners", openHandlers)
  for (let i=openHandlers.length; i--;){
    openHandlers[i]()
  }
}



export function openSocket(): Promise<WebSocket>{
  if (socket && socket.readyState !== WebSocket.CLOSING && socket.readyState !== WebSocket.CLOSED) {
    socket.close()
  }

  if (SECURE_DAEMON) {
    socket = new WebSocket(`wss://${ HOST }:${ HTTPS_PORT }/daemon`)
  } else {
    socket = new WebSocket(`ws://${ HOST }:${ HTTP_PORT }/daemon`)
  }

  socketPromise = new Promise((resolve, reject) => {
    socket.addEventListener('open', () => {
      socket.send(JSON.stringify({
        kind: 'connect:client',
        client_id: clientID,
      }))

      handleOpen()
      resolve(socket)
    })

    socket.onerror = (err) => {
      console.error("Socket error", err)
      reject(err)
    }
  })


  socket.addEventListener('message', (event) => {
    let msg = JSON.parse(event.data)
    // console.info(msg)

    if (handlers[msg.kind]){
      handlers[msg.kind](msg)
    }
  })

  socket.addEventListener('close', () => {
    /* We broadly have two options when the socket reconnects. We can either resend any messages which weren't
     * received and responded to, or we can let teach consumer know about the disconnect such that it can do
     * what is most appropriate. The latter is a better move, as many messages don't get acked at all, and
     * it would be hard to know which to resend.
     */
    reconnectSocket()
    handleClose()
  })

  return socketPromise
}

let reconnectTimeout: any = null
function reconnectSocket(): Promise<WebSocket> {
  return new Promise((resolve) => {
    console.log("Reconnecting to daemon...")

    clearTimeout(reconnectTimeout)
    reconnectTimeout = setTimeout(() => {
      resolve(openSocket())
    }, 1000)
  })
}

export function getSocket(): Promise<WebSocket> {
  if (!socket){
    return openSocket()
  } else if ([WebSocket.OPEN, WebSocket.CONNECTING].includes(socket.readyState)) {
    return socketPromise
  } else {
    return reconnectSocket()
  }
}

