/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SearchOrderList_query = {
    readonly searchOrders: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly status: string;
        readonly reservedBy: string | null;
        readonly reservedAt: unknown | null;
        readonly paidAt: unknown | null;
        readonly cardVideoURL: string | null;
        readonly cardVideoSHA256: string | null;
        readonly cardVideoEncodeProgress: number | null;
        readonly shareVideoEncodeProgress: number | null;
        readonly shareVideoURL: string | null;
        readonly customerName: string | null;
        readonly scheduledDeliveryDate: unknown | null;
        readonly loyaltyCode: string | null;
        readonly notes: ReadonlyArray<{
            readonly id: string;
            readonly createdAt: unknown;
            readonly content: string;
            readonly createdBy: {
                readonly name: string;
                readonly email: string;
            };
        }> | null;
        readonly balanceUsages: ReadonlyArray<{
            readonly id: string;
            readonly vendorSaleVendorId: string | null;
            readonly vendorSaleId: string | null;
        }> | null;
        readonly vendorSale: {
            readonly id: string;
            readonly vendorId: string;
        } | null;
        readonly customer: {
            readonly id: string;
            readonly email: string;
            readonly flagOrders: {
                readonly createdByName: string;
                readonly createdByEmail: string;
                readonly createdAt: unknown;
                readonly description: string | null;
                readonly emoji: ReadonlyArray<string> | null;
            } | null;
        } | null;
        readonly shopify: {
            readonly email: string;
            readonly createdAt: unknown | null;
            readonly note: string | null;
            readonly giftMessage: string;
            readonly discountCodes: ReadonlyArray<string> | null;
            readonly shippingAddress: {
                readonly address1: string;
                readonly address2: string;
                readonly city: string;
                readonly company: string;
                readonly countryCode: string;
                readonly firstName: string;
                readonly name: string;
                readonly phone: string;
                readonly province: string;
                readonly provinceCode: string;
                readonly zip: string;
            } | null;
            readonly shipping: {
                readonly carrierIdentifier: string;
                readonly code: string;
            } | null;
            readonly lineItems: ReadonlyArray<{
                readonly quantity: number;
                readonly SKU: string;
                readonly product: {
                    readonly title: string;
                    readonly images: ReadonlyArray<{
                        readonly src: string;
                    }> | null;
                } | null;
                readonly destination: {
                    readonly id: string;
                    readonly coversPrinted: number;
                    readonly cover: {
                        readonly approvedAt: unknown | null;
                    } | null;
                    readonly backCover: {
                        readonly approvedAt: unknown | null;
                    } | null;
                } | null;
            }> | null;
        } | null;
        readonly shippingLabels: ReadonlyArray<{
            readonly label_id: string;
            readonly is_return: boolean | null;
            readonly created_at: string;
            readonly status: string;
            readonly voided: boolean;
            readonly shipment_id: string;
            readonly tracking_number: string;
            readonly tracking_status: string;
            readonly carrier_code: string;
            readonly service_code: string;
            readonly label_download: {
                readonly pdf: string | null;
                readonly png: string | null;
            } | null;
            readonly form_download: {
                readonly href: string | null;
            } | null;
        }> | null;
        readonly cardVideoEncodedConfig: {
            readonly vertical: boolean;
            readonly allowVertical: boolean;
        } | null;
        readonly videoDownloadStatus: string;
        readonly videoPercentageDownloaded: number | null;
        readonly videoTransferStatus: string | null;
        readonly videoPercentageTransferred: number | null;
        readonly " $fragmentRefs": FragmentRefs<"OrderDetails_order">;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"FinalizeDialog_query">;
    readonly " $refType": "SearchOrderList_query";
};
export type SearchOrderList_query$data = SearchOrderList_query;
export type SearchOrderList_query$key = {
    readonly " $data"?: SearchOrderList_query$data;
    readonly " $fragmentRefs": FragmentRefs<"SearchOrderList_query">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "approvedAt",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "query"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchOrderList_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "destinationsBecomeOrders",
          "value": true
        },
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "query"
        }
      ],
      "concreteType": "Order",
      "kind": "LinkedField",
      "name": "searchOrders",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reservedBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reservedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paidAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cardVideoURL",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cardVideoSHA256",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cardVideoEncodeProgress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shareVideoEncodeProgress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shareVideoURL",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "scheduledDeliveryDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "loyaltyCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderNote",
          "kind": "LinkedField",
          "name": "notes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "content",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BalanceUsage",
          "kind": "LinkedField",
          "name": "balanceUsages",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "vendorSaleVendorId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "vendorSaleId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "VendorSale",
          "kind": "LinkedField",
          "name": "vendorSale",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "vendorId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Customer",
          "kind": "LinkedField",
          "name": "customer",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "FlagInfo",
              "kind": "LinkedField",
              "name": "flagOrders",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdByName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdByEmail",
                  "storageKey": null
                },
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "emoji",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopifyOrder",
          "kind": "LinkedField",
          "name": "shopify",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "note",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "giftMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "discountCodes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ShopifyAddress",
              "kind": "LinkedField",
              "name": "shippingAddress",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address1",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address2",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "city",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "company",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "countryCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "phone",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "province",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "provinceCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "zip",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ShopifyShipping",
              "kind": "LinkedField",
              "name": "shipping",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "carrierIdentifier",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ShopifyLineItem",
              "kind": "LinkedField",
              "name": "lineItems",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "SKU",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShopifyProduct",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ShopifyImage",
                      "kind": "LinkedField",
                      "name": "images",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "src",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Destination",
                  "kind": "LinkedField",
                  "name": "destination",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "coversPrinted",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Cover",
                      "kind": "LinkedField",
                      "name": "cover",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Cover",
                      "kind": "LinkedField",
                      "name": "backCover",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShippingLabel",
          "kind": "LinkedField",
          "name": "shippingLabels",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "is_return",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created_at",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "voided",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shipment_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tracking_number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tracking_status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "carrier_code",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "service_code",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LabelDownload",
              "kind": "LinkedField",
              "name": "label_download",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pdf",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "png",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FormDownload",
              "kind": "LinkedField",
              "name": "form_download",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "href",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "VideoConfiguration",
          "kind": "LinkedField",
          "name": "cardVideoEncodedConfig",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "vertical",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "allowVertical",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrderDetails_order"
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "videoDownloadStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "videoPercentageDownloaded",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "videoTransferStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "videoPercentageTransferred",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FinalizeDialog_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '949f7bef99a7b07d6f2357cc5341ac53';
export default node;
