import { send, registerMessageKind, onSocketClose, HTTP_PORT } from './Socket'
import debug from 'debug'
const log = debug('daemon:downloader')

registerMessageKind('download:progress', handleDownloadProgress)
registerMessageKind('download:complete', handleDownloadComplete)
registerMessageKind('download:error', handleDownloadError)

interface DownloadListener {
  progressNotifier: (progress: DownloadProgress) => void
  completeNotifier: () => void
  errorNotifier: (error: DownloadError) => void
}

let downloadListeners = new Map<string, DownloadListener[]>()

export interface DownloadStart {
  hash_sha256: string

  url: string
}

export interface DownloadProgress {
  hash_sha256: string

  percent_complete: number
  time_remaining_seconds: number
}

export interface DownloadComplete {
  hash_sha256: string
}

export interface DownloadError {
  hash_sha256: string

  message: string
  code?: string
}

onSocketClose(() => {
  log('Socket closed, canceling downloads', downloadListeners)
  for (let hash of downloadListeners.keys()) {
    const message: DownloadError = {
      hash_sha256: hash,
      message: 'Daemon disconnected',
    }

    handleDownloadError(message)
  }
})

function handleDownloadProgress(message: DownloadProgress) {
  let listeners = downloadListeners.get(message.hash_sha256)
  if (listeners) {
    for (let i = 0; i < listeners.length; i++) {
      listeners[i].progressNotifier && listeners[i].progressNotifier(message)
    }
  }
}

function handleDownloadComplete(message: DownloadComplete) {
  let listeners = downloadListeners.get(message.hash_sha256)
  if (listeners) {
    for (let i = 0; i < listeners.length; i++) {
      listeners[i].completeNotifier && listeners[i].completeNotifier()
    }
  }

  downloadListeners.delete(message.hash_sha256)
}

function handleDownloadError(message: DownloadError) {
  let listeners = downloadListeners.get(message.hash_sha256)
  log('Triggering error', listeners)
  if (listeners) {
    for (let i = 0; i < listeners.length; i++) {
      listeners[i].errorNotifier && listeners[i].errorNotifier(message)
    }
  }

  downloadListeners.delete(message.hash_sha256)
}

export function getDownloadedFileURL(hash: string) {
  return `http://127.0.0.1:${ HTTP_PORT }/view/${hash}`
}

export function cancelDownload(hash: string) {
  // We don't actually have a way of telling the daemon to stop yet
  downloadListeners.delete(hash)
}

export async function startDownload(
  url: string,
  hash: string,
  progressCb: (progress: DownloadProgress) => void
): Promise<DownloadError> {
  // The assumption here is that the daemon won't start downloading the same file multiple times,
  // so it's safe for us to call this multiple times and request it again just in case (as we tag on more
  // listeners).
  send(
    JSON.stringify({
      kind: 'download:start',
      url: url,
      hash_sha256: hash,
    })
  )

  let promise = new Promise<DownloadError>((resolveCb, rejectCb) => {
    let list: DownloadListener[] = []

    let existingList = downloadListeners.get(hash)
    if (existingList) list = existingList

    list.push({
      progressNotifier: progressCb,
      completeNotifier: resolveCb,
      errorNotifier: rejectCb,
    })

    downloadListeners.set(hash, list)
  })

  return promise
}
