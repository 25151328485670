import React, { useState } from 'react'
import * as Sentry from "@sentry/react";
import { commitMutation, graphql } from 'react-relay'
import type { IEnvironment, RecordSourceSelectorProxy, SelectorData } from 'relay-runtime'
import { useHotkeys } from 'react-hotkeys-hook'
import { options as fulfillmentOptions } from './components/FulfillmentOptions'

import type { OrderDetails_order } from './__generated__/OrderDetails_order.graphql'

let t: OrderDetails_order["shippingLabels"] = []
interface Props {
  environment: IEnvironment
  order: OrderDetails_order
  label: typeof t[0]
  disabled: boolean
  onFinalized: () => void
}

const finalizeOrderMutation = graphql`
  mutation FinalizeButtonMutation($orderID: ID!) {
    finalizeOrder(orderID: $orderID) {
      order {
        status
        finalizedBy
        finalizedAt
      }
    }
  }
`

export default function FinalizeButton({ order, label, environment, onFinalized, disabled }: Props) {
  const [error, setError] = useState<string|null>(null)
  const [pending, setPending] = useState<boolean>(false)

  useHotkeys('f', () => {
    if (fulfillmentOptions.keyboardShortcuts()) {
      finalizeOrder()
    }
  })

  if (!order || !label) {
    return <></>
  }

  function finalizeOrder() {
    setPending(true)
    setError(null)

    commitMutation(environment, {
      mutation: finalizeOrderMutation,
      variables: {
        orderID: order.id,
      },
      onCompleted: () => {
        setPending(false)
      },
      onError: (error: Error) => {
        setPending(false)
        setError(error.message)

        console.error('Error finalizing order', error)
        Sentry.captureException(new Error("Error finalizing order"), {
          extra: {error: error.message}
        })
      },
      updater: (store: RecordSourceSelectorProxy, data: SelectorData) => {
        // @ts-ignore
        let newOrder = data!.finalizeOrder!.order

        // @ts-ignore
        let record = store!.get(order.id);
        ['finalizedAt', 'finalizedBy', 'status'].forEach( (field) => {
          record!.setValue(newOrder[field], field)
        })

        setTimeout( () => {
          onFinalized && onFinalized()
        })
      },
    })

  }

  return (
    <>
      <output
        className="App-finalize-button-error"
        hidden={ error === null }
      >
        An error occurred, call Zack.
      </output>
      <button
        onClick={ finalizeOrder }
        className="App-finalize-button"
        title="Finalizing an order marks it fulfilled in Shopify, notifies the customer, removes it from loom, and closes this dialog."
        disabled={ disabled || pending }
      >
        { pending ?
          "Finalizing..." :
          <><strong className="keyboard-shortcut-indicator">F</strong>inalize Order & Close</>
        }
      </button>
    </>
  )
}
