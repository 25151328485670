import React from 'react'
import type { OrderDetailsFragment } from '../graphql/__generated__'

interface OrderSelectionProps {
  orders: OrderDetailsFragment[]
  onSelected: (orderId: string) => void
}

export default function OrderSelection({ orders, onSelected }: OrderSelectionProps) {
  function onClick(orderId: string) {
    return function() {
      onSelected(orderId)
    }
  }

  function onKeyUp(orderId: string) {
    // @ts-ignore
    return function (e) {
      if (e.keyCode === 13){
        onSelected(orderId)
      }
    }
  }

  return <ul className="w-10/12 m-auto">
    {orders?.map((order: OrderDetailsFragment) => {
      return <li
        key={order.id}
        className="w-full mb-2 p-3 flex cursor-pointer hover:bg-blue-200"
        tabIndex={0}
        onClick={onClick(order.id)}
        onKeyUp={onKeyUp(order.id)}
        >

        <h2 className="text-lg w-8/12 text-left">{ order.customerName }'s Order</h2>
        <div className="w-4/12 text-right">
          <h3 className="text-base">#{ order.name }</h3>
          <time dateTime={ order.createdAt }>{ new Date(order.createdAt).toLocaleDateString() }</time>
        </div>
      </li>
    })}
  </ul>
}
