// @ts-ignore
import escapeHtml from 'escape-html'

import { renderHTMLToCanvas, getTextHeight } from './Draw'

export const WIDTH_DPI = 300
export const HEIGHT_DPI = 300

export interface CanvasRef {
  canvas: HTMLCanvasElement
  unload: () => void
}

export async function renderArtTag(orderName: string, artName: string): Promise<CanvasRef|null> {
  let widthIn = 2
  let heightIn = 1

  const pixelWidth = widthIn * WIDTH_DPI
  const pixelHeight = heightIn * HEIGHT_DPI

  let canvas = document.createElement('canvas')
  canvas.width = pixelWidth
  canvas.height = pixelHeight
  canvas.style.display = 'none'
  canvas.style.width = `${ pixelWidth/2 }px`
  canvas.style.height = `${ pixelHeight/2 }px`
  document.body.appendChild(canvas)

  let orderNameParts = orderName.split(':')
  let empOrderName = orderNameParts[0]
  let restOrderName = orderNameParts.slice(1).join(':')
  if (restOrderName) {
    restOrderName = `:${ restOrderName }`
  }

  let html = `
    <style>
      .printed {
        overflow: hidden;
        font-family: Avenir, "Avenir Next", "Avenir New", Helvetica, "Helvetica Neue", sans-serif;
        font-size: 70px;
        line-height: 1.5;
        margin-top: 7.5px;
        text-align: center;
        height: ${ pixelHeight - 15 }px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: bold;
      }

      .data {
        font-size: 40px;
        margin-top: 20px;
      }
      .highlight {
        font-weight: bold;
      }
    </style>
    <div class="printed">

      <div class="main">
        Place Book Here
      </div>

      <span class="data">
        <span class="highlight">${ empOrderName }</span>${ restOrderName } ${ artName.substr(0, 3) }</span>
    </div>`

  await renderHTMLToCanvas(html, canvas, pixelWidth, pixelHeight)

  let unloadCanvas = () => {
    document.body.removeChild(canvas)
  }

  return {
    canvas,
    unload: unloadCanvas,
  }
}
