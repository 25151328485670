import React, { useState, useEffect } from 'react'
import type { OrderInfoQuery } from '../graphql'
import { useMutation } from '@apollo/client'
import { VideoLink, SearchOverlay, VideoUploader } from '.'
import type { OrderDetailsFragment } from '../graphql/__generated__'
import { SortableVideo, toLetter } from './SortableVideo'
import { VideoDropTarget } from './VideoDropTarget'
import { findDuplicates } from '../utils/ImageDuplicates'

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';

interface VideoListingProps {
  order: OrderInfoQuery['order']
  onSave?: (newURLs: string[], oldURLs: string[], onDone: (err: string | undefined) => void) => void
  onChange?: (newURLs: string[]) => void
  noEdit?: boolean
}

const VideoListing = ({ order, onSave, onChange, noEdit }: VideoListingProps) => {
  const [shownSegments, setShownSegments] = useState<string[]>(order?.shopify?.videoManifest || [])
  const [changed, setChanged] = useState<boolean>(false)
  const [showOrderSelector, setShowOrderSelector] = useState<boolean>(false)
  const [videoUploadLocation, setVideoUploadLocation] = useState<number | null>(null)
  const [draggedURL, setDraggedURL] = useState<any>(null)
  const [contactCard, setContactCard] = useState<boolean>(false)
  const [showOrderInput, setShowOrderInput] = useState<boolean>(false)
  const [reorderInputValue, setReorderInputValue] = useState<string>('')
  const [lastOnChange, setLastOnChange] = useState<string[] | undefined>(undefined)
  const [saving, setSaving] = useState<boolean>(false)
  const [saveDone, setSaveDone] = useState<boolean>(false)
  const [saveError, setSaveError] = useState<string | undefined>(undefined)
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor)
  );

  const media = order?.designs && order?.designs[0]?.design?.media || []

  useEffect(() => {
    if (lastOnChange !== undefined) {
      onChange && onChange(shownSegments)
    }
    setLastOnChange(shownSegments)
  }, [onChange, shownSegments])

  function toggleContactCard() {
    setContactCard(!contactCard)
  }

  function toggleOrderInput() {
    setShowOrderInput(!showOrderInput)
  }

  function reorderFromInput(e) {
    // We carefully don't show this option after any changes have been made,
    // as the letters would no longer match the indexes.

    e.preventDefault()

    const parts = reorderInputValue.split(/[\s,]+/).map(p => p.trim().toUpperCase())

    let out = []
    // This is very inefficient, but for how infrequently it's used,
    // it's unlikely to be a problem.
    let found = {}
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i]

      for (let j = 0; j < shownSegments.length; j++) {
        if (toLetter(j) === part) {
          out.push(shownSegments[j])
          found[j] = true
          break
        }
      }
    }

    for (let i = 0; i < shownSegments.length; i++){
      if (!found[i]) {
        console.warn("Segment", toLetter(i), "missing, adding to end")
        out.push(shownSegments[i])
      }
    }

    setShownSegments(out)
    setChanged(true)
  }

  function removeAll() {
    setShownSegments([])
    setChanged(true)
  }

  function resetChanges() {
    setShownSegments(order!.shopify!.videoManifest!)
    setChanged(false)
  }

  function saveChanges() {
    if (!order) {
      console.error("Order missing")
      return
    }

    if (!onSave) {
      return
    }

    setSaving(true)
    setSaveDone(false)
    setSaveError(undefined)

    onSave(
      shownSegments,
      order?.shopify?.videoManifest || [],
      (err: string|undefined) => {
        setSaving(false)
        if (err) {
          setSaveError(err)
        } else {
          setSaveDone(true)
        }

        setChanged(false)
      },
    )
  }

  let changedBlock: JSX.Element = <></>

  if (saveDone) {
    changedBlock = <div className="my-6 p-3 bg-green-200">
      Changes saved.
    </div>
  }
  if (saving) {
    changedBlock = <div className="my-6 p-3 bg-gray-200">
      Saving...
    </div>
  }
  if (changed && onSave) {
    changedBlock = <div className="m-6 p-3 bg-gray-200">
      { saveError && <div className="m-2 p-2 bg-red-200">Error saving changes.</div> }

      <h4 className="inline-block mr-6">You have unsaved changes.</h4>
      <button className="btn btn-primary mr-2" onClick={ saveChanges }>Save</button>
      <button className="btn btn-primary btn-danger" onClick={ resetChanges }>Reset</button>
    </div>
  }

  function onOrderChosen(newOrder: OrderDetailsFragment) {
    setShowOrderSelector(false)
    setShownSegments(newOrder!.shopify!.videoManifest!)
    setChanged(true)
  }

  function startChoosingOrder() {
    setShowOrderSelector(true)
  }

  function cancelChoosingOrder() {
    setShowOrderSelector(false)
  }

  function prepareToUpload(index: number) {
    return function() {
      setVideoUploadLocation(index)
    }
  }

  function placeNewSegment(url: string) {
    setShownSegments([...shownSegments, url])
    setChanged(true)
    setVideoUploadLocation(null)
  }

  function replaceSegment(index: number) {
    return function(url: string) {
      setShownSegments(shownSegments!.map( (item, i) => {
        return (i === index ? url : item)
      }))
      setChanged(true)

      setVideoUploadLocation(null)
    }
  }

  function removeEntry(index: number) {
    return function() {
      setShownSegments(shownSegments!.filter( (item, i) => {
        return i !== index
      }))
      setChanged(true)
    }
  }

  function handleDragEnd(event: any) {
    const {active, over} = event;

    if (active && over) {
      const from = shownSegments.indexOf(active.id)
      const to = over.id + 1

      let newShownSegments = shownSegments.slice()
      newShownSegments.splice(from, 1)
      newShownSegments.splice((from < to ? to - 1 : to), 0, active.id)
      setShownSegments(newShownSegments)
      setChanged(true)
    }

    setDraggedURL(null)
  }
  function handleDragStart(event: any) {
    const {active} = event;

    setDraggedURL(active.id);
  }

  async function removeDuplicates() {
    const thumbnails = shownSegments?.map((url: string, i: number): (string | null) => {
      for (let j = 0; j < media.length; j++) {
        if (media[j].encodedLocation === url) {
          return media[j].signedThumbnailLocation
        }
      }
      return null
    })

    const dups = await findDuplicates(thumbnails.filter(i => i !== null))

    let newShownSegments = shownSegments.slice()
    let numRemoved = 0
    for (let i = 0; i < dups.length; i++) {
      newShownSegments.splice(dups[i] - numRemoved, 1)
      numRemoved++
    }
    setShownSegments(newShownSegments)
    setChanged(true)
  }

  const noop = () => {}

  return <>
      { changedBlock }

      { showOrderSelector && <SearchOverlay onSelected={ onOrderChosen } onCancelled={ cancelChoosingOrder }/> }

      <div className="btn-block-2">
        { !noEdit && <>
          <button
            className="btn btn-primary mr-2"
            onClick={ startChoosingOrder }
          >Replace With Videos From Another Order</button>
          <button
            className="btn btn-primary btn-danger mr-2"
            onClick={ removeAll }
          >Remove All</button>
        </> }
        { order.cardVideoURL ?
          <VideoLink className="btn btn-primary mr-2 mt-2" href={ order.cardVideoURL }>
            View Book Video
          </VideoLink>
          : <div />
        }
        { order.shareVideoURL ?
          <a
            className="btn btn-primary mr-2 mt-2 inline-block"
            target="_blank"
            rel="noreferrer"
            href={ order.shareVideoURL || '' }
          >
            View Sharable Video
          </a>
          : <div />
        }
        <button
          className="btn mt-4"
          onClick={ toggleContactCard }
        >{ contactCard ? "Hide Contact Card" : "Show Contact Card" }</button>
        { !changed && !noEdit &&
          <div className="mt-4">
            <button
              className="btn"
              onClick={ toggleOrderInput }
            >{ showOrderInput ? "Hide Order Input" : "Show Order Input" }</button>

            { showOrderInput && <div className="mt-4">
                <form onSubmit={ reorderFromInput }>
                  <div>Enter the order the videos should be placed in, using their letter codes seperated by commas or spaces.</div>
                  <textarea value={ reorderInputValue } onChange={ e => setReorderInputValue(e.target.value) }/>
                  <input type="submit" value="Reorder" className="btn cursor-pointer" />
                </form>
              </div>
            }

            <button
              className="btn"
              onClick={ removeDuplicates }
            >Remove Duplicates</button>
          </div>
        }
      </div>
      <div className={ "mt-10 " + (contactCard ? "grid grid-cols-10 gap-2 bg-white relative" : "") } style={ contactCard ? {"width": "90vw"} : {} }>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          {
            contactCard ? <></> :
            <VideoDropTarget index={-1} className={ contactCard ? "hidden" : "" } />
          }
          {shownSegments?.map((url: string, i: number) => {
            let thumbnail: string | undefined = undefined
            for (let j = 0; j < media.length; j++) {
              if (media[j].encodedLocation === url) {
                thumbnail = media[j].signedThumbnailLocation
              }
            }

            return <div key={url}>
              <SortableVideo
                id={url}
                index={i}
                replaceSegment={replaceSegment}
                prepareToUpload={prepareToUpload}
                videoUploadLocation={videoUploadLocation}
                url={url}
                thumbnail={thumbnail}
                hideButtons={contactCard}
                removeEntry={removeEntry}
                noEdit={noEdit}
              />
              <VideoDropTarget index={i} />
            </div>
          })}
          <DragOverlay>
            {draggedURL ? <SortableVideo
              id="being-dragged"
              index={shownSegments.indexOf(draggedURL)}
              replaceSegment={noop}
              prepareToUpload={noop}
              removeEntry={noop}
              hideButtons={false}
              pointerEvents={false}
              url={draggedURL} /> : null}
          </DragOverlay>
        </DndContext>
      </div>

      <div className="p-4"><VideoUploader onUploaded={ placeNewSegment } /></div>
    </>
}

export default VideoListing
