export function getTextHeight(html: string, width: number): number {
  let xml = html_to_xml(html)
  xml = xml.replace(/#/g, '%23')
  let svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${ width }" height="3000">
  <foreignObject width="100%" height="100%">
    ${ xml }
  </foreignObject>
  </svg>`

  let div = document.createElement('div')
  div.style.position = 'absolute'
  div.innerHTML = svg

  document.body.appendChild(div)
  const obj = div.querySelector('svg foreignObject .body')
  let textLength = obj?.getBoundingClientRect()?.height
  document.body.removeChild(div)

  return textLength || 0
}

export function renderHTMLToCanvas(html: string, canvas: HTMLCanvasElement, width: number, height: number): Promise<void> {
  return new Promise((resolve) => {
    let xml = html_to_xml(html)
    xml = xml.replace(/#/g, '%23')
    let data = `data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="${ width }" height="${ height }">
    <foreignObject width="100%" height="100%">
      ${ xml }
    </foreignObject>
    </svg>`

    let img = new Image()
    img.onload = function () {
      canvas!.getContext('2d')!.drawImage(img, 0, 0)
      resolve()
    }
    img.src = data
  })
}

function html_to_xml(html: string) {
  let doc = document.implementation.createHTMLDocument('')
  doc.write(html)

  // You must manually set the xmlns if you intend to immediately serialize
  // the HTML document to a string as opposed to appending it to a
  // <foreignObject> in the DOM
  // @ts-ignore
  doc.documentElement.setAttribute('xmlns', doc.documentElement.namespaceURI)

  // Get well-formed markup
  html = (new XMLSerializer()).serializeToString(doc.body)
  return html
}


