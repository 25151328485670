import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { DEBUG } from './Debug'
import { QueryPolicy as Query } from './graphql'
import { VideoPolicy as video, NotesPolicy as notes } from './graphql/local'
import typeDefs from './graphql/clientSchema.gql'

let url = 'https://loom-server.sendheirloom.com/query'

if (DEBUG('local_server')) {
  url = 'http://localhost:8080/query'
}

const httpLink = createHttpLink({ uri: url })

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const cache = new InMemoryCache({
  typePolicies: {
    Order: {
      fields: {
        video,
        notes,
      },
    },
    Query,
  },
})

export const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  typeDefs,
})

// @ts-ignore
window.client = client
