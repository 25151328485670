import React from 'react'

export type Alignment = 'left' | 'center' | 'right' | 'justify'

interface Props {
  alignment: Alignment
  setAlignment: (alignment: Alignment) => void
}

export default function AlignButtons({ alignment, setAlignment }: Props) {
  return (
    <div className="align-buttons">
      <button
        className={alignment === 'left' ? 'active' : ''}
        onClick={() => setAlignment('left')}
      >
        Left
      </button>
      <button
        className={alignment === 'center' ? 'active' : ''}
        onClick={() => setAlignment('center')}
      >
        Center
      </button>
      <button
        className={alignment === 'right' ? 'active' : ''}
        onClick={() => setAlignment('right')}
      >
        Right
      </button>
      <button
        className={alignment === 'justify' ? 'active' : ''}
        onClick={() => setAlignment('justify')}
      >
        Justify
      </button>
    </div>
  )
}
