import React from 'react'

interface Props {
  shareVideoURL: string | null | undefined
  icon: string | undefined
}

const SHARE_ICON = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="122.88px" height="114.318px" viewBox="0 0 122.88 114.318" enable-background="new 0 0 122.88 114.318" xml:space="preserve"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M122.88,35.289L87.945,70.578v-17.58c-22.091-4.577-39.542,0.468-52.796,17.271 c2.301-34.558,25.907-51.235,52.795-52.339L87.945,0L122.88,35.289L122.88,35.289z"/><path d="M6.908,23.746h35.626c-4.587,3.96-8.71,8.563-12.264,13.815H13.815v62.943h80.603V85.831l13.814-13.579v35.159 c0,3.814-3.093,6.907-6.907,6.907H6.908c-3.815,0-6.908-3.093-6.908-6.907V30.653C0,26.838,3.093,23.746,6.908,23.746L6.908,23.746 z"/></g></svg>`

export default function ShareVideoButton({ shareVideoURL, icon }: Props) {
  if (!shareVideoURL) {
    return <></>
  }

  if (icon){
    icon = `<span style="font-size: 1.7em; margin-top: -6px; margin-left: -1px; display: inline-block">
      ${ icon }
    </span>`
  } else {
    icon = SHARE_ICON
  }

  return (
    <a
      className={'App-share-video'}
      href={shareVideoURL}
      target="_blank"
      title="Open Shareable Video"
      rel="noopener noreferrer"
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  )
}
