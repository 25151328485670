import { graphql, fetchQuery } from 'react-relay'
import {
  getRequest,
  createOperationDescriptor,
} from 'relay-runtime'
import type { IEnvironment } from 'relay-runtime'

import { preloadVideo, currentlyPreloadingCount } from './Downloader'
import { updateDevices } from './Devices'
import type { LocalDevice } from './Devices'
import { EXTERN_HOST } from '../Daemon'
import { isItemBook, getLabelSize, hasBook } from '../OrderDetails'
import { shouldLoad } from '../components/LoadList'

import { RateQuoteDocument } from '../graphql/__generated__'
import { client } from '../apollo-client'
import type { ApolloError } from '@apollo/client'

import { OrderDetails_order } from '../__generated__/OrderDetails_order.graphql'

import { options as fulfillmentOptions } from '../components/FulfillmentOptions'

// This is confusing. debug is a OS library which supports wildcards and things, DEBUG
// is a simple localStorage wrapper used for config
import debug from 'debug'
import { DEBUG } from '../Debug'

const log = debug('pipeline:pipeline')

const BATCH_SIZE = +DEBUG('batch_size') || 5

const { saturdayDelivery, fromWarehouse, bookType } = fulfillmentOptions

// TODO Use search here so you don't have to refresh the page to fulfill a searched for order
const query = graphql`
  query PipelineQuery {
    searchOrders(query: "", destinationsBecomeOrders: true) {
      id
      name
      status
      videoDownloadStatus
      videoTransferStatus
      cardVideoURL
      cardVideoSHA256
      shopify {
        videoManifest
        lineItems {
          SKU
          quantity
        }
      }
      cardVideoEncodedConfig {
        vertical
        allowVertical
      }
      balanceUsages {
        id
        vendorSaleVendorId
        vendorSaleId
      }
    }

    devices {
      id
      status
      videoTransferStatus
      transferredOrderId
      allowBlank
    }
  }
`

function isCurrentBookType(order: OrderDetails_order): boolean {
  const isSquare = getLabelSize(order) === "4x4"
  switch (bookType()) {
    case "5in+square":
      return isSquare
    case "5in+rect":
      return !isSquare
    default:
      return false
  }
}

function chooseOrders(environment: IEnvironment, orders: OrderDetails_order[]) {
  let preloadedAndWaiting = 0
  for (let i = 0; i < orders.length; i++) {
    if (
      orders[i] &&
      orders[i].videoDownloadStatus === 'downloaded' &&
      ['transferred', 'transferring'].indexOf(
        orders[i].videoTransferStatus!
      ) === -1
    )
      preloadedAndWaiting++
  }

  let currentlyPreloading = currentlyPreloadingCount()

  log(preloadedAndWaiting, 'waiting', currentlyPreloading, 'preloading')

  const factor = +DEBUG("active_fulfillers") || 1
  const fulfillerIndex = +DEBUG("fulfiller_index") || 0
  // This is a hacky way of supporting multiple people fulfilling at once, by ensuring
  // no two get the same order

  let preloadedNow = 0
  for (let i = 0; i < orders.length; i++) {
    if (
      preloadedNow + preloadedAndWaiting + currentlyPreloading >=
      BATCH_SIZE
    ) {
      break
    }

    const order = orders[i]

    if (
      order &&
      order.status === 'reserved-ready' &&
      (!order.videoDownloadStatus ||
        order.videoDownloadStatus === 'error') &&
      order.cardVideoURL &&
      hasBook(order) &&
      isCurrentBookType(order) &&
      shouldLoad(order.id) &&
      +(order.name.replace(/[^\d]/g, '')) % factor === fulfillerIndex
    ) {

      let quantity = 1
      if (order?.shopify?.lineItems) {
        for (let i=order.shopify.lineItems.length; i--;){
          let item = order.shopify.lineItems[i]
          if (item && isItemBook(item)){
            quantity = item.quantity
            break
          }
        }
      }

      preloadVideo(environment, order.id)
      preloadRates(order.id, quantity)

      preloadedNow++
    }
  }
}

function preloadRates(orderId: string, quantity: number) {
  client.query({
    query: RateQuoteDocument,
    variables: {
      // @ts-ignore Mapping the string to the enum
      fromOrToWarehouse: fromWarehouse(),
      saturdayDelivery: saturdayDelivery(),
      orderID: orderId,
      quantity,
    }
  }).catch((error: ApolloError) => {
    console.error("Error preloading rates", error)
  })
}

let cancelSubscription: any

let prevOrders = []

export function recheckOrders(environment: IEnvironment) {
  chooseOrders(environment, prevOrders)
}

export function startPipeline(
  environment: IEnvironment,
  onTransferredDeviceDisconnected: (device: LocalDevice) => void
) {
  if (EXTERN_HOST) {
    log('Not starting pipeline because EXTERN_HOST is set')
    return
  }

  log('Starting pipeline')
  if (cancelSubscription) {
    cancelSubscription.dispose()
  }

  let prevDevices = new Map<string, LocalDevice>()

  const handleChange = function (chg: any) {
    let { data } = chg

    log('Change', chg)
    if (data.devices && data.searchOrders) {
      updateDevices(environment, data.searchOrders, data.devices)

      // Keep track of devices which were just unplugged so we can show the finalization dialog
      let newDevices = new Set<string>()
      for (let i = data.devices.length; i--; ) {
        newDevices.add(data.devices[i].id)
      }

      for (let [deviceId, device] of prevDevices) {
        if (!newDevices.has(deviceId)) {
          if (
            device.status === 'unmounted' &&
            device.videoTransferStatus === 'transferred' &&
            device.transferredOrderId
          ) {
            onTransferredDeviceDisconnected(device)
          }
        }
      }

      prevDevices.clear()
      for (let i = data.devices.length; i--; ) {
        prevDevices.set(data.devices[i].id, data.devices[i])
      }
    }

    if (data.searchOrders) {
      chooseOrders(environment, data.searchOrders)
      prevOrders = data.searchOrders
    }
  }

  const request = getRequest(query)
  const operation = createOperationDescriptor(request, {})
  const selector = environment.lookup(operation.fragment)
  cancelSubscription = environment.subscribe(selector, handleChange)

  fetchQuery(environment, query, {}).subscribe({
    next: (resp: any) => {
      let { searchOrders } = resp

      chooseOrders(environment, searchOrders)
    }
  })
}
