import React, { MouseEvent } from 'react'
import { commitMutation, graphql } from 'react-relay'
import { getOrder } from './Pipeline/Devices'
import { cancelPreload } from './Pipeline/Downloader'

import type { IEnvironment, RecordSourceSelectorProxy } from 'relay-runtime'
import type { IOrder } from './graphql'
import type { OrderDetails_order } from './__generated__/OrderDetails_order.graphql'

const setActiveMutation = graphql`
  mutation OrderActiveToggleMutation($id: ID!, $active: Boolean!) {
    setOrderActive(id: $id, active: $active) {
      order {
        id
        status
      }
    }
  }
`

interface Props {
  environment: IEnvironment
  order: OrderDetails_order | IOrder | null
}

function statusToEmoji(status: string): string {
  switch (status) {
    case 'reserved-ready':
      return '🟢'
    case 'reserved-hold':
      return '🛑'
    case 'fulfilled':
      return '✉️'
    case 'paid':
      return '💰'
    case 'archived':
      return '🧊'
    case 'draft':
      return '📝'
    default:
      return '🔲'
  }
}

export default function OrderActiveToggle({ order, environment }: Props) {
  if (!order) {
    return <></>
  }

  function toggleStatus(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault()

    if (!order) {
      return
    }

    const newActive = !(order.status === 'reserved-ready')

    commitMutation(environment, {
      mutation: setActiveMutation,
      variables: {
        id: order.id,
        active: newActive,
      },
      optimisticUpdater: (store: RecordSourceSelectorProxy) => {
        const sOrder = getOrder(store, order.id)
        if (!sOrder) {
          return
        }

        const newStatus = (newActive ? 'reserved-ready' : 'reserved-hold')
        sOrder.setValue(newStatus, 'status')
      },
      updater: (store: RecordSourceSelectorProxy, data: any) => {
        const sOrder = getOrder(store, order.id)
        if (!sOrder) {
          return
        }

        sOrder.setValue(data!.setOrderActive!.order!.status!, 'status')

        if (!newActive) {
          console.log("Cancelling preload for order", order.id)

          // This causes it's own store update, so it happens after this update
          setTimeout(() => {
            cancelPreload(environment, order.id)
          })
        }
      }
    })
  }

  return (
    <button onClick={toggleStatus} className={`App-order-active-button`}>
      {statusToEmoji(order.status)}
    </button>
  )
}
