import { commitMutation, graphql } from 'react-relay'
import type { IEnvironment, RecordSourceSelectorProxy, SelectorData } from 'relay-runtime'

const voidLabelMutation = graphql`
  mutation VoidLabelMutation($orderID: ID!, $labelID: ID!) {
    voidShippingLabel(orderID: $orderID, labelID: $labelID) {
      label_id
      status
      voided
      shipment_id
      tracking_number
      label_download {
        pdf
        png
      }
    }
  }
`

export function voidLabel(environment: IEnvironment, orderID: string, labelID: string) {
  commitMutation(environment, {
    mutation: voidLabelMutation,
    variables: {
      orderID,
      labelID,
    },
    updater: (store: RecordSourceSelectorProxy, data: SelectorData) => {
      console.log(data.voidShippingLabel)

      const label = store.getRootField('voidShippingLabel')
      if (!label) {
        return
      }

      let order = store.get(orderID)
      if (!order) {
        return
      }

      let labels = order.getLinkedRecords('shippingLabels') || []
      for (let i=labels.length; i--;){
        if (labels[i].getValue('label_id') === labelID){
          labels[i] = label
          break
        }
      }
      order.setLinkedRecords(labels, 'shippingLabels')
    },
  })
}
