import React from 'react'

import type { IEnvironment } from 'relay-runtime'

import { useHotkeys } from 'react-hotkeys-hook'
import { options as fulfillmentOptions } from '../components/FulfillmentOptions'
import { voidLabel } from './VoidLabel'

type Props = {
  environment: IEnvironment
  orderID: string
  labelID: string
  onVoid: (() => void) | undefined
}

export default function VoidButton({ environment, orderID, labelID, onVoid }: Props) {
  useHotkeys('v', () => {
    if (fulfillmentOptions.keyboardShortcuts()) {
      doVoid()
    }
  })

  function doVoid() {
    voidLabel(environment, orderID, labelID)
    onVoid && onVoid()
  }

  return <>
    <button
      className="a-dangerous-button"
      onClick={ () => doVoid() }>
        <strong className="keyboard-shortcut-indicator">V</strong>oid Label
    </button>
  </>
}
