// Load images from a list of URLs, using async fetch
// hash the images, and place them in a set
// Return the indexes of the duplicates
export async function findDuplicates(urls: string[]): number[] {
  const images = urls.map(url => {
    return fetch(url).then(res => res.blob()).then(blob => {
      return blob.text()
    });
  });

  return Promise.all(images).then(images => {
    const set = new Set()
    return images.map((image, i) => {
      if (set.has(image)) {
        return i
      }

      set.add(image)
    }).filter(i => i !== undefined)
  })
}
