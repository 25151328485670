import React, { useState } from 'react'
import { useDraggable } from '@dnd-kit/core'

import { Player, VideoLink, VideoUploader } from '.'

export function toLetter(value: number): string {
  let out = ""
  let letter = ""

  // The behavior has to be different in the first pass, as
  // we want 0 ('A') to be meaningful as the first digit, but
  // not as the last
  value++

  while (Math.floor(value) > 0) {
    value--
    letter = String.fromCharCode(65 + Math.floor(value % 26))
    out = letter + out
    value /= 26
  }

  return out
}

// TODO This is not really so much an abstraction as a convenience
// It would be better to refactor the functions which are currently
// getting passed into this into something more logical
export function SortableVideo(props: any) {
  const {
    attributes,
    listeners,
    setNodeRef,
  } = useDraggable({id: props.id})
  const [showThumbnail, setShowThumbnail] = useState(true)

  const {
    replaceSegment,
    prepareToUpload,
    url,
    videoUploadLocation,
    removeEntry,
    hideButtons,
    pointerEvents,
    thumbnail,
    noEdit,
    index} = props

  function toggleThumbnail() {
    // We don't cache thumbnails yet, so they're a bit expensive to render.
    setShowThumbnail(!showThumbnail)
  }

  const [firstIndex] = useState<number>(index)

  // @ts-ignore
  return (<div ref={setNodeRef} {...attributes} {...listeners} className={ (hideButtons ? "" : "md:flex ") + "mb-2" }>
      <div className={ hideButtons ? "w-full" : "md:w-5/12 pr-4 text-left" }>
        <h4 className="select-none" onDoubleClick={toggleThumbnail}>{ hideButtons ? "" : "Segment " }{ toLetter(firstIndex) }{ hideButtons ? "" : " #" + (index + 1) }</h4>
        <div className={"btn-block-2 " + (hideButtons ? "!hidden" : "") }>
          { !noEdit && <>
            { videoUploadLocation === index
              ? <VideoUploader onUploaded={ replaceSegment(index) } />
              : <button className="btn btn-primary mr-2" onClick={ prepareToUpload(index) } >Replace</button>
            }
          </> }

          { videoUploadLocation !== index &&
            <>
              <VideoLink href={ url } className="btn btn-primary">
                Download
              </VideoLink>
              { !noEdit &&
                <button
                  className="btn btn-danger mt-2 btn-block-full"
                  onClick={ removeEntry(index) }
                >Remove</button>
              }
            </>
          }
        </div>
      </div>
      <div className={ (hideButtons ? "w-full" : "md:w-7/12 w-full") + " " + (pointerEvents === false ? "pointer-events-none" : "") }>
        { showThumbnail && thumbnail
          ?
          <div className="w-full h-full">
            <img src={ thumbnail } className="max-w-full max-h-48 m-auto" />
          </div>
          :
          <Player source={ url } />
        }
      </div>
    </div>
  );
}
