import React, { useEffect, useCallback } from 'react'
import debounce from 'lodash.debounce'
import { useLazyQuery } from '@apollo/client'
import { Link } from '@reach/router'

import { SearchCustomersDocument } from './graphql/__generated__'

export default function Customers() {
  const pageSearchParams = new URLSearchParams(document.location.search)
  const defaultSearch = pageSearchParams.get('q') || ''

  const [searchCustomers, { data, loading, error }] = useLazyQuery(SearchCustomersDocument)

  const search = useCallback(debounce((val: string) => {
    searchCustomers({
      variables: {
        query: val,
      },
    })
  }, 500), [])

  useEffect(() => {
    search(defaultSearch)
  }, [search, defaultSearch])

  const customers = data?.searchCustomers || []

  return (
    <div>
      <h1>Customers</h1>

      <input
        type="text"
        className="my-6 text-xl w-96 max-w-full"
        onChange={ (e) => { search(e.target.value) } }
        defaultValue={ defaultSearch }
        placeholder="Name, email, or ID" />

      { loading && <p className="my-4 text-center">Loading...</p> }
      { error && <p className="text-red-500 my-4 text-center">Error: { error.message }</p> }

      <table className="m-auto">
        <tbody>
          { customers.map((customer) => (
            <tr key={ customer.id }>
              <td className="p-2 text-left">
                { customer.email }
              </td>
              <td className="underline my-2">
                <Link to={ `/customers/${customer.id}` }>
                  View
                </Link>
              </td>
            </tr>
          )) }
        </tbody>
      </table>
    </div>
  )

}
