import React, { useState, useCallback } from 'react'
import { Router, navigate } from '@reach/router'
import environment from './relay-env'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import debounce from 'lodash.debounce'
import { Loading, NavBar } from './components'
import Customers from './Customers'
import CustomerInfo from './CustomerInfo'
import OrderDashboard from './OrderDashboard'
import NewOrder from './NewOrder'
import MyOrders from './MyOrders'
import SearchOrders from './SearchOrders'
import OrderInfo from './OrderInfo'
import Flags from './Flags'
import Art from './Art'

const Loom = withAuthenticationRequired(
  () => {
    const { user } = useAuth0()

    const pageSearchParams = new URLSearchParams(document.location.search)
    const [orderSearchQuery, setOrderSearchQuery] = useState<string>(pageSearchParams.get('q') || '')

    const debouncedSearch = useCallback(debounce((qry) => {
      setOrderSearchQuery(qry)

      // Bring us to the page where search is meaningful
      navigate("/")
    }, 500), [])

    if (!user) {
      return (
        <>Not logged in.</>
      )
    }

    return (
      <>
	<aside className="App-sidebar">
	  <NavBar
	    environment={ environment }
	    user={ user! }
	    setQuery={ debouncedSearch }
	    initialQuery={ orderSearchQuery }
	  />
	</aside>

	<main className="App-page">
	  <Router>
	    <SearchOrders path="/" query={ orderSearchQuery } />
	    <OrderDashboard path="orders/dashboard" />
	    <Customers path="customers" />
	    <CustomerInfo path="customers/:customerId" />
	    <Art path="art" />
	    <NewOrder path="orders/new" />
	    <MyOrders path="my-orders" />
	    <OrderInfo path="order/:orderId" />
	    <Flags path="flags" />
	  </Router>
	</main>
      </>
    )
  },
  {
    onRedirecting: () => <Loading />,
    loginOptions: {
      // It would make a lot of sense to include our scopes here, but
      // it causes infinite redirect loops with Auth0
      cacheLocation: 'localstorage',
    },
  }
)

export default Loom
